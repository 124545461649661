import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styles from "./CorporationForm.module.scss";
import CorporationDataService from "../../services/corporation.data.service";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";

const CorporationForm = (props) => {
  // The data to be displayed in the textfields.
  const data = props.editData;

  // Checks wether the corporation id already exist.
  const idExists = async (id) => {
    try {
      const resp = await CorporationDataService.idExists(id);
      return !resp.data;
    } catch (err) {
      console.log(err);
    }
  };

  // Custom yup function to set the form invalid if the corporation id already exists.
  Yup.addMethod(Yup.string, "idExists", function (errorMessage) {
    return this.test(`test-id-exists`, errorMessage, function (value) {
      const { path, createError } = this;
      // Checks wether its in edit or add mode
      // IsDisabled is true if the user is in edit mode.
      // In this moment the user can't change the corporation id.
      if (props.isDisabled) {
        // Return true so the form is valid
        return true;
      } else {
        // If in add mode check if the id is valid or not.
        return idExists(value) || createError({ path, message: errorMessage });
      }
    });
  });

  // The object schema to validate the input fields.
  const CorporationSchema = Yup.object().shape({
    id: Yup.string()
      .max(2, "Die Id muss aus maximal 2 Zeichen bestehen.")
      .min(2, "Die Id muss aus minimal 2 Zeichen bestehen.")
      .idExists("Die Id existiert bereits!")
      .required("Pflichtfeld"),
    name: Yup.string()
      .required("Pflichtfeld")
      .max(100, "Der Gesellschaftsname darf nur maximal 100 Zeichen enthalten")
      .min(2, "Der Gesellschaftsname muss mindestens 2 Zeichen beinhalten"),
  });
  return (
    <>
      <Formik
        validationSchema={CorporationSchema}
        onSubmit={props.onSubmit}
        initialValues={{
          id: data !== undefined ? data.id : "",
          name: data !== undefined ? data.name : "",
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, touched, errors, values, setFieldTouched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridId">
                <Form.Label>Id</Form.Label>
                <Form.Control
                  name="id"
                  type="text"
                  placeholder="Id"
                  value={values.id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("id", true, false)}
                  isValid={touched.id && !errors.id}
                  isInvalid={!!errors.id && touched.id}
                  disabled={props.isDisabled}
                />
                {errors.id && touched.id ? (
                  <div className={styles["invalid-error"]}>{errors.id}</div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("name", true, false)}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                {errors.name && touched.name ? (
                  <div className={styles["invalid-error"]}>{errors.name}</div>
                ) : null}
              </Form.Group>
            </Row>
            <SubmitButton className="submitBtn" variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CorporationForm;
