import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class PAndIFranchiseDataService {
  // Get all p-and-i-franchises from the database
  getAll(filter) {
    return axios.post(API_URL + "/p-and-i-franchises", filter, {
      headers: authHeader(),
    });
  }

  // Create a new p-and-i-franchise
  create(data) {
    return axios.post(API_URL + "/p-and-i-franchise", data, {
      headers: authHeader(),
    });
  }

  // Get just one p-and-i-franchise
  get(id) {
    return axios.get(API_URL + `/p-and-i-franchise/${id}`, {
      headers: authHeader(),
    });
  }

  // Delete a specific p-and-i-franchise out of the table.
  delete(pnr, id) {
    return axios.delete(API_URL + `/p-and-i-franchise/${pnr}/${id}`, {
      headers: authHeader(),
    });
  }

  // Update the data of one specific p-and-i-franchise.
  update(pnr, id, data) {
    return axios.put(API_URL + `/p-and-i-franchise/${pnr}/${id}`, data, {
      headers: authHeader(),
    });
  }
}

export default new PAndIFranchiseDataService();
