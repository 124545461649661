import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { percentagePatternPremium } from "../../patterns/patterns";
import CorporationDataService from "../../services/corporation.data.service";
import styles from "./HullCorporationForm.module.scss";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";

const HullCorporationForm = (props) => {
  const [hullCorpFields, setHullCorpFields] = useState({
    hull: props.editData.hull,
    corporation: "",
    share: "",
    premium_rate: "",
  });
  // Variable with the values for the input fields
  const data = props.editData;
  useEffect(() => {
    setHullCorpFields({
      hull: data.hull,
      corporation: { value: data.corporation, label: data.corporation.name },
      share: data.share,
      premium_rate: data.premium_rate,
    });
  }, [data]);

  console.log(hullCorpFields)

  const resetHullCorpFields = () => {
    setHullCorpFields({
      hull: props.editData.hull,
      corporation: "",
      share: "",
      premium_rate: "",
    });
  };

  // State object of corporations to display them in the select field.
  const [corporation, setCorporation] = useState([]);

  // Function to get all corporations out of the database.
  const getAllCorporations = () => {
    CorporationDataService.getAll({ filter: "" })
      .then((response) => {
        let corporation = [];
        response.data.forEach((element) => {
          corporation.push({ value: element, label: element.name });
        });
        setCorporation(corporation);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get all corporations if the component gets rendered the first time
  useEffect(() => {
    getAllCorporations();
  }, []);

  // Object schmema for the formik tag to validate the input fields
  const HullCorporationObjectSchema = Yup.object().shape({
    corporation: Yup.object().required("Pflichtfeld").nullable(),
    share: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePatternPremium,
        "Mit Komma trennen und maximal 4 Nachkommstellen"
      ),
    premium_rate: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePatternPremium,
        "Mit Komma trennen und maximal 4 Nachkommstellen"
      ),
  });

  return (
    <>
      <Formik
        validationSchema={HullCorporationObjectSchema}
        onSubmit={(values) => {
          props.onSubmit(values);
          resetHullCorpFields();
        }}
        enableReinitialize
        initialValues={hullCorpFields}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCorporation">
                <Form.Label>Gesellschaft</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="corporation"
                  name="corporation"
                  placeholder="Wähle Gesellschaft..."
                  onChange={(selected) => {
                    values.corporation = selected;
                    setFieldValue("corporation", selected);
                  }}
                  onBlur={handleBlur}
                  options={corporation}
                  value={values.corporation}
                  styles={chooseCustomSelectStyle(
                    errors.corporation,
                    values.corporation
                  )}
                />
                {errors.corporation ? (
                  <div className={styles["invalid-error"]}>
                    {errors.corporation}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridShare">
                <Form.Label>Anteil</Form.Label>
                <Form.Control
                  name="share"
                  type="text"
                  placeholder="Anteil"
                  value={values.share}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.share && !errors.share}
                  isInvalid={!!errors.share}
                />
                {errors.share ? (
                  <div className={styles["invalid-error"]}>{errors.share}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPremiumRate">
                <Form.Label>Prämiensatz</Form.Label>
                <Form.Control
                  name="premium_rate"
                  type="text"
                  placeholder="Prämiensatz"
                  value={values.premium_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.premium_rate && !errors.premium_rate}
                  isInvalid={!!errors.premium_rate}
                />
                {errors.premium_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.premium_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <SubmitButton className="submitBtn" variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default HullCorporationForm;
