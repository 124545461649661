import React, { useState, useEffect } from "react";
import FranchiseDataService from "../../services/franchise.data.service";
import * as Yup from "yup";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { pricePattern } from "../../patterns/patterns";
import styles from "./PAndIFranchiseForm.module.scss";
import { Formik } from "formik";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";

const PAndIFranchiseForm = (props) => {
  console.log(props.editData.pAndI)
  const [pAndIFranchiseFields, setPAndIFranchiseFields] = useState({
    pAndI: props.editData.pAndI,
    franchise: "",
    amount: "",
  });

  const data = props.editData;
  useEffect(() => {
    setPAndIFranchiseFields({
      pAndI: data.pAndI,
      franchise: { value: data.franchise, label: data.franchise.name },
      amount: data.amount,
    });
  }, [data]);

  console.log(pAndIFranchiseFields);

  const resetPAndIFranchiseFields = () => {
    setPAndIFranchiseFields({
      pAndI: data.pAndI,
      franchise: "",
      amount: "",
    });
  };

  // State object of franchises to display them in the select field.
  const [franchises, setFranchises] = useState([]);

  const getAllFranchises = () => {
    FranchiseDataService.getAll({ filter: "" })
      .then((response) => {
        let franchise = [];
        response.data.forEach((element) => {
          franchise.push({ value: element, label: element.name });
        });
        setFranchises(franchise);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllFranchises();
  }, []);

  const PAndIFranchiseObjectSchema = Yup.object().shape({
    franchise: Yup.object().required("Pflichtfeld").nullable(),
    amount: Yup.string().matches(pricePattern).required("Pflichtfeld"),
  });

  return (
    <>
      <Formik
        validationSchema={PAndIFranchiseObjectSchema}
        onSubmit={(values) => {
          console.log(values)
          props.onSubmit(values);
          resetPAndIFranchiseFields();
        }}
        enableReinitialize
        initialValues={pAndIFranchiseFields}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridFranchise">
                <Form.Label>Franchise</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="franchise"
                  name="franchise"
                  placeholder="Wähle Franchise..."
                  onChange={(selected) => {
                    values.franchise = selected;
                    setFieldValue("franchise", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() => setFieldTouched("franchise", true, true)}
                  options={franchises}
                  value={values.franchise}
                  styles={chooseCustomSelectStyle(
                    errors.franchise,
                    values.franchise,
                    touched.franchise
                  )}
                />
                {errors.franchise ? (
                  <div className={styles["invalid-error"]}>
                    {errors.franchise}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridAmount">
                <Form.Label>Höhe</Form.Label>
                <Form.Control
                  name="amount"
                  type="text"
                  placeholder="Höhe"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("amount", true, false)}
                  isValid={touched.amount && !errors.amount}
                  isInvalid={!!errors.amount && touched.amount}
                />
                {errors.amount && touched.amount ? (
                  <div className={styles["invalid-error"]}>{errors.amount}</div>
                ) : null}
              </Form.Group>
            </Row>
            <SubmitButton className="submitBtn" variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PAndIFranchiseForm;
