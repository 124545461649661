import "./Header.scss";
import { useEffect, useState } from "react";
import Bar from "../UI/Bar/Bar";
import Sidenav from "../UI/Sidenav/Sidenav";
import React from "react";

function Header() {
  // Variable which indicates if the sidenav is expanded or not
  const [collapsed, setCollapsed] = useState(true);

  // Holds the logic to shift the content container to the right or the left if
  // the menu is collapsed or extended
  useEffect(() => {
    var elements = document.getElementsByClassName("main");
    if (!collapsed) {
      //console.log("opening sidebar");
      for (let item of elements) {
        item.style.marginLeft = "300px";
      }
    } else {
      //console.log("closing sidebar");
      for (let item of elements) {
        item.style.marginLeft = "110px";
      }
    }
  }, [collapsed]);

  // Function to handle the collapse and expand of the collapse button and the title
  const handleCollapse = () => {
    setCollapsed(!collapsed);
    if (collapsed === false) {
      document.getElementById("header").style.alignItems = "center";
      document.getElementById("menuHeading").innerText = "SuS";
    } else {
      document.getElementById("header").style.alignItems = "flex-end";
      document.getElementById("menuHeading").innerText =
        "Störk und Süssmann Versicherungen";
    }
  };

  return (
    <>
      <Bar />
      <Sidenav collapsed={collapsed} onCollapse={handleCollapse} />
    </>
  );
}

export default Header;
