import React from "react";
import styles from "./Table.module.scss";
import { Button, Spinner } from "react-bootstrap";
import { dateFormatterDE } from "../../formatter/date-formatter";

function Table(props) {
  const formatTableEntries = (datum, header) => {
    const valueIsNull = Object.values(datum)[header] === null;
    const valueIsObject = typeof Object.values(datum)[header] === "object";
    const valueIsBoolean = typeof Object.values(datum)[header] === "boolean";
    const valueIsDate = /^[0-9]{4}[-]{1}[0-9]{2}[-]{1}[0-9]{2}$/.test(
      Object.values(datum)[header]
    );
    if (valueIsNull) {
      return "-";
    } else {
      if (valueIsObject) {
        return Object.values(datum)[header][props.attr[header]];
      } else {
        if (valueIsDate) {
          return dateFormatterDE(Object.values(datum)[header]);
        } else if (valueIsBoolean) {
          return Object.values(datum)[header] === true ? "Ja" : "Nein";
        } else {
          return Object.values(datum)[header];
        }
      }
    }
  };

  const placeSigns = (header) => {
    const signArrayIsEmpty = props.signArray === undefined;
    if (!signArrayIsEmpty) {
      return " " + props.signArray[header];
    } else {
      return "";
    }
  };

  return (
    <div className={`${styles[props.tableStyle]}`}>
      <table>
        <thead>
          <tr>
            {props.header.map((headerItem, index) => (
              <th key={index}>{headerItem}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.items.map((datum, index) => (
            <tr key={index}>
              {Object.keys(props.headers).map((header, key) => (
                <td key={key}>
                  <span>
                    {formatTableEntries(datum, header)}
                    {placeSigns(header)}
                  </span>
                </td>
              ))}
              <td>
                {props.edit && (
                  <Button
                    className={styles.tableEditBtn}
                    data-item={datum}
                    onClick={() => {
                      props.onEdit(datum);
                      console.log(datum);
                    }}
                  >
                    <i
                      className="bi bi-pencil-square"
                      style={{ color: "white" }}
                    ></i>
                  </Button>
                )}
                {props.delete && (
                  <Button
                    className={`${styles.tableDelBtn} btn-danger`}
                    data-item={datum}
                    onClick={() => props.onDelete(datum)}
                  >
                    <i className="bi bi-trash3" style={{ color: "white" }}></i>
                  </Button>
                )}
                {props.moreInfo && (
                  <Button
                    className={`${styles.tableDisplayBtn}`}
                    data-item={datum}
                    onClick={() => props.onDisplay(datum)}
                  >
                    <i
                      className="bi bi-info-circle"
                      style={{ color: "white" }}
                    ></i>
                  </Button>
                )}
                {props.invoice && (
                  <Button
                    className={`${styles.tableInvoiceBtn}`}
                    data-item={datum}
                    onClick={() => props.onInvoice(datum)}
                  >
                    <i
                      className="bi bi-file-earmark-plus"
                      style={{ color: "white" }}
                    ></i>
                  </Button>
                )}
                {props.credit && (
                  <Button
                    className={`${styles.tableInvoiceBtn}`}
                    data-item={datum}
                    onClick={() => props.onCredit(datum)}
                  >
                    <i
                      className="bi bi-file-earmark-plus"
                      style={{ color: "white" }}
                    ></i>
                  </Button>
                )}
                {props.download && (
                  <Button
                    className={`${styles.tableInvoiceBtn}`}
                    data-item={datum}
                    onClick={() => props.onDownload(datum)}
                  >
                    <i
                      className="bi bi-download"
                      style={{ color: "white" }}
                    ></i>
                  </Button>
                )}
              </td>
              {props.extended && (
                <td>
                  <Button
                    className={`${styles.tableDisplayBtn}`}
                    data-item={datum}
                    onClick={() => props.onExtend(datum)}
                  >
                    <i
                      className="bi bi-calendar-plus"
                      style={{ color: "white" }}
                    ></i>
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {props.loading && (
        <div className={styles["wrap-container"]}>
          <Spinner animation="border" variant="primary" />
          <p className={styles.text}>Lädt Daten aus der Datenbank...</p>
        </div>
      )}
      {props.unauthorized && (
        <div className={styles["wrap-container"]}>
          <p className={styles["error-text"]}>
            <b>Nicht autorisiert. Bitte erneut einloggen!</b>
          </p>
        </div>
      )}
      {!props.unauthorized &&
        !props.error &&
        props.items.length < 1 &&
        !props.loading && (
          <div className={styles["wrap-container"]}>
            <p>
              <b>Keine Daten vorhanden!</b>
            </p>
          </div>
        )}
      {props.error && (
        <div className={styles["wrap-container"]}>
          <p className={styles["error-text"]}>
            <b>Ein Fehler ist aufgetreten!</b>
          </p>
        </div>
      )}
    </div>
  );
}

export default Table;
