import React, { useCallback, useEffect, useState } from "react";
import InterestDataService from "../../services/interest.data.service";
import InterestForm from "./InterestForm";
import Header from "../Header/Header";
import CrudContent from "../CrudContent/CrudContent";
import { dateFormatterDE } from "../../formatter/date-formatter";
import InvoiceForm from "../Invoice/InvoiceForm";
import InvoiceDataService from "../../services/invoice.data.service";
import useAlert from "../../hooks/use-alert";
import GenericAlert from "../Alert/GenericAlert";
import InterestChangeForm from "./InterestChangeForm";
import { checkIfItemIsStringAndReplaceComma } from "../../helper/helper";

// The headers to be displayed in the table.
export const interestHeaders = [
  "Policennummer:",
  "Kasko:",
  "ZW:",
  "Versicherungsbeginn:",
  "Versicherungssumme:",
  "Prämiensatz:",
  "Prämie:",
  "Steuer 1:",
  "Courtage:",
  "Schiff:",
  "Gesellschaft:",
  "Prämiensatz G.",
  "Kündigung:",
  "51:",
  "Versicherungszeitraum von:",
  "Versicherungszeitraum bis:",
];

// Array to set currency or percentage sign after specific values.
export const interestSignArray = [
  "",
  "",
  "",
  "",
  "€",
  "%",
  "€",
  "%",
  "%",
  "",
  "",
  "%",
  "",
  "€",
  "",
  "",
];

const Interest = () => {
  // The state which holds all interests
  const [allInterests, setAllInterests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();
  const [dueDateIsDisabled, setDueDateIsDisabled] = useState(false);

  // The headers to be displayed in the table.
  const header = [
    "Policennummer",
    "Kasko",
    "ZW",
    "Beginn",
    "Versicherungssumme",
    "Prämiensatz",
    "Prämie",
    "Steuer 1",
    "Courtage",
    "Schiff",
    "Gesellschaft",
    "Prämiensatz G.",
    "Kündigung",
    "Bearbeiten",
  ];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "Interesse löschen";
  const modalTitleEdit = "Interesse bearbeiten";
  const modalTitleAdd = "Interesse hinzufügen";
  const modalBody = "Möchten Sie die Interesse wirklich löschen?";

  const initialInterestObject = {
    policy_number: "",
    hull: "",
    payment_method: "",
    insurance_start: "",
    insurance_sum: "",
    premium_rate: "",
    premium: "",
    tax: "",
    brokerage: "",
    ship: "",
    corporation: "",
    share: "",
    termination: "",
    fifty_one_sum: "",
    insurance_period_from: "",
    insurance_period_to: "",
  };

  // The data to be edited
  const [editData, setEditData] = useState(initialInterestObject);

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  // Function to get all interests out of the database.
  const getAllInterests = useCallback(() => {
    InterestDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          // Array which holds all interests
          var inter = [];
          response.data.forEach((element) => {
            inter.push({
              policy_number: element.policy_number,
              hull: element.hull,
              payment_method: element.payment_method,
              insurance_start: element.insurance_start,
              insurance_sum: element.insurance_sum.toString().replace(".", ","),
              premium_rate: element.premium_rate.toString().replace(".", ","),
              premium: element.premium.toString().replace(".", ","),
              tax: element.tax !== null ? element.tax.toString().replace(".", ",") : null,
              brokerage: element.brokerage.toString().replace(".", ","),
              ship: element.ship,
              corporation: element.corporation,
              premium_rate_corp: element.premium_rate_corp
                .toString()
                .replace(".", ","),
              termination: element.termination,
              fifty_one_sum:
                element.fifty_one_sum !== null
                  ? element.fifty_one_sum.toString().replace(".", ",")
                  : null,
              insurance_period_from: element.insurance_period_from,
              insurance_period_to: element.insurance_period_to,
            });
          });
          setAllInterests(inter);
        } else {
          setAllInterests([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  // Call getAllIntersts() everytime the filter value changes.
  useEffect(() => {
    getAllInterests();
  }, [getAllInterests]);

  // Function to delete an interest object out of the database.
  // Accepts an interest object to get its policynumber.
  const deleteItem = (item) => {
    InterestDataService.delete(item.policy_number)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die Interesseversicherung "' +
            item.policy_number +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllInterests();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Interesseversicherung "' +
            item.policy_number +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Replace all commas with dots.
  // Must be done for all decimal values because
  // the backend only accepts data with a dot.
  const replaceComma = (item) => {
    item.insurance_sum = checkIfItemIsStringAndReplaceComma(item.insurance_sum);
    item.premium = checkIfItemIsStringAndReplaceComma(item.premium);
    item.premium_rate = checkIfItemIsStringAndReplaceComma(item.premium_rate);
    item.tax = checkIfItemIsStringAndReplaceComma(item.tax);
    item.brokerage = checkIfItemIsStringAndReplaceComma(item.brokerage);
    item.premium_rate_corp = checkIfItemIsStringAndReplaceComma(
      item.premium_rate_corp
    );
  };

  const selectValuesFromObjects = (item) => {
    if (item.policy_number.value !== undefined) {
      item.policy_number = item.policy_number.value;
    }
    if (item.ship.value !== undefined) {
      item.ship = item.ship.value;
    }
    if (item.corporation.value !== undefined) {
      item.corporation = item.corporation.value;
    }
    if (item.hull !== null && item.hull.value !== undefined) {
      item.hull = item.hull.value;
    }
  };

  // Function to edit a specific interest item.
  const editItem = (item) => {
    selectValuesFromObjects(item);
    replaceComma(item);
    InterestDataService.update(item.policy_number, item)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die Interesseversicherung "' +
            item.policy_number +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        getAllInterests();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Interesseversicherung "' +
            item.policy_number +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to create a new loh item in the database
  const addItem = (item) => {
    selectValuesFromObjects(item);
    replaceComma(item);
    InterestDataService.create(item)
      .then((response) => {
        let interest = response.data;
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die Interesseversicherung "' +
            interest.policy_number +
            '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllInterests();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          "Die Interesseversicherung konnte nicht erstellt werden!",
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    const data = { ...item };
    console.log(data);
    setEditData(data);
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    console.log(item);
    // Create an array which holds multiple arrays each with
    item.hull = item.hull !== null ? item.hull.policy_number : null;
    item.corporation = item.corporation.name;
    item.ship = item.ship.shipname;
    item.insurance_start = dateFormatterDE(item.insurance_start);
    item.termination = dateFormatterDE(item.termination);
    item.insurance_period_from = dateFormatterDE(item.insurance_period_from);
    item.insurance_period_to = dateFormatterDE(item.insurance_period_to);
    // one key value pair separaeted by a comma.
    let interestArray = Object.entries(item);
    for (let i = 0; i < interestHeaders.length; i++) {
      // Replace all keys with the header value.
      interestArray[i][0] = interestHeaders[i];
      interestArray[i][2] = interestSignArray[i];
    }
    console.log(interestArray);
    // Set the displayed data.
    setDisplayData({ array: interestArray });
  };

  const handleYearChange = (year) => {
    console.log(year);
    setFilter((prevFilter) => {
      return { ...prevFilter, year: year };
    });
  };

  const handleInvoiceCreation = (item) => {
    InterestDataService.get(item.div.policy_number)
      .then((response) => {
        const invoiceData = {
          interest: response.data,
          dueDatesList: item.dueDatesList,
        };
        console.log(invoiceData);
        InvoiceDataService.generateInterestInvoice(invoiceData)
          .then((response) => {
            handleAlertVisible(
              "Erfolgreiche Rechnungsgenerierung",
              "Die Interesserechnung wurde erfolgreich generiert!",
              "success"
            );
            console.log(response);
          })
          .catch((error) => {
            handleAlertVisible(
              "Fehler",
              "Die Interesserechnung konnte nicht generiert werden!",
              "danger"
            );
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setDisabled = (isDisabled) => {
    setDueDateIsDisabled(isDisabled);
  }

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="Interesse"
          items={allInterests}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <InterestForm editData={editData} btnText="Bearbeiten" isEditing />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Interessen..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<InterestForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 1: "policy_number", 9: "shipname", 10: "name" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          signArray={interestSignArray}
          edit
          onYearChange={handleYearChange}
          hasYearFilter
          onInvoiceCreation={handleInvoiceCreation}
          invoice
          dueDatesForm={
            <InvoiceForm btnText="Manuelle Fälligkeiten anwenden" isDisabled={dueDateIsDisabled} />
          }
          dueDatesIsDisabled={setDisabled}
          invoiceModalHeader="Interesse Rechungsgenerierung"
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          changeDivision
          onChangeItem={addItem}
          formChange={<InterestChangeForm btnText="Hinzufügen" />}
          changeTitle="Interesse Änderung hinzufügen"
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};

export default Interest;
