import React, { useState } from "react";
//import styles from "./CrudContent.module.scss";
import CustomFormModal from "../UI/Modals/CustomFormModal";
import CustomDeleteModal from "../UI/Modals/CustomDeleteModal";
import Table from "../Table/Table";
import ItemFilter from "../ItemFilter/ItemFilter";
import CustomMoreInfoModal from "../UI/Modals/CustomMoreInfoModal";
import CustomModal from "../UI/Modals/CustomModal";
import { createSpecificDivisionObject } from "../../helper/helper";

function CrudContent(props) {
  // Variable to display the delete modal
  const [show, setShow] = useState(false);
  // Variable to display the edit modal
  const [showEdit, setShowEdit] = useState(false);
  // Variable to display the add modal
  const [showAdd, setShowAdd] = useState(false);
  // Variable to display the dsiplay modal
  const [showDisplay, setShowDisplay] = useState(false);

  const [showInvoice, setShowInvoice] = useState(false);
  const [showCredit, setShowCredit] = useState(false);

  const [showChange, setShowChange] = useState(false);

  const [division, setDivision] = useState("");
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

  const [creditItem, setCreditItem] = useState({});

  // Variable with the current item which is selected
  const [item, setItem] = useState("");
  // Create a copy of the headers object and delete the last index
  const headers = Object.assign([], props.header).splice(
    0,
    props.header.length - 1
  );
  console.log("Headers length: " + headers.length);
  // Accept the header array
  const header = props.header;

  //console.log(props.items)

  // Function to handle the delete of an specific entry
  const handleDelete = () => {
    console.log(item);
    props.onDelete(item);
    setShow(false);
  };

  // The functions to open and close the modal
  const handleClose = () => setShow(false);
  const handleShow = (datum) => {
    setItem(datum);
    setShow(true);
  };

  // Function to handle the edit of the items
  const handleEdit = (e) => {
    handleCloseEdit();
    console.log(e);
    props.onEditItem(e);
  };

  // Functions to close and open the modal
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (data) => {
    console.log(data);
    props.onEditItemReceive(data);
    setShowEdit(true);
  };

  // Function to handle adding of new items to the table
  const handleAdd = (e) => {
    handleCloseAdd();
    console.log(e);
    props.onAddItem(e);
  };

  // Function to close and open the modal
  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => {
    setShowAdd(true);
  };

  // Function to handle adding of new items to the table
  const handleChange = (e) => {
    console.log(e);
    const data = { ...e };
    console.log(data);
    props.onChangeItem(data);
    handleCloseChange();
  };

  // Function to close and open the modal
  const handleCloseChange = () => {
    setShowChange(false);
  };
  const handleShowChange = () => {
    setShowChange(true);
  };

  // Function to close and open the modal
  const handleCloseDisplay = () => setShowDisplay(false);
  const handleShowDisplay = (data) => {
    console.log(data);
    props.onDisplayItemReceive(data);
    setShowDisplay(true);
  };

  // Methode to get the filter value out of the input field
  const handleFilter = (e) => {
    let filter = { filter: e.target.value };
    props.onFilter(filter);
  };

  const handleCloseInvoice = () => {
    setShowInvoice(false);
  };
  const handleShowInvoice = (data) => {
    setHasPaymentMethod(data.payment_method !== '' ? true : false);
    props.dueDatesIsDisabled(data.payment_method !== '' ? true : false)
    setDivision(data);
    setShowInvoice(true);
  };

  const handleInvoiceCreation = (dueDatesList) => {
    handleCloseInvoice();
    const newDivision = { ...division };
    if (dueDatesList !== null) {
      const invoiceCreationData = { div: newDivision, dueDatesList: [] };
      props.onInvoiceCreation(invoiceCreationData);
    } else {
      const invoiceCreationData = {
        div: newDivision,
        dueDatesList: dueDatesList,
      };
      props.onInvoiceCreation(invoiceCreationData);
    }
  };

  const handleShowCredit = (data) => {
    setCreditItem(data);
    setShowCredit(true);
  };

  const handleCloseCredit = () => {
    setShowCredit(false);
  };

  const handleCreditCreation = () => {
    const newCreditItem = { ...creditItem };
    console.log(newCreditItem);
    const newDivision = { ...creditItem.division };
    const specificDivision = createSpecificDivisionObject(newDivision);
    const creditCreationData = {
      ...specificDivision,
      credit_period_from: newCreditItem.credit_period_from,
      credit_period_to: newCreditItem.credit_period_to,
      id: newCreditItem.id
    };
    props.onCreditCreation(creditCreationData, newCreditItem.division_type);
    handleCloseCredit();
  };

  const handleDownload = (item) => {
    console.log(item);
    props.onDownloadInvoice(item);
  };

  return (
    <>
      <div>
        <h2>{props.heading}</h2>
        <div className="col">
          <ItemFilter
            placeholder={props.placeholder}
            onChange={handleFilter}
            onAdd={handleShowAdd}
            onAddChange={handleShowChange}
            onYearChange={props.onYearChange}
            hasYearFilter={props.hasYearFilter}
            changeDivision={props.changeDivision}
            add={props.add}
          />
          <Table
            headers={headers}
            header={header}
            items={props.items}
            onEdit={handleShowEdit}
            onDelete={handleShow}
            delete={props.delete}
            onDisplay={handleShowDisplay}
            attr={props.attr}
            moreInfo={props.moreInfo}
            edit={props.edit}
            tableStyle="tableContainer"
            signArray={props.signArray}
            onInvoice={handleShowInvoice}
            invoice={props.invoice}
            loading={props.loading}
            unauthorized={props.unauthorized}
            error={props.error}
            onDownload={handleDownload}
            download={props.download}
            credit={props.credit}
            onCredit={handleShowCredit}
          />
        </div>
      </div>

      <CustomDeleteModal
        modalTitle={props.modalTitle}
        modalBody={props.modalBody}
        onClose={handleClose}
        onDelete={handleDelete}
        onHide={handleClose}
        show={show}
      />

      {props.changeDivision && (
        <CustomFormModal
          show={showChange}
          onSubmit={handleChange}
          form={props.formChange}
          onHide={handleCloseChange}
          modalTitle={props.changeTitle}
        />
      )}

      {props.edit && (
        <CustomFormModal
          show={showEdit}
          onSubmit={handleEdit}
          form={props.form}
          onHide={handleCloseEdit}
          modalTitle={props.modalTitleEdit}
        />
      )}
      {props.hasForm && (
        <CustomFormModal
          show={showAdd}
          onSubmit={handleAdd}
          form={props.formAdd}
          onHide={handleCloseAdd}
          modalTitle={props.modalTitleAdd}
        />
      )}

      {props.moreInfo && (
        <CustomMoreInfoModal
          show={showDisplay}
          items={props.displayItems}
          onHide={handleCloseDisplay}
          onClose={handleCloseDisplay}
          modalTitle={props.modalTitleAdd}
          hasTable={false}
        />
      )}

      {props.invoice && (
        <CustomFormModal
          show={showInvoice}
          onSubmit={handleInvoiceCreation}
          leftButton="Schließen"
          rightButton="Direkt generieren"
          onLeftBtn={handleCloseInvoice}
          onRightBtn={handleInvoiceCreation}
          form={
            <div>
              <p>
                <b>
                  Es ist immer das Zeitraum von und bis anzugeben. Bei
                  quartalsweiser Zahlung würde für das erste Quartal 01.01 -
                  31.3 angegeben werden. 
                </b>
              </p>
              {props.dueDatesForm}
            </div>
          }
          onHide={handleCloseInvoice}
          modalTitle={props.invoiceModalHeader}
          footer
          hasPaymentMethod={hasPaymentMethod}
        />
      )}

      {props.credit && (
        <CustomModal
          show={showCredit}
          leftButton="Abbrechen"
          rightButton="Generieren"
          modalTitle="Gutschrift generieren"
          modalBody="Möchten Sie die Gutschrift generieren?"
          onLeftBtn={handleCloseCredit}
          onHide={handleCloseCredit}
          onRightBtn={handleCreditCreation}
        />
      )}
    </>
  );
}

export default CrudContent;
