import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";

export default function PrivateRoute({ children }) {
  const { keycloak, initialized } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(180);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  return isLoggedIn && initialized ? children : null;
}
