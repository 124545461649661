import React from "react";
import styles from "./TableWithoutButtons.module.scss";

const TableWithoutButtons = (props) => {
  console.log(props.items)
    return (
        <div className={styles.tableContainerModal}>
          <table>
            <thead>
              <tr>
                {props.header.map((headerItem, index) => (
                  <th key={index}>{headerItem}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.items.map((datum, index) => (
                <tr key={index}>
                  {Object.keys(props.headers).map((header, key) => (
                    <td key={key}>
                      <span>
                        {Object.values(datum)[header] === null ? "-" : typeof Object.values(datum)[header] === "object"
                         ? Object.values(datum)[header][props.attr[header]]
                         : Object.values(datum)[header]}
                        {props.signArray !== undefined
                          ? " " + props.signArray[header]
                          : ""}
                      </span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
};

export default TableWithoutButtons;