import { useState, useCallback, useEffect } from "react";
import BillingDataService from "../../services/billing.data.service";
import CrudContent from "../CrudContent/CrudContent";
import Header from "../Header/Header";
import fileDownload from "js-file-download";

// The header of the table.
export const header = ["Id", "Sparte", "Pfad", "Dateiname", "Bearbeiten"];

export const billingHeaders = [
  "Id:",
  "Sparte:",
  "Pfad:",
  "Dateiname:",
  "Jahr:",
];

// The variables for the modals (add, delete, edit)
export const modalTitle = "Abrechnung löschen";
export const modalBody = "Möchten Sie die Abrechnung wirklich löschen?";

const CorporationBilling = () => {
  const [allCorporationBillings, setAllCorporationBillings] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // Count of header items that need to be skipped
  const skip = 1;

  const getAllCorporationBillings = useCallback(() => {
    BillingDataService
      .getAllCorporationBillings(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          let corpBillings = [];
          response.data.forEach((element) => {
            corpBillings.push({
              id: element.id,
              division_type: element.division_type,
              path: element.path,
              filename: element.filename,
              year: element.year,
            });
          });
          setAllCorporationBillings(corpBillings);
        } else {
          setAllCorporationBillings([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    getAllCorporationBillings()
  }, [getAllCorporationBillings]);

  // Function to set the current filter value.
 const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
        return { ...prevFilter, filter: filterValue.filter };
      });
  };

  const handleYearChange = (year) => {
    console.log(year);
    setFilter((prevFilter) => {
      return { ...prevFilter, year: year };
    });
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    console.log(item);
    // Create an array which holds multiple arrays each with
    // one key value pair separaeted by a comma.
    let billingArray = Object.entries(item);
    console.log(billingArray);
    for (let i = 0; i < billingHeaders.length; i++) {
      // Replace all keys with the header value.
      billingArray[i][0] = billingHeaders[i];
      billingArray[i][2] = "";
    }
    console.log(billingArray);
    // Set the displayed data.
    setDisplayData({ array: billingArray });
  };

  const handleDownload = (item) => {
    BillingDataService.downloadCorporationBilling(item.filename)
      .then((response) => {
        fileDownload(response.data, item.filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <main>
        <Header />
        <div className="main">
            <CrudContent
                heading="Gesellschaftsabrechnungen"
                items={allCorporationBillings}
                header={header}
                modalTitle={modalTitle}
                modalBody={modalBody}
                placeholder={"Filter Abrechnungen..."}
                onFilter={handleFilter}
                skip={skip}
                onDisplayItemReceive={handleDisplayItem}
                displayItems={displayData}
                moreInfo
                onYearChange={handleYearChange}
                hasYearFilter
                loading={isLoading}
                unauthorized={isUnauthorized}
                error={error}
                download
                onDownloadInvoice={handleDownload}
            />
        </div>
    </main>
  );

};

export default CorporationBilling;
