import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class PoolShipDataService {
  // Get all pool-ships from the database
  getAll(filter) {
    return axios.post(API_URL + "/pool-ships", filter, {
      headers: authHeader(),
    });
  }

  // Create a new pool-ship
  create(data) {
    return axios.post(API_URL + "/pool-ship", data, { headers: authHeader() });
  }

  // Update a specific pool-ship
  update(name, id, data) {
    return axios.put(API_URL + `/pool-ship/${name}/${id}`, data, {
      headers: authHeader(),
    });
  }

  // Delete a specific pool-ship out of the table.
  delete(name, id) {
    return axios.delete(API_URL + `/pool-ship/${name}/${id}`, { headers: authHeader() });
  }
}

export default new PoolShipDataService();