import React, { useEffect, useState } from "react";
import styles from "./ElectronicsInsuranceForm.module.scss";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { pricePattern, percentagePattern } from "../../patterns/patterns";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";
import ElectronicsInsuranceDataService from "../../services/electronics-insurance.data.service";
import { fillOutFieldsWithDivisonData } from "../../helper/helper";

const ElectronicsInsuranceChangeForm = (props) => {
  const data = props.editData;

  // The states for select fileds (policynumber)
  const [policynumber, setPolicynumber] = useState([]);

  const getAllPolicynumbers = () => {
    ElectronicsInsuranceDataService.getAllCurrent()
      .then((response) => {
        let policynumbers = [];
        response.data.forEach((element) => {
          console.log(element);
          policynumbers.push({
            value: element,
            label: element,
          });
        });
        console.log(policynumbers);
        setPolicynumber(policynumbers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllPolicynumbers() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllPolicynumbers();
  }, []);

  const ElectronicsInsuranceSchema = Yup.object().shape({
    insurance_sum: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
    price: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
    insurance_start: Yup.date().required("Pflichtfeld"),
    payment_method: Yup.string().nullable(),
    tax: Yup.string()
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
    brokerage: Yup.string()
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
    ship: Yup.object().required("Pflichtfeld").nullable(),
    corporation: Yup.object().required("Pflichtfeld").nullable(),
    fifty_one_sum: Yup.string()
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      )
      .nullable(),
    insurance_period_from: Yup.date().required("Pflichtfeld"),
    insurance_period_to: Yup.date().required("Pflichtfeld"),
  });

  return (
    <>
      <Formik
        validationSchema={ElectronicsInsuranceSchema}
        onSubmit={(values) => {
          const corporation = values.corporation;
          const ship = values.ship;
          console.log(values);
          props.onSubmit(values);
          values.corporation = corporation;
          values.ship = ship;
        }}
        initialValues={{
          policy_number:
            data !== undefined && data.policy_number !== null
              ? { value: data.policy_number, label: data.policy_number }
              : "",
          insurance_sum: data !== undefined ? data.insurance_sum : "",
          price: data !== undefined ? data.price : "",
          insurance_start: data !== undefined ? data.insurance_start : "",
          payment_method: data !== undefined ? data.payment_method : "",
          tax: data !== undefined ? data.tax : "",
          brokerage: data !== undefined ? data.brokerage : "",
          corporation: data !== undefined ? data.corporation : "",
          ship: data !== undefined ? data.ship : "",
          termination:
            data !== undefined && data.termination !== null
              ? data.termination
              : "",
          fifty_one_sum:
            data !== undefined && data.fifty_one_sum !== null
              ? data.fifty_one_sum
              : "",
          insurance_period_from:
            data !== undefined ? data.insurance_period_from : "",
          insurance_period_to:
            data !== undefined ? data.insurance_period_to : "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridHull">
                <Form.Label>Policennummer</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="policy_number"
                  name="policy_number"
                  placeholder="Wähle Policennummer..."
                  onChange={(selected) => {
                    if (selected !== null) {
                      ElectronicsInsuranceDataService.get(selected.value)
                        .then((response) => {
                          console.log(response.data);
                          const electronicsInsurance = response.data;
                          fillOutFieldsWithDivisonData(
                            electronicsInsurance,
                            values,
                            setFieldValue
                          );
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                    values.policy_number = selected;
                    setFieldValue("policy_number", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() =>
                    setFieldTouched("policy_number", true, false)
                  }
                  options={policynumber}
                  value={values.policy_number}
                  styles={chooseCustomSelectStyle(
                    errors.policy_number,
                    values.policy_number,
                    touched.policy_number
                  )}
                />
                {errors.policy_number && touched.policy_number ? (
                  <div className={styles["invalid-error"]}>
                    {errors.policy_number}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsuranceSum">
                <Form.Label>Versicherungssumme</Form.Label>
                <Form.Control
                  name="insurance_sum"
                  type="text"
                  placeholder="Versicherungssumme"
                  value={values.insurance_sum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("insurance_sum", true, false)}
                  isValid={touched.insurance_sum && !errors.insurance_sum}
                  isInvalid={!!errors.insurance_sum && touched.insurance_sum}
                />
                {errors.insurance_sum && touched.insurance_sum ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_sum}
                  </div>
                ) : null}
              </Form.Group>
            </Row>

            <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Versicherungsbeginn</Form.Label>
                <Form.Control
                  name="insurance_start"
                  type="date"
                  placeholder="Versicherungsbeginn"
                  value={values.insurance_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_start", true, false)
                  }
                  isValid={touched.insurance_start && !errors.insurance_start}
                  isInvalid={
                    !!errors.insurance_start && touched.insurance_start
                  }
                  disabled={props.isEditing}
                />
                {errors.insurance_start && touched.insurance_start ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_start}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPrice">
                <Form.Label>Preis</Form.Label>
                <Form.Control
                  name="price"
                  type="text"
                  placeholder="Preis"
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("price", true, false)}
                  isValid={touched.price && !errors.price}
                  isInvalid={!!errors.price && touched.price}
                />
                {errors.price && touched.price ? (
                  <div className={styles["invalid-error"]}>{errors.price}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridTaxOne">
                <Form.Label>Steuer</Form.Label>
                <Form.Control
                  name="tax"
                  type="text"
                  placeholder="Steuer"
                  value={values.tax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("tax", true, false)}
                  isValid={touched.tax && !errors.tax}
                  isInvalid={!!errors.tax && touched.tax}
                />
                {errors.tax && touched.tax ? (
                  <div className={styles["invalid-error"]}>{errors.tax}</div>
                ) : null}
              </Form.Group>
            </Row>
            <div className={styles.submitBtn}>
              <SubmitButton variant="primary" type="submit">
                {props.btnText}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ElectronicsInsuranceChangeForm;
