import React, { useState, useEffect, useCallback } from "react";
import LohFranchiseDataService from "../../services/loh-franchise.data.service";
import LohFranchiseForm from "./LohFranchiseForm";
import Header from "../Header/Header";
import CrudContent from "../CrudContent/CrudContent";
import { dateFormatterDE } from "../../formatter/date-formatter";
import InvoiceForm from "../Invoice/InvoiceForm";
import InvoiceDataService from "../../services/invoice.data.service";
import useAlert from "../../hooks/use-alert";
import GenericAlert from "../Alert/GenericAlert";
import { checkIfItemIsStringAndReplaceComma } from "../../helper/helper";
import LohFranchiseChangeForm from "./LohFranchiseChangeForm";

export const lohFranchiseHeaders = [
  "Policennummer:",
  "Loh:",
  "Festprämie:",
  "Festprämie Gesellschaft:",
  "Tagessatz:",
  "ZW:",
  "SB:",
  "Max.:",
  "Max. pro Jahr:",
  "Steuer 1:",
  "Beginn:",
  "Courtage:",
  "Schiff:",
  "Gesellschaft:",
  "Kündigung:",
  "51:",
  "Versicherungszeitraum von:",
  "Versicherungszeitraum bis:",
];

export const lohFranchiseSignArray = [
  "",
  "",
  "€",
  "€",
  "€",
  "",
  "",
  "",
  "",
  "%",
  "",
  "%",
  "",
  "",
  "",
  "€",
  "",
  "",
];

const LohFranchise = () => {
  // The state which holds all loh-franchises.
  const [allLohFranchises, setAllLohFranchises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();
  const [dueDateIsDisabled, setDueDateIsDisabled] = useState(false);

  // The headers to be displayed in the table.
  const header = [
    "Policennummer",
    "Loh",
    "Festprämie",
    "Festprämie G.",
    "Tagessatz",
    "ZW",
    "SB",
    "Kasko",
    "Maschine",
    "Steuer 1",
    "Beginn",
    "Courtage",
    "Schiff",
    "Gesellschaft",
    "Kündigung",
    "Bearbeiten",
  ];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "Loh-Franchise löschen";
  const modalTitleEdit = "Loh-Franchise bearbeiten";
  const modalTitleAdd = "Loh-Franchise hinzufügen";
  const modalBody = "Möchten Sie die Loh-Franchise wirklich löschen?";

  const initialLohFranchiseObject = {
    policy_number: "",
    loh: "",
    fixed_premium: "",
    fixed_premium_corp: "",
    daily_rate: "",
    payment_method: "",
    deductible: "",
    hull: "",
    machine: "",
    tax: "",
    insurance_start: "",
    brokerage: "",
    ship: "",
    corporation: "",
    termination: "",
    fifty_one_sum: "",
    insurance_period_from: "",
    insurance_period_to: "",
  };

  // The data to be edited
  const [editData, setEditData] = useState(initialLohFranchiseObject);

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  // Function to get all loh-franchises out of the database
  // based on the filtered value.
  const getAllLohFranchises = useCallback(() => {
    LohFranchiseDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          // Array which hold all loh-franchises
          var lohFran = [];
          response.data.forEach((element) => {
            lohFran.push({
              policy_number: element.policy_number,
              loh: element.loh,
              fixed_premium: element.fixed_premium.toString().replace(".", ","),
              fixed_premium_corp: element.fixed_premium_corp
                .toString()
                .replace(".", ","),
              daily_rate: element.daily_rate,
              payment_method: element.payment_method,
              deductible: element.deductible,
              hull: element.hull,
              machine: element.machine,
              tax: element.tax !== null ? element.tax.toString().replace(".", ",") : null,
              insurance_start: element.insurance_start,
              brokerage: element.brokerage.toString().replace(".", ","),
              ship: element.ship,
              corporation: element.corporation,
              termination: element.termination,
              fifty_one_sum:
                element.fifty_one_sum !== null
                  ? element.fifty_one_sum.toString().replace(".", ",")
                  : null,
              insurance_period_from: element.insurance_period_from,
              insurance_period_to: element.insurance_period_to,
            });
          });
          setAllLohFranchises(lohFran);
        } else {
          setAllLohFranchises([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    getAllLohFranchises();
  }, [getAllLohFranchises]);

  const deleteItem = (item) => {
    LohFranchiseDataService.delete(item.policy_number)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die LOH-Franchise-Versicherung "' +
            item.policy_number +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllLohFranchises();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die LOH-Franchise-Versicherung "' +
            item.policy_number +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Replace all commas with dots.
  // Must be done for all decimal values because
  // the backend only accepts data with a dot.
  const replaceComma = (item) => {
    item.tax = checkIfItemIsStringAndReplaceComma(item.tax);
    item.brokerage = checkIfItemIsStringAndReplaceComma(item.brokerage);
    item.fixed_premium = checkIfItemIsStringAndReplaceComma(item.fixed_premium);
    item.fixed_premium_corp = checkIfItemIsStringAndReplaceComma(
      item.fixed_premium_corp
    );
  };

  const selectValuesFromObjects = (item) => {
    if (item.policy_number.value !== undefined) {
      item.policy_number = item.policy_number.value;
    }
    if (item.ship.value !== undefined) {
      item.ship = item.ship.value;
    }
    if (item.corporation.value !== undefined) {
      item.corporation = item.corporation.value;
    }
    if (item.loh !== null && item.loh.value !== undefined) {
      item.loh = item.loh.value;
    }
  };

  // Function to edit a specific loh-franchise item.
  const editItem = (item) => {
    selectValuesFromObjects(item);
    console.log(item);
    replaceComma(item);
    LohFranchiseDataService.update(item.policy_number, item)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die LOH-Franchise-Versicherung "' +
            item.policy_number +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        getAllLohFranchises();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die LOH-Franchise-Versicherung "' +
            item.policy_number +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  const addItem = (item) => {
    selectValuesFromObjects(item);
    replaceComma(item);
    console.log(item);
    LohFranchiseDataService.create(item)
      .then((response) => {
        console.log(response.data);
        let lohFranchise = response.data;
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die LOH-Franchise-Versicherung "' +
            lohFranchise.policy_number +
            '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllLohFranchises();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          "Die LOH-Franchise-Versicherung konnte nicht erstellt werden!",
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    console.log(item);
    setEditData(item);
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    // Get one specific attribute of the objects to be displayed.
    item.ship = item.ship.shipname;
    item.loh = item.loh.policy_number;
    item.corporation = item.corporation.name;
    item.insurance_start = dateFormatterDE(item.insurance_start);
    item.termination = dateFormatterDE(item.termination);
    item.insurance_period_from = dateFormatterDE(item.insurance_period_from);
    item.insurance_period_to = dateFormatterDE(item.insurance_period_to);
    // Create an array which holds multiple arrays each with
    // one key value pair separaeted by a comma.
    let lohArray = Object.entries(item);
    for (let i = 0; i < lohFranchiseHeaders.length; i++) {
      // Replace all keys with the header value.
      lohArray[i][0] = lohFranchiseHeaders[i];
      lohArray[i][2] = lohFranchiseSignArray[i];
    }
    console.log(lohArray);
    // Set the displayed data.
    setDisplayData({ array: lohArray });
  };

  const handleYearChange = (year) => {
    console.log(year);
    setFilter((prevFilter) => {
      return { ...prevFilter, year: year };
    });
  };

  const handleInvoiceCreation = (item) => {
    LohFranchiseDataService.get(item.div.policy_number)
      .then((response) => {
        const invoiceData = {
          lohFranchise: response.data,
          dueDatesList: item.dueDatesList,
        };
        console.log(invoiceData);
        InvoiceDataService.generateLohFranchiseInvoice(invoiceData)
          .then((response) => {
            handleAlertVisible(
              "Erfolgreiche Rechnungsgenerierung",
              "Die LOH-Franchise-Rechnung wurde erfolgreich generiert!",
              "success"
            );
            console.log(response);
          })
          .catch((error) => {
            handleAlertVisible(
              "Fehler",
              "Die LOH-Franchise-Rechnung konnte nicht generiert werden!",
              "danger"
            );
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setDisabled = (isDisabled) => {
    setDueDateIsDisabled(isDisabled);
  }

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="Loh-Franchise"
          items={allLohFranchises}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <LohFranchiseForm
              editData={editData}
              btnText="Bearbeiten"
              isEditing
            />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Loh-Franchisen..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<LohFranchiseForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 1: "policy_number", 12: "shipname", 13: "name" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          signArray={lohFranchiseSignArray}
          edit
          onYearChange={handleYearChange}
          hasYearFilter
          onInvoiceCreation={handleInvoiceCreation}
          invoice
          dueDatesForm={
            <InvoiceForm btnText="Manuelle Fälligkeiten anwenden" isDisabled={dueDateIsDisabled} />
          }
          dueDatesIsDisabled={setDisabled}
          invoiceModalHeader="Interesse Rechnungsgenerierung"
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          changeDivision
          onChangeItem={addItem}
          formChange={<LohFranchiseChangeForm btnText="Hinzufügen" />}
          changeTitle="Loh-Franchise Änderung hinzufügen"
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};

export default LohFranchise;
