import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class DamageDataService {
  getAll(filter) {
    return axios.post(API_URL + "/damages", filter, {
      headers: authHeader(),
    });
  }

  get(id) {
    return axios.get(API_URL + `/damage/${id}`, { headers: authHeader() });
  }

  create(data) {
    return axios.post(API_URL + "/damage", data, {
      headers: authHeader(),
    });
  }

  delete(id) {
    return axios.delete(API_URL + `/damage/${id}`, {
      headers: authHeader(),
    });
  }

  update(id, data) {
    return axios.put(API_URL + `/damage/${id}`, data, {
      headers: authHeader(),
    });
  }
}

export default new DamageDataService();
