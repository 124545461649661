import { Alert } from "react-bootstrap";
import styles from "./GenericAlert.module.scss"

const GenericAlert = (props) => {
    return (
      <div className={styles.container}>
      {props.show &&
      <Alert variant={props.alertVariant}>
        <Alert.Heading>{props.alertHeading}</Alert.Heading>
        <p>
         {props.alertBody}
        </p>
      </Alert>
      }
      </div>
    );
};

export default GenericAlert;