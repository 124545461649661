import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import styles from "./FranchiseForm.module.scss";

const FranchiseForm = (props) => {
  const data = props.editData;

  const FranchiseObjectSchema = Yup.object().shape({
    name: Yup.string()
      .required("Pflichtfeld")
      .max(100, "Der Franchisename darf nur maximal 100 Zeichen enthalten")
      .min(2, "Der Franchisename muss mindestens 2 Zeichen beinhalten"),
  });
  return (
    <>
      <Formik
        validationSchema={FranchiseObjectSchema}
        onSubmit={props.onSubmit}
        initialValues={{
          id: data !== undefined ? data.id : "",
          name: data !== undefined ? data.name : "",
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, touched, errors, values, setFieldTouched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("name", true, false)}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                {errors.name && touched.name ? (
                  <div className={styles["invalid-error"]}>{errors.name}</div>
                ) : null}
              </Form.Group>
            </Row>
            <SubmitButton className="submitBtn" variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FranchiseForm;
