import React, { useState, useEffect, useCallback } from "react";
import ElectronicsInsuranceDataService from "../../services/electronics-insurance.data.service";
import ElectronicsInsuranceForm from "./ElectronicsInsuranceForm";
import Header from "../Header/Header";
import CrudContent from "../CrudContent/CrudContent";
import { dateFormatterDE } from "../../formatter/date-formatter";
import useAlert from "../../hooks/use-alert";
import GenericAlert from "../Alert/GenericAlert";
import { checkIfItemIsStringAndReplaceComma } from "../../helper/helper";
import ElectronicsInsuranceChangeForm from "./ElectronicsInsuranceChangeForm";
import InvoiceForm from "../Invoice/InvoiceForm";
import InvoiceDataService from "../../services/invoice.data.service";

// Headers for the more information view.
export const electronicsInsuranceHeaders = [
  "Policennummer:",
  "Versicherungssumme:",
  "Festprämie:",
  "Festprämie G.:",
  "Preis:",
  "Zahlweise:",
  "Beginn:",
  "Steuer 1:",
  "Courtage:",
  "Schiff:",
  "Gesellschaft:",
  "Kündigung zum:",
  "51:",
  "Versicherungszeitraum von:",
  "Versicherungszeitraum bis:",
];

// Array to set currency or percentage sign after specific values.
export const electronicsInsuranceSignArray = [
  "",
  "€",
  "€",
  "€",
  "€",
  "",
  "",
  "%",
  "%",
  "",
  "",
  "",
  "€",
  "",
  "",
];

const ElectronicsInsurance = () => {
  // The state which holds all electronics insurances.
  const [allElectronicsInsurances, setAllElectronicsInsurances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();
  const [dueDateIsDisabled, setDueDateIsDisabled] = useState(false);

  // The headers to be displayed in the table.
  const header = [
    "Policennummer",
    "Versicherungssumme",
    "Festprämie",
    "Festprämie G.",
    "Preis",
    "ZW",
    "Beginn",
    "Steuer 1",
    "Courtage",
    "Schiff",
    "Gesellschaft",
    "Kündigung",
    "Bearbeiten",
  ];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "Elektronik-Versicherung löschen";
  const modalTitleEdit = "Elektronik-Versicherung bearbeiten";
  const modalTitleAdd = "Elektronik-Versicherung hinzufügen";
  const modalBody = "Möchten Sie die Elektronik-Versicherung wirklich löschen?";

  const initialElectronicsInsuranceObject = {
    policy_number: "",
    insurance_sum: "",
    fixed_premium: "",
    fixed_premium_corp: "",
    price: "",
    payment_method: "",
    insurance_start: "",
    tax: "",
    brokerage: "",
    ship: "",
    corporation: "",
    termination: "",
    fifty_one_sum: "",
    insurance_period_from: "",
    insurance_period_to: "",
  };

  // The data to be edited
  const [editData, setEditData] = useState(initialElectronicsInsuranceObject);

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  // Function to get all electronic insurances out of the database
  // based on the filtered value.
  const getAllElectronicInsurances = useCallback(() => {
    ElectronicsInsuranceDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          // Array which holds all electronic insurances.
          var elIn = [];
          response.data.forEach((element) => {
            elIn.push({
              policy_number: element.policy_number,
              insurance_sum: element.insurance_sum.toString().replace(".", ","),
              fixed_premium: element.fixed_premium.toString().replace(".", ","),
              fixed_premium_corp: element.fixed_premium_corp.toString().replace(".", ","),
              price: element.price.toString().replace(".", ","),
              payment_method: element.payment_method,
              insurance_start: element.insurance_start,
              tax: element.tax.toString().replace(".", ","),
              brokerage: element.brokerage.toString().replace(".", ","),
              ship: element.ship,
              corporation: element.corporation,
              termination: element.termination,
              fifty_one_sum:
                element.fifty_one_sum !== null
                  ? element.fifty_one_sum.toString().replace(".", ",")
                  : null,
              insurance_period_from: element.insurance_period_from,
              insurance_period_to: element.insurance_period_to,
            });
          });
          // Set all electronics insurances to the loh array created above.
          setAllElectronicsInsurances(elIn);
        } else {
          // Set all electronics insurances to its default value.
          setAllElectronicsInsurances([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  // Always calls getAllElectronicsInsurances() if the filter value changes.
  useEffect(() => {
    getAllElectronicInsurances();
  }, [getAllElectronicInsurances]);

  const deleteItem = (item) => {
    ElectronicsInsuranceDataService.delete(item.policy_number)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die Elektronikversicherung "' +
            item.policy_number +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllElectronicInsurances();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Elektronikversicherung "' +
            item.policy_number +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Replace all commas with dots.
  // Must be done for all decimal values because
  // the backend only accepts data with a dot.
  const replaceComma = (item) => {
    item.insurance_sum = checkIfItemIsStringAndReplaceComma(item.insurance_sum);
    item.price = checkIfItemIsStringAndReplaceComma(item.price);
    item.tax = checkIfItemIsStringAndReplaceComma(item.tax);
    item.brokerage = checkIfItemIsStringAndReplaceComma(item.brokerage);
    item.fifty_one_sum = checkIfItemIsStringAndReplaceComma(item.fifty_one_sum);
    item.fixed_premium = checkIfItemIsStringAndReplaceComma(item.fixed_premium);
    item.fixed_premium_corp = checkIfItemIsStringAndReplaceComma(item.fixed_premium_corp);
  };

  const selectValuesFromObjects = (item) => {
    if (item.policy_number.value !== undefined) {
      item.policy_number = item.policy_number.value;
    }
    if (item.ship.value !== undefined) {
      item.ship = item.ship.value;
    }
    if (item.corporation.value !== undefined) {
      item.corporation = item.corporation.value;
    }
  };

  // Function to edit a specific electronics insurance item.
  const editItem = (item) => {
    selectValuesFromObjects(item);
    replaceComma(item);
    ElectronicsInsuranceDataService.update(item.policy_number, item)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die Elektronikversicherung "' +
            item.policy_number +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        getAllElectronicInsurances();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Elektronikversicherung "' +
            item.policy_number +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to create a new electronics insurance item in the database
  const addItem = (item) => {
    selectValuesFromObjects(item);
    replaceComma(item);
    ElectronicsInsuranceDataService.create(item)
      .then((response) => {
        let electronicsInsurance = response.data;
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die Elektronikversicherung "' +
          electronicsInsurance.policy_number +
            '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllElectronicInsurances();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Elektronikversicherung konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    console.log(item);
    setEditData(item);
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    // Get one specific attribute of the objects to be displayed.
    item.ship = item.ship.shipname;
    item.corporation = item.corporation.name;
    item.insurance_start = dateFormatterDE(item.insurance_start);
    item.termination = dateFormatterDE(item.termination);
    item.insurance_period_from = dateFormatterDE(item.insurance_period_from);
    item.insurance_period_to = dateFormatterDE(item.insurance_period_to);
    // Create an array which holds multiple arrays each with
    // one key value pair separaeted by a comma.
    let elInArray = Object.entries(item);
    for (let i = 0; i < electronicsInsuranceHeaders.length; i++) {
      // Replace all keys with the header value.
      elInArray[i][0] = electronicsInsuranceHeaders[i];
      elInArray[i][2] = electronicsInsuranceSignArray[i];
    }
    console.log(elInArray);
    // Set the displayed data.
    setDisplayData({ array: elInArray });
  };

  const handleYearChange = (year) => {
    console.log(year);
    setFilter((prevFilter) => {
      return { ...prevFilter, year: year };
    });
  };

  const handleInvoiceCreation = (item) => {
    console.log(item)
    ElectronicsInsuranceDataService.get(item.div.policy_number)
      .then((response) => {
        const invoiceData = {
          electronicsInsurance: response.data,
          dueDatesList: item.dueDatesList,
        };
        console.log(invoiceData);
        InvoiceDataService.generateElectronicsInsuranceInvoice(invoiceData)
          .then((response) => {
            handleAlertVisible(
              "Erfolgreiche Rechnungsgenerierung",
              "Die Elektronik-Rechnung wurde erfolgreich generiert!",
              "success"
            );
            console.log(response);
          })
          .catch((error) => {
            handleAlertVisible(
              "Fehler",
              "Die Elektronik-Rechnung konnte nicht generiert werden!",
              "danger"
            );
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setDisabled = (isDisabled) => {
    setDueDateIsDisabled(isDisabled);
  }

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="Elektronik-Versicherung"
          items={allElectronicsInsurances}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <ElectronicsInsuranceForm
              editData={editData}
              btnText="Bearbeiten"
              isEditing={true}
            />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Elektronik-Versicherungen..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<ElectronicsInsuranceForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 9: "shipname", 10: "name" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo={true}
          signArray={electronicsInsuranceSignArray}
          edit={true}
          onYearChange={handleYearChange}
          hasYearFilter={true}
          onInvoiceCreation={handleInvoiceCreation}
          invoice
          dueDatesForm={
            <InvoiceForm btnText="Manuelle Fälligkeiten anwenden" isDisabled={dueDateIsDisabled}/>
          }
          dueDatesIsDisabled={setDisabled}
          invoiceModalHeader="Elektronikversicherung Rechungsgenerierung"
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          changeDivision
          onChangeItem={addItem}
          formChange={<ElectronicsInsuranceChangeForm btnText="Hinzufügen" />}
          changeTitle="Elektronikversicherung Änderung hinzufügen"
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};

export default ElectronicsInsurance;
