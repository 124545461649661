import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Policyholder from "./components/Policyholder/Policyholder";
import InsuredObject from "./components/InsuredObject/InsuredObject";
import Corporation from "./components/Corporation/Corporation";
import Hull from "./components/Hull/Hull";
import Loh from "./components/Loh/Loh";
import Pool from "./components/Pool/Pool";
import LohFranchise from "./components/Loh/LohFranchise";
import HullFranchise from "./components/Hull/HullFranchise";
import ElectronicsInsurance from "./components/ElectronicsInsurance/ElectronicsInsurance";
import Interest from "./components/Interest/Interest";
import Franchise from "./components/Franchise/Franchise";
import PAndI from "./components/PAndI/PAndI";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ShipOverview from "./components/ShipOverview/ShipOverview";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import React from "react";
import PrivateRoute from "./router/PrivateRouter";
import AccountingInvoice from "./components/Invoice/AccountingInvoice";
import CustomerInvoice from "./components/Invoice/CustomerInvoice";
import Damage from "./components/Damage/Damage";
import Credit from "./components/Credit/Credit";
import CreditInvoice from "./components/Invoice/CreditInvoice";
import CorporationBilling from "./components/Billing/CorporationBilling";
import CustomerBilling from "./components/Billing/CustomerBilling";
import TaxBilling from "./components/Billing/TaxBilling";

function App() {
  return (
    <div>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onLoad: "login-required" }}
        onTokens={(token) => {
          localStorage.setItem("keycloakToken", JSON.stringify(token));
        }}
        onEvent={(event, error) => {
          console.log(event, error);
          if (event === "onAuthSuccess") {
            localStorage.setItem("keycloakToken", JSON.stringify({ token: keycloak.token }));
          }
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PrivateRoute>
                  <Policyholder />
                </PrivateRoute>
              }
            />
            <Route
              path="/policyholder"
              element={
                <PrivateRoute>
                  <Policyholder />
                </PrivateRoute>
              }
            />
            <Route
              path="/insured-object"
              element={
                <PrivateRoute>
                  <InsuredObject />
                </PrivateRoute>
              }
            />
            <Route
              path="/corporation"
              element={
                <PrivateRoute>
                  <Corporation />
                </PrivateRoute>
              }
            />
            <Route
              path="/hull"
              element={
                <PrivateRoute>
                  <Hull />
                </PrivateRoute>
              }
            />
            <Route
              path="/loh"
              element={
                <PrivateRoute>
                  <Loh />
                </PrivateRoute>
              }
            />
            <Route
              path="/pool"
              element={
                <PrivateRoute>
                  <Pool />
                </PrivateRoute>
              }
            />
            <Route
              path="/loh-franchise"
              element={
                <PrivateRoute>
                  <LohFranchise />
                </PrivateRoute>
              }
            />
            <Route
              path="/hull-franchise"
              element={
                <PrivateRoute>
                  <HullFranchise />
                </PrivateRoute>
              }
            />
            <Route
              path="/electronics-insurance"
              element={
                <PrivateRoute>
                  <ElectronicsInsurance />
                </PrivateRoute>
              }
            />
            <Route
              path="/interest"
              element={
                <PrivateRoute>
                  <Interest />
                </PrivateRoute>
              }
            />
            <Route
              path="/franchise"
              element={
                <PrivateRoute>
                  <Franchise />
                </PrivateRoute>
              }
            />
            <Route
              path="/p-and-i"
              element={
                <PrivateRoute>
                  <PAndI />
                </PrivateRoute>
              }
            />
            <Route
              path="/ship-overview"
              element={
                <PrivateRoute>
                  <ShipOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="/accouting-invoice"
              element={
                <PrivateRoute>
                  <AccountingInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer-invoice"
              element={
                <PrivateRoute>
                  <CustomerInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit-invoice"
              element={
                <PrivateRoute>
                  <CreditInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="/damage"
              element={
                <PrivateRoute>
                  <Damage />
                </PrivateRoute>
              }
            />
            <Route
              path="/credit"
              element={
                <PrivateRoute>
                  <Credit />
                </PrivateRoute>
              }
            />
             <Route
              path="/corporation-billing"
              element={
                <PrivateRoute>
                  <CorporationBilling />
                </PrivateRoute>
              }
            />
             <Route
              path="/customer-billing"
              element={
                <PrivateRoute>
                  <CustomerBilling />
                </PrivateRoute>
              }
            />
            <Route
              path="/tax-billing"
              element={
                <PrivateRoute>
                  <TaxBilling />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
