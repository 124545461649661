import styles from "./SubmitButton.module.scss";
import { Button } from "react-bootstrap";
import React from "react";

function SubmitButton(props) {
  return (
    <Button className={styles.submitBtn} variant="primary" type="submit" disabled={props.disabled}>
      {props.children}
    </Button>
  );
}

export default SubmitButton;
