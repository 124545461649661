import React, { useState } from "react";
import CustomHullCorporationModal from "./CustomHullCorporationModal";
import CustomPageOneModal from "../CustomPageOneModal";

const HullMultiModal = (props) => {
  console.log(props.hull)
  // Count state for the modals.
  // Count 1 stands for the first CustomHullModal
  // and count 2 for the CustomHullCorporationModal.
  const [count, setCount] = useState(1);

  // Function to increase the count by one
  // Switch to modal two.
  const handleNext = () => {
    setCount((curCount) => {
      return curCount + 1;
    });
  };

  // Decrease the count by one to go back to modal one.
  const handleBack = () => {
    setCount((curCount) => {
      return curCount - 1;
    });
  };

  // Function to close both modals.
  // Checks wether its the first or second page of the modal.
  // If it is page two, the count needs to be decreased by one.
  const handleClose = () => {
    if (count > 1) handleBack();
    props.onHide();
  };

  return (
    <>
      {count === 1 && (
        <CustomPageOneModal
          onNext={handleNext}
          onClose={handleClose}
          onSubmit={props.onSubmit}
          form={props.form}
          show={props.show}
          modalTitle={props.modalTitle}
          preventNext={props.preventNext}
          isEditing={props.isEditing}
        />
      )}
      {count === 2 && (
        <CustomHullCorporationModal
          onBack={handleBack}
          onClose={handleClose}
          show={props.showCorp}
          onSubmit={props.onSubmitCorp}
          modalTitle={props.modalTitleCorp}
          hull={props.hull}
          onDelete={props.onDelete}
          isEditing={props.isEditing}
        />
      )}
    </>
  );
};

export default HullMultiModal;
