import React, { useEffect, useState } from "react";
import HullDataService from "../../services/hull.data.service";
import styles from "./HullFranchiseForm.module.scss";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { percentagePattern, pricePattern } from "../../patterns/patterns";
import CorporationDataService from "../../services/corporation.data.service";
import Select from "react-select";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";

const HullFranchiseForm = (props) => {
  const data = props.editData;

  // The states for select fileds (hull, corporation)
  const [hull, setHull] = useState([]);
  const [corporation, setCorporation] = useState([]);
  const [ship, setShip] = useState([]);

  // Function to get all ships out of the database.
  const getAllHulls = () => {
    HullDataService.getAll({ filter: "", year: "" })
      .then((response) => {
        let hull = [];
        response.data.forEach((element) => {
          hull.push({ value: element, label: element.policy_number });
        });
        setHull(hull);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllHulls() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllHulls();
  }, []);

  // Function to get all corporations out of the database.
  const getAllCorporations = () => {
    CorporationDataService.getAll({ filter: "" })
      .then((response) => {
        let corporation = [];
        response.data.forEach((element) => {
          corporation.push({ value: element, label: element.name });
        });
        setCorporation(corporation);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllCorporations() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllCorporations();
  }, []);

  // Function to get all ships out of the databse.
  const getAllShips = () => {
    InsuredObjectDataService.getAll({ filter: "" })
      .then((response) => {
        console.log(response.data);
        let ships = [];
        response.data.forEach((element) => {
          ships.push({ value: element, label: element.shipname });
        });
        setShip(ships);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllShips() function thr first time this component
  // is rendered.
  useEffect(() => {
    getAllShips();
  }, []);

  const HullFranchiseSchema = Yup.object().shape(
    {
      insurance_start: Yup.date().required("Pflichtfeld"),
      insurance_sum_one: Yup.string().when(["deductible_one", "premium_one"], {
        is: (deductible_one, premium_one) =>
          deductible_one !== undefined || premium_one !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      insurance_sum_two: Yup.string().when(["deductible_two", "premium_two"], {
        is: (deductible_two, premium_two) =>
          deductible_two !== undefined || premium_two !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      insurance_sum_three: Yup.string().when(
        ["deductible_three", "premium_three"],
        {
          is: (deductible_three, premium_three) =>
            deductible_three !== undefined || premium_three !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      insurance_sum_four: Yup.string().when(
        ["deductible_four", "premium_four"],
        {
          is: (deductible_four, premium_four) =>
            deductible_four !== undefined || premium_four !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      premium_one: Yup.string().when(["insurance_sum_one", "deductible_one"], {
        is: (insurance_sum_one, deductible_one) =>
          insurance_sum_one !== undefined || deductible_one !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      premium_two: Yup.string().when(["insurance_sum_two", "deductible_two"], {
        is: (insurance_sum_two, deductible_two) =>
          insurance_sum_two !== undefined || deductible_two !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      premium_three: Yup.string().when(
        ["insurance_sum_three", "deductible_three"],
        {
          is: (insurance_sum_three, deductible_three) =>
            insurance_sum_three !== undefined || deductible_three !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      premium_four: Yup.string().when(
        ["insurance_sum_four", "deductible_four"],
        {
          is: (insurance_sum_four, deductible_four) =>
            insurance_sum_four !== undefined || deductible_four !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      deductible_one: Yup.string().when(["insurance_sum_one", "premium_one"], {
        is: (insurance_sum_one, premium_one) =>
          insurance_sum_one !== undefined || premium_one !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      deductible_two: Yup.string().when(["insurance_sum_two", "premium_two"], {
        is: (insurance_sum_two, premium_two) =>
          insurance_sum_two !== undefined || premium_two !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      deductible_three: Yup.string().when(
        ["insurance_sum_three", "premium_three"],
        {
          is: (insurance_sum_three, premium_three) =>
            insurance_sum_three !== undefined || premium_three !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      deductible_four: Yup.string().when(
        ["insurance_sum_four", "premium_four"],
        {
          is: (insurance_sum_four, premium_four) =>
            insurance_sum_four !== undefined || premium_four !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      payment_method: Yup.string().nullable(),
      tax: Yup.string()
        .matches(
          percentagePattern,
          "Mit Komma trennen maximal 2 Nachkommastellen"
        )
        .nullable(),
      brokerage: Yup.string()
        .matches(
          percentagePattern,
          "Mit Komma trennen maximal 2 Nachkommastellen"
        )
        .required("Pflichtfeld"),
      hull: Yup.object().nullable(),
      corporation: Yup.object().required("Pflichtfeld").nullable(),
      insurance_period_from: Yup.date().required("Pflichtfeld"),
      insurance_period_to: Yup.date().required("Pflichtfeld"),
      ship: Yup.object().required("Pflichtfeld").nullable(),
      fixed_premium: Yup.string()
        .matches(
          pricePattern,
          "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
        )
        .required("Pflichtfeld"),
      fixed_premium_corp: Yup.string()
        .matches(
          pricePattern,
          "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
        )
        .required("Pflichtfeld"),
    },
    [
      ["premium_one", "deductible_one"],
      ["premium_one", "insurance_sum_one"],
      ["deductible_one", "insurance_sum_one"],
      ["premium_two", "deductible_two"],
      ["premium_two", "insurance_sum_two"],
      ["deductible_two", "insurance_sum_two"],
      ["premium_three", "deductible_three"],
      ["premium_three", "insurance_sum_three"],
      ["deductible_three", "insurance_sum_three"],
      ["premium_four", "deductible_four"],
      ["premium_four", "insurance_sum_four"],
      ["deductible_four", "insurance_sum_four"],
    ]
  );

  // Fills out the insurance sums with the values of the hull insurance.
  const fillOutFieldsWithHullData = (hull, value, setFieldValue) => {
    if (hull.hull_liability !== null) {
      value.insurance_sum_one = hull.hull_liability;
      setFieldValue("insurance_sum_one", hull.hull_liability);
    } else {
      value.insurance_sum_one = "";
      setFieldValue("insurance_sum_one", "");
    }
    if (hull.machine_franchise_fixed !== null) {
      value.insurance_sum_two = hull.machine_franchise_fixed;
      setFieldValue("insurance_sum_two", hull.machine_franchise_fixed);
    } else {
      value.insurance_sum_two = "";
      setFieldValue("insurance_sum_two", "");
    }

    if (hull.msb !== null) {
      value.insurance_sum_three = hull.msb;
      setFieldValue("insurance_sum_three", hull.msb);
    } else {
      value.insurance_sum_three = "";
      setFieldValue("insurance_sum_three", "");
    }

    if (hull.tpl !== null) {
      value.insurance_sum_four = hull.tpl;
      setFieldValue("insurance_sum_four", hull.tpl);
    } else {
      value.insurance_sum_four = "";
      setFieldValue("insurance_sum_four", "");
    }
  };

  return (
    <Formik
      validationSchema={HullFranchiseSchema}
      onSubmit={(values) => {
        console.log(values.hull);
        const hull = values.hull;
        const corporation = values.corporation;
        const ship = values.ship;
        props.onSubmit(values);
        values.corporation = corporation;
        values.ship = ship;
        values.hull = hull;
      }}
      initialValues={{
        policy_number: data !== undefined ? data.policy_number : "",
        insurance_sum_one:
          data !== undefined && data.insurance_sum_one !== null
            ? data.insurance_sum_one
            : "",
        insurance_sum_two:
          data !== undefined && data.insurance_sum_two !== null
            ? data.insurance_sum_two
            : "",
        insurance_sum_three:
          data !== undefined && data.insurance_sum_three !== null
            ? data.insurance_sum_three
            : "",
        insurance_sum_four:
          data !== undefined && data.insurance_sum_four !== null
            ? data.insurance_sum_four
            : "",
        premium_one:
          data !== undefined && data.premium_one !== null
            ? data.premium_one
            : "",
        premium_two:
          data !== undefined && data.premium_two !== null
            ? data.premium_two
            : "",
        premium_three:
          data !== undefined && data.premium_three !== null
            ? data.premium_three
            : "",
        premium_four:
          data !== undefined && data.premium_four !== null
            ? data.premium_four
            : "",
        deductible_one:
          data !== undefined && data.deductible_one !== null
            ? data.deductible_one
            : "",
        deductible_two:
          data !== undefined && data.deductible_two !== null
            ? data.deductible_two
            : "",
        deductible_three:
          data !== undefined && data.deductible_three !== null
            ? data.deductible_three
            : "",
        deductible_four:
          data !== undefined && data.deductible_four !== null
            ? data.deductible_four
            : "",
        payment_method: data !== undefined ? data.payment_method : "",
        tax: data !== undefined && data.tax !== null ? data.tax : "",
        insurance_start: data !== undefined ? data.insurance_start : "",
        brokerage: data !== undefined ? data.brokerage : "",
        corporation:
          data !== undefined
            ? { value: data.corporation, label: data.corporation.name }
            : "",
        ship:
          data !== undefined
            ? { value: data.ship, label: data.ship.shipname }
            : "",
        hull:
          data !== undefined && data.hull !== null
            ? { value: data.hull, label: data.hull.policy_number }
            : "",
        termination:
          data !== undefined && data.termination !== null
            ? data.termination
            : "",
        insurance_period_from:
          data !== undefined ? data.insurance_period_from : "",
        insurance_period_to: data !== undefined ? data.insurance_period_to : "",
        fixed_premium: data !== undefined ? data.fixed_premium : "",
        fixed_premium_corp: data !== undefined ? data.fixed_premium_corp : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridHull">
              <Form.Label>Kasko</Form.Label>
              <Select
                isClearable
                isSearchable
                id="hull"
                name="hull"
                placeholder="Wähle Kasko..."
                onChange={(selected) => {
                  if (selected !== null) {
                    HullDataService.get(selected.value.policy_number)
                      .then((response) => {
                        console.log(response.data);
                        const hull = response.data;
                        values.ship = response.data.ship;
                        setFieldValue("ship", {
                          value: response.data.ship,
                          label: response.data.ship.shipname,
                        });
                        fillOutFieldsWithHullData(
                          hull,
                          selected.value,
                          setFieldValue
                        );
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  values.hull = selected;
                  setFieldValue("hull", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() => setFieldTouched("hull", true, false)}
                options={hull}
                value={values.hull}
                styles={chooseCustomSelectStyle(
                  errors.hull,
                  values.hull,
                  touched.hull
                )}
              />
              {errors.hull && touched.hull ? (
                <div className={styles["invalid-error"]}>{errors.hull}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridFixedPremium">
              <Form.Label>Festprämie</Form.Label>
              <Form.Control
                name="fixed_premium"
                type="text"
                placeholder="Festprämie"
                value={values.fixed_premium}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("fixed_premium", true, false)}
                isValid={touched.fixed_premium && !errors.fixed_premium}
                isInvalid={!!errors.fixed_premium && touched.fixed_premium}
              />
              {errors.fixed_premium && touched.fixed_premium ? (
                <div className={styles["invalid-error"]}>
                  {errors.fixed_premium}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridFixedPremiumCorp">
              <Form.Label>Festprämie Gesell.</Form.Label>
              <Form.Control
                name="fixed_premium_corp"
                type="text"
                placeholder="Festprämie Gesell."
                value={values.fixed_premium_corp}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("fixed_premium_corp", true, false)
                }
                isValid={
                  touched.fixed_premium_corp && !errors.fixed_premium_corp
                }
                isInvalid={
                  !!errors.fixed_premium_corp && touched.fixed_premium_corp
                }
              />
              {errors.fixed_premium_corp && touched.fixed_premium_corp ? (
                <div className={styles["invalid-error"]}>
                  {errors.fixed_premium_corp}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceStart">
              <Form.Label>Versicherungsbeginn</Form.Label>
              <Form.Control
                name="insurance_start"
                type="date"
                placeholder="Versicherungsbeginn"
                value={values.insurance_start}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("insurance_start", true, false)}
                isValid={touched.insurance_start && !errors.insurance_start}
                isInvalid={!!errors.insurance_start && touched.insurance_start}
                disabled={props.isEditing}
              />
              {errors.insurance_start && touched.insurance_start ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_start}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPaymentMethod">
              <Form.Label>Zahlungsmethode</Form.Label>
              <Form.Select
                name="payment_method"
                value={values.payment_method}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.payment_method && !errors.payment_method}
                isInvalid={!!errors.payment_method && touched.payment_method}
              >
                <option hidden>Methode wählen...</option>
                <option></option>
                <option>1</option>
                <option>1/2</option>
                <option>1/3</option>
                <option>1/4</option>
                <option>1/6</option>
                <option>1/12</option>
              </Form.Select>
              {errors.payment_method && touched.payment_method ? (
                <div className={styles["invalid-error"]}>
                  {errors.payment_method}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsurancePeriodFrom">
              <Form.Label>Versicherungszeitraum von</Form.Label>
              <Form.Control
                name="insurance_period_from"
                type="date"
                placeholder="Versicherungszeitraum von"
                value={values.insurance_period_from}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("insurance_period_from", true, false)
                }
                isValid={
                  touched.insurance_period_from && !errors.insurance_period_from
                }
                isInvalid={
                  !!errors.insurance_period_from &&
                  touched.insurance_period_from
                }
              />
              {errors.insurance_period_from && touched.insurance_period_from ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_period_from}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridInsurancePeriodTo">
              <Form.Label>Versicherungszeitraum bis</Form.Label>
              <Form.Control
                name="insurance_period_to"
                type="date"
                placeholder="Versicherungszeitraum bis"
                value={values.insurance_period_to}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("insurance_period_to", true, false)
                }
                isValid={
                  touched.insurance_period_to && !errors.insurance_period_to
                }
                isInvalid={
                  !!errors.insurance_period_to && touched.insurance_period_to
                }
              />
              {errors.insurance_period_to && touched.insurance_period_to ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_period_to}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <h6>Kasko-Haftpflicht</h6>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceSumOne">
              <Form.Label>Versicherungssumme</Form.Label>
              <Form.Control
                name="insurance_sum_one"
                type="text"
                placeholder="Versicherungssume"
                value={values.insurance_sum_one}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("insurance_sum_one", true, false)
                }
                isValid={touched.insurance_sum_one && !errors.insurance_sum_one}
                isInvalid={
                  !!errors.insurance_sum_one && touched.insurance_sum_one
                }
              />
              {errors.insurance_sum_one && touched.insurance_sum_one ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_sum_one}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPremiumOne">
              <Form.Label>Prämie</Form.Label>
              <Form.Control
                name="premium_one"
                type="text"
                placeholder="Prämie"
                value={values.premium_one}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("premium_one", true, false)}
                isValid={touched.premium_one && !errors.premium_one}
                isInvalid={!!errors.premium_one && touched.premium_one}
              />
              {errors.premium_one && touched.premium_one ? (
                <div className={styles["invalid-error"]}>
                  {errors.premium_one}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDeductibleOne">
              <Form.Label>Selbstbeteiligung</Form.Label>
              <Form.Control
                name="deductible_one"
                type="text"
                placeholder="Selbstbeteiligung"
                value={values.deductible_one}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("deductible_one", true, false)}
                isValid={touched.deductible_one && !errors.deductible_one}
                isInvalid={!!errors.deductible_one && touched.deductible_one}
              />
              {errors.deductible_one && touched.deductible_one ? (
                <div className={styles["invalid-error"]}>
                  {errors.deductible_one}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <h6>Maschinen-Franchise-Fest</h6>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceSumTwo">
              <Form.Label>Versicherungssumme</Form.Label>
              <Form.Control
                name="insurance_sum_two"
                type="text"
                placeholder="Versicherungssume"
                value={values.insurance_sum_two}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("insurance_sum_two", true, false)
                }
                isValid={touched.insurance_sum_two && !errors.insurance_sum_two}
                isInvalid={
                  !!errors.insurance_sum_two && touched.insurance_sum_two
                }
              />
              {errors.insurance_sum_two && touched.insurance_sum_two ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_sum_two}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPremiumTwo">
              <Form.Label>Prämie</Form.Label>
              <Form.Control
                name="premium_two"
                type="text"
                placeholder="Prämie"
                value={values.premium_two}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("premium_two", true, false)}
                isValid={touched.premium_two && !errors.premium_two}
                isInvalid={!!errors.premium_two && touched.premium_two}
              />
              {errors.premium_two && touched.premium_two ? (
                <div className={styles["invalid-error"]}>
                  {errors.premium_two}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDeductibleTwo">
              <Form.Label>Selbstbeteiligung</Form.Label>
              <Form.Control
                name="deductible_two"
                type="text"
                placeholder="Selbstbeteiligung"
                value={values.deductible_two}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("deductible_two", true, false)}
                isValid={touched.deductible_two && !errors.deductible_two}
                isInvalid={!!errors.deductible_two && touched.deductible_two}
              />
              {errors.deductible_two && touched.deductible_two ? (
                <div className={styles["invalid-error"]}>
                  {errors.deductible_two}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <h6>MSB</h6>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceSumThree">
              <Form.Label>Versicherungssumme</Form.Label>
              <Form.Control
                name="insurance_sum_three"
                type="text"
                placeholder="Versicherungssume"
                value={values.insurance_sum_three}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("insurance_sum_three", true, false)
                }
                isValid={
                  touched.insurance_sum_three && !errors.insurance_sum_three
                }
                isInvalid={
                  !!errors.insurance_sum_three && touched.insurance_sum_three
                }
              />
              {errors.insurance_sum_three && touched.insurance_sum_three ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_sum_three}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPremiumThree">
              <Form.Label>Prämie</Form.Label>
              <Form.Control
                name="premium_three"
                type="text"
                placeholder="Prämie"
                value={values.premium_three}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("premium_three", true, false)}
                isValid={touched.premium_three && !errors.premium_three}
                isInvalid={!!errors.premium_three && touched.premium_three}
              />
              {errors.premium_three && touched.premium_three ? (
                <div className={styles["invalid-error"]}>
                  {errors.premium_three}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDeductibleThree">
              <Form.Label>Selbstbeteiligung</Form.Label>
              <Form.Control
                name="deductible_three"
                type="text"
                placeholder="Selbstbeteiligung"
                value={values.deductible_three}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("deductible_three", true, false)}
                isValid={touched.deductible_three && !errors.deductible_three}
                isInvalid={
                  !!errors.deductible_three && touched.deductible_three
                }
              />
              {errors.deductible_three && touched.deductible_three ? (
                <div className={styles["invalid-error"]}>
                  {errors.deductible_three}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <h6>TPL</h6>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceSumFour">
              <Form.Label>Versicherungssumme</Form.Label>
              <Form.Control
                name="insurance_sum_four"
                type="text"
                placeholder="Versicherungssume"
                value={values.insurance_sum_four}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("insurance_sum_four", true, false)
                }
                isValid={
                  touched.insurance_sum_four && !errors.insurance_sum_four
                }
                isInvalid={
                  !!errors.insurance_sum_four && touched.insurance_sum_four
                }
              />
              {errors.insurance_sum_four && touched.insurance_sum_four ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_sum_four}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPremiumFour">
              <Form.Label>Prämie</Form.Label>
              <Form.Control
                name="premium_four"
                type="text"
                placeholder="Prämie"
                value={values.premium_four}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("premium_four", true, false)}
                isValid={touched.premium_four && !errors.premium_four}
                isInvalid={!!errors.premium_four && touched.premium_four}
              />
              {errors.premium_four && touched.premium_four ? (
                <div className={styles["invalid-error"]}>
                  {errors.premium_four}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDeductibleFour">
              <Form.Label>Selbstbeteiligung</Form.Label>
              <Form.Control
                name="deductible_four"
                type="text"
                placeholder="Selbstbeteiligung"
                value={values.deductible_four}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("deductible_four", true, false)}
                isValid={touched.deductible_four && !errors.deductible_four}
                isInvalid={!!errors.deductible_four && touched.deductible_four}
              />
              {errors.deductible_four && touched.deductible_four ? (
                <div className={styles["invalid-error"]}>
                  {errors.deductible_four}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridTax">
              <Form.Label>Steuer</Form.Label>
              <Form.Control
                name="tax"
                type="text"
                placeholder="Steuer"
                value={values.tax}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("tax", true, false)}
                isValid={touched.tax && !errors.tax}
                isInvalid={!!errors.tax && touched.tax}
              />
              {errors.tax && touched.tax ? (
                <div className={styles["invalid-error"]}>{errors.tax}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridBrokerage">
              <Form.Label>Courtage</Form.Label>
              <Form.Control
                name="brokerage"
                type="text"
                placeholder="Courtage"
                value={values.brokerage}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("brokerage", true, false)}
                isValid={touched.brokerage && !errors.brokerage}
                isInvalid={!!errors.brokerage && touched.brokerage}
              />
              {errors.brokerage && touched.brokerage ? (
                <div className={styles["invalid-error"]}>
                  {errors.brokerage}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCorporation">
              <Form.Label>Gesellschaft</Form.Label>
              <Select
                isClearable
                isSearchable
                id="corporation"
                name="corporation"
                placeholder="Wähle Gesellschaft..."
                onChange={(selected) => {
                  values.corporation = selected;
                  setFieldValue("corporation", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() => setFieldTouched("corporation", true, false)}
                options={corporation}
                value={values.corporation}
                styles={chooseCustomSelectStyle(
                  errors.corporation,
                  values.corporation,
                  touched.corporation
                )}
              />
              {errors.corporation && touched.corporation ? (
                <div className={styles["invalid-error"]}>
                  {errors.corporation}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridShip">
              <Form.Label>Schiff</Form.Label>
              <Select
                isClearable
                isSearchable
                id="ship"
                name="ship"
                onChange={(selected) => {
                  values.ship = selected;
                  setFieldValue("ship", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() => setFieldTouched("ship", true, false)}
                placeholder="Wähle ein Schiff..."
                options={ship}
                value={values.ship}
                styles={chooseCustomSelectStyle(
                  errors.ship,
                  values.ship,
                  touched.ship
                )}
              />
              {errors.ship && touched.ship ? (
                <div className={styles["invalid-error"]}>{errors.ship}</div>
              ) : null}
            </Form.Group>
            {props.isEditing && (
              <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Kündigung zum</Form.Label>
                <Form.Control
                  name="termination"
                  type="date"
                  placeholder="Kündigung zum"
                  value={values.termination}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("termination", true, false)}
                  isValid={touched.termination && !errors.termination}
                  isInvalid={!!errors.termination && touched.termination}
                />
                {errors.termination && touched.termination ? (
                  <div className={styles["invalid-error"]}>
                    {errors.termination}
                  </div>
                ) : null}
              </Form.Group>
            )}
          </Row>
          <div className={styles.submitBtn}>
            <SubmitButton variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default HullFranchiseForm;
