import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class BillingDataService {
  getAllTaxBillings(filter) {
    return axios.post(API_URL + "/tax-billings", filter, {
      headers: authHeader(),
    });
  }

  getAllCustomerBillings(filter) {
    return axios.post(API_URL + "/customer-billings", filter, {
      headers: authHeader(),
    });
  }

  getAllCorporationBillings(filter) {
    return axios.post(API_URL + "/corporation-billings", filter, {
      headers: authHeader(),
    });
  }

  downloadTaxBilling(filename) {
    return axios.get(API_URL + `/tax-billing/download/${filename}`, {
      headers: authHeader(),
      responseType: "blob",
    });
  }

  downloadCustomerBilling(filename) {
    return axios.get(API_URL + `/customer-billing/download/${filename}`, {
      headers: authHeader(),
      responseType: "blob",
    });
  }

  downloadCorporationBilling(filename) {
    return axios.get(API_URL + `/corporation-billing/download/${filename}`, {
      headers: authHeader(),
      responseType: "blob",
    });
  }
}

export default new BillingDataService();
