import React, { useState, useEffect } from "react";
import PAndIFranchiseDataService from "../../../../services/p-and-i-franchise.data.service";
import PAndIFranchiseForm from "../../../PAndI/PAndIFranchiseForm";
import { Modal, Button } from "react-bootstrap";
import styles from "./CustomPAndIFranchiseModal.module.scss";
import Table from "../../../Table/Table";
import CustomDeleteModal from "../CustomDeleteModal";
import useAlert from "../../../../hooks/use-alert";
import GenericAlert from "../../../Alert/GenericAlert";
import { checkIfItemIsStringAndReplaceComma } from "../../../../helper/helper";

const CustomPAndIFranchiseModal = (props) => {
  console.log("CustomPAndIFranchiseModal")
  const { show, alertContent, handleAlertVisible } = useAlert();
  // Variable to display the delete modal
  const [showDel, setShowDel] = useState(false);

  // Variable with the current item which is selected
  const [item, setItem] = useState("");

  // Hold the franchises for a specific P&I.
  const [franchises, setFranchises] = useState([]);

  // State which indicates if the user is editing or not
  const [isEditing, setIsEditing] = useState(false);

  // Header to be displayed in the table component
  const header = ["Franchise", "Höhe", "Bearbeiten"];

  // Headers for the table without the edit field.
  const headers = ["Franchise", "Höhe"];
  const signArray = ["", "€"];

  // Header and Bodys for the modals
  const modalTitleAddFran = "Franchisen"; // Variable to display the edit modal
  const modalTitleDel = "Franchise löschen";
  const modalBodyDel = "Möchten Sie die Franchise wirklich löschen?";

  const [pAndIFranchise, setPAndIFranchise] = useState({
    pAndI: props.pAndI,
    franchise: { id: "", name: "" },
    amount: "",
  });

  console.log(pAndIFranchise)

  const getAllPAndIFranchises = (filter) => {
    PAndIFranchiseDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          var franch = [];

          response.data.forEach((element) => {
            franch.push({
              franchise: element.franchise,
              amount: element.amount.toString().replace(".", ","),
            });
          });
          setFranchises(franch);
        } else {
          setFranchises([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const {policy_number} = props.pAndI;

  useEffect(() => {
    getAllPAndIFranchises({ filter: policy_number });
  }, [policy_number]);

  // Function to adjust the decimal values and replace the comma with a dot.
  const formatDecimals = (item) => {
    item.amount = checkIfItemIsStringAndReplaceComma(item.amount);
    item.pAndI.fixed_premium = checkIfItemIsStringAndReplaceComma(item.pAndI.fixed_premium);
    item.pAndI.fixed_premium_corp = checkIfItemIsStringAndReplaceComma(item.pAndI.fixed_premium_corp);
    item.pAndI.tax = checkIfItemIsStringAndReplaceComma(item.pAndI.tax);
    item.pAndI.brokerage = checkIfItemIsStringAndReplaceComma(item.pAndI.brokerage);
    item.pAndI.fifty_one_sum = checkIfItemIsStringAndReplaceComma(item.pAndI.fifty_one_sum);
  };

  const selectValuesFromObjects = (item) => {
    item.franchise = item.franchise.value;
  };

  // Method to add a new p-and-i-franchise to the database
  const addPAndIFranchise = (item) => {
    const data = {...item}
    selectValuesFromObjects(data);
    console.log(data)
    // Format decimals
    formatDecimals(data);
    // Calls the create function of the backend to add a new p-and-i-franchise
    PAndIFranchiseDataService.create(data)
      .then((response) => {
        console.log(data)
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die Franchise mit der Id "' +
          data.franchise.id +
            '" wurde erfolgreich hinzugefügt!',
          "success"
        );
        // Call getAllPAndIFranchises to refresh the table.
        getAllPAndIFranchises({ filter: policy_number });
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Franchise mit der Id "' +
            data.franchise.id +
            '" konnte nicht hinzugefügt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to update the p-and-i-franchise entry.
  const updatePAndFranchise = (item) => {
    const data = {...item}
    selectValuesFromObjects(data);
    console.log(data)
    // Format decimals
    formatDecimals(data);
    // Calls the update function of the backend to edit an entry
    PAndIFranchiseDataService.update(
      props.pAndI.policy_number,
      data.franchise.id,
      data
    )
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die Franchise mit der Id "' +
          data.franchise.id +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        // Call getAllPAndIFranchises to refresh the table
        getAllPAndIFranchises({ filter: policy_number });
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Franchise mit der Id "' +
          data.franchise.id +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  const resetPAndIFranchiseFields = () => {
    setPAndIFranchise(prevPAndIFranchise => {
      return {
        ...prevPAndIFranchise,
        franchise: { id: "", name: "" },
        amount: "",
      };
    });
  }

  // Function to handle the onSubmit for add and edit a p-and-i-franchise
  const handleAddAndEdit = (e) => {
    //Checks wether an item is added or is being edited.
    if (isEditing) {
      console.log(e)
      updatePAndFranchise(e);
      // Sets the PAndIFranchise object to default to clear the input fields.
      resetPAndIFranchiseFields();
      setIsEditing(false);
    } else {
      addPAndIFranchise(e);
      // Sets the PAndIFranchise object to defult to clear the input field.
      //resetPAndIFranchiseFields();
    }
  };

  // Function to fikk the input fields with the data that was already inserted.
  const handleEdit = (data) => {
    setPAndIFranchise((prevPAndIFranchise) => {
      return {
        ...prevPAndIFranchise,
        franchise: data.franchise,
        amount: data.amount,
      };
    });
    // Sets is editing to true that handleAddAndEdit() choose the right onSubmit.
    setIsEditing(true);
  };

  // Function to deleta an specific p-and-i-franchise object in the backend.
  const deletePAndIFranchise = () => {
    PAndIFranchiseDataService.delete(item.p_and_i_pnr, item.franchise_id)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die Franchise mit der Id "' +
            item.franchise_id +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        // Call getAllPAndIFranchises to refresh the table
        getAllPAndIFranchises({ filter: policy_number });
      })
      .catch((error) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die Franchise mit der Id "' +
            item.franchise_id +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to handle the delete of an specific entry
  const handleDelete = () => {
    // Call the methods which holds the backend deletion call.
    deletePAndIFranchise();
    // Close the delete modal
    setShowDel(false);
  };

  // The functions to open and close the delete modal
  const handleCloseDel = () => setShowDel(false);
  const handleShowDel = (datum) => {
    console.log(datum);
    // If the delete modal opens create an object with the franchise id
    // and the pAndI policynumber to delete the right item.
    const item = {
      p_and_i_pnr: props.pAndI.policy_number,
      franchise_id: datum.franchise.id,
    };
    // Set the item.
    setItem(item);
    // Opens the delete modal.
    setShowDel(true);
  };

  return (
    <>
      <Modal centered show={props.show} onHide={props.onHide}>
        <Modal.Header className={styles.modalHeader} closeVariant="white">
          <Modal.Title>{modalTitleAddFran}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4>Franchisen für P & I {policy_number}</h4>
          <PAndIFranchiseForm
            btnText={isEditing ? "Bearbeiten" : "Hinzufügen"}
            onSubmit={handleAddAndEdit}
            editData={pAndIFranchise}
            isEditing={isEditing}
          />
          <div className={styles.tableContainer}>
            <Table
              headers={headers}
              header={header}
              items={franchises}
              attr={{ 0: "name" }}
              tableStyle="tableContainerModal"
              onEdit={handleEdit}
              onDelete={handleShowDel}
              delete
              signArray={signArray}
              edit
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props.isEditing && (
            <Button variant="secondary" onClick={props.onBack}>
              Zurück
            </Button>
          )}
          <Button variant="secondary" onClick={props.onClose}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>

      <CustomDeleteModal
        modalTitle={modalTitleDel}
        modalBody={modalBodyDel}
        onClose={handleCloseDel}
        onDelete={handleDelete}
        onHide={handleCloseDel}
        show={showDel}
      />
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </>
  );
};

export default CustomPAndIFranchiseModal;
