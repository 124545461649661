import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class PolicyholderDataService {
  // Get all Policyholders from the database
  getAll(filter) {
    return axios.post(API_URL + "/policyholders", filter, {
      headers: authHeader(),
    });
  }

  // Create a new policyholder
  create(data) {
    return axios.post(API_URL + "/policyholder", data, {
      headers: authHeader(),
    });
  }

  // Get just one Policyholder
  get(id) {
    return axios.get(API_URL + `/policyholder/${id}`, {
      headers: authHeader(),
    });
  }

  // Delete a specific policyholder out of the table.
  delete(id) {
    return axios.delete(API_URL + `/policyholder/${id}`, {
      headers: authHeader(),
    });
  }

  // Update the data of one specific policyholder.
  update(id, data) {
    return axios.put(API_URL + `/policyholder/${id}`, data, {
      headers: authHeader(),
    });
  }

  // Checks if the choosen id exists
  idExists(id) {
    return axios.get(API_URL + `/policyholder/id-exists/${id}`, { headers: authHeader() });
  }
}

export default new PolicyholderDataService();
