import React from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./CustomPageOneModal.module.scss";

function CustomPageOneModal(props) {
  // Create a copy of the form component and add a onSubmit property.
  const form = React.cloneElement(props.form, { onSubmit: props.onSubmit, isEditing: props.isEditing });

  return (
    <Modal centered show={props.show} onHide={props.onClose} >
      <Modal.Header
        className={styles.modalHeader}
        closeVariant="white"
        closeButton={props.closable || props.preventNext}
      >
        <Modal.Title>{props.modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{form}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onNext} disabled={props.preventNext}>Weiter</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomPageOneModal;
