import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class CreditDataService {
  getAll(filter) {
    return axios.post(API_URL + "/credits", filter, {
      headers: authHeader(),
    });
  }

  get(id) {
    return axios.get(API_URL + `/credit/${id}`, { headers: authHeader() });
  }

  create(data) {
    return axios.post(API_URL + "/credit", data, {
      headers: authHeader(),
    });
  }

  delete(id) {
    return axios.delete(API_URL + `/credit/${id}`, {
      headers: authHeader(),
    });
  }

  update(id, data) {
    return axios.put(API_URL + `/credit/${id}`, data, {
      headers: authHeader(),
    });
  }

  createHullCredit(data) {
    return axios.post(API_URL + "/hull-credit-invoice", data, {
      headers: authHeader(),
    });
  }

  createHullFranchiseCredit(data) {
    return axios.post(API_URL + "/hull-franchise-credit-invoice", data, {
      headers: authHeader(),
    });
  }

  createInterestCredit(data) {
    return axios.post(API_URL + "/interest-credit-invoice", data, {
      headers: authHeader(),
    });
  }

  createLohCredit(data) {
    return axios.post(API_URL + "/loh-credit-invoice", data, {
      headers: authHeader(),
    });
  }

  createLohFranchiseCredit(data) {
    return axios.post(API_URL + "/loh-franchise-credit-invoice", data, {
      headers: authHeader(),
    });
  }

  createElectronicsInsuranceCredit(data) {
    return axios.post(API_URL + "/electronics-insurance-credit-invoice", data, {
      headers: authHeader(),
    });
  }

  createPAndICredit(data) {
    return axios.post(API_URL + "/p-and-i-credit-invoice", data, {
      headers: authHeader(),
    });
  }
}

export default new CreditDataService();