import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class HullCorporationDataService {
  // Get all hull corporations from the database
  getAll(filter) {
    return axios.post(API_URL + "/hull-corporations", filter, {
      headers: authHeader(),
    });
  }

  // Create a new hull corporation
  create(data) {
    return axios.post(API_URL + "/hull-corporation", data, {
      headers: authHeader(),
    });
  }

  // Delete a specific hull corporation out of the table.
  // Accepts the policynumber of the hull and the corporation id.
  delete(pnr, id) {
    return axios.delete(API_URL + `/hull-corporation/${pnr}/${id}`, {
      headers: authHeader(),
    });
  }

  // Update the data of one specific hull corporation.
  // Accepts the policynmuber of the hull, the id of the corporation 
  // and the whole hull corporation object with the changes
  update(pnr, id, data) {
    return axios.put(API_URL + `/hull-corporation/${pnr}/${id}`, data, {
      headers: authHeader(),
    });
  }
}

export default new HullCorporationDataService();
