import React from "react"
import {
    Modal,
    Button
  } from "react-bootstrap";
import styles from "./CustomModal.module.scss"

const CustomModal = (props) => {
    return ( 
        <Modal centered show={props.show} onHide={props.onHide}>
          <Modal.Header className={styles.modalHeader} closeButton closeVariant="white">
            <Modal.Title>{props.modalTitle}</Modal.Title>
          </Modal.Header>
    
          <Modal.Body>
            {props.modalBody}
          </Modal.Body>
    
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onLeftBtn}>
              {props.leftButton}
            </Button>
            <Button variant="primary" className={styles.btn} onClick={props.onRightBtn}>
              {props.rightButton}
            </Button>
          </Modal.Footer>
        </Modal>
      );
}

export default CustomModal;