import React, { useState } from "react";
import CustomDeleteModal from "../UI/Modals/CustomDeleteModal";
import Table from "../Table/Table";
import ItemFilter from "../ItemFilter/ItemFilter";
import PoolMultiModal from "../UI/Modals/Pool/PoolMultiModal";
//import styles from "./CrudContentPool.module.scss";
import CustomMoreInfoModal from "../UI/Modals/CustomMoreInfoModal";
import CustomModal from "../UI/Modals/CustomModal";

const CrudContentPool = (props) => {
  // Variable to display the delete modal
  const [show, setShow] = useState(false);
  // Variable to display the edit modal
  const [showEdit, setShowEdit] = useState(false);
  // Variable to display the add modal
  const [showAdd, setShowAdd] = useState(false);
  // Variable to display the delete modal
  const [showDisplay, setShowDisplay] = useState(false);
  // Variable to display the extend modal
  const [showExtend, setShowExtend] = useState(false);

  // Variable with the current item which is selected
  const [item, setItem] = useState("");

  const [selectedItem, setSelectedItem] = useState("");

  // Create a copy of the headers object and delete the last index
  const headers = Object.assign([], props.header).splice(
    0,
    props.header.length - props.skip
  );
  // Accept the header array
  const header = props.header;

  // Poolname
  const [pool, setPool] = useState({});
  const [preventNext, setPreventNext] = useState(true);

  // Function to handle the delete of an specific entry
  const handleDelete = () => {
    console.log(item);
    props.onDelete(item);
    setShow(false);
  };

  // The functions to open and close the modal
  const handleClose = () => setShow(false);
  const handleShow = (datum) => {
    setItem(datum);
    setShow(true);
  };

  const handleExtend = () => {
    console.log(selectedItem);
    props.onExtend(selectedItem)
    setShowExtend(false);
  }

  const handleCloseExtend = () => setShowExtend(false);
  const handleShowExtend = (datum) => {
    setSelectedItem(datum);
    setShowExtend(true);
  }

  // Function to handle the edit of the items
  const handleEdit = (e) => {
    props.onEditItem(e);
  };

  // Functions to close and open the modal
  const handleCloseEdit = () => {
    setPool(undefined);
    setShowEdit(false);
  };

  const handleShowEdit = (data) => {
    props.onEditItemReceive(data);
    // Create a copy of the data item and replace all comma with dot
    const item = { ...data };
    setPool(item);
    setShowEdit(true);
  };

  // Function to handle adding of new items to the table
  const handleAdd = (e) => {
    console.log(e);
    if (e !== undefined) {
      setPool(e);
      setPreventNext(false);
    }
    props.onAddItem(e);
  };

  // Function to close and open the modal
  const handleCloseAdd = () => {
    setPreventNext(true);
    setPool(undefined);
    setShowAdd(false);
  };
  const handleShowAdd = () => {
    setShowAdd(true);
  };

  // Function to close and open the modal
  const handleCloseDisplay = () => setShowDisplay(false);
  const handleShowDisplay = (data) => {
    props.onDisplayItemReceive(data);
    setShowDisplay(true);
  };

  // Methode to get the filter value out of the input field
  const handleFilter = (e) => {
    var filter = { filter: e.target.value };
    props.onFilter(filter);
  };

  const poolObject = props.pool && Object.keys(props.pool).length === 0 ? pool : props.pool;


  return (
    <>
      <div>
        <h2>{props.heading}</h2>
        <div className="col">
          <ItemFilter
            placeholder={props.placeholder}
            onChange={handleFilter}
            onAdd={handleShowAdd}
            add={props.add}
          />
          <Table
            headers={headers}
            header={header}
            items={props.items}
            onEdit={handleShowEdit}
            onDelete={handleShow}
            delete={props.delete}
            onDisplay={handleShowDisplay}
            onExtend={handleShowExtend}
            attr={props.attr}
            moreInfo={props.moreInfo}
            signArray={props.signArray}
            edit={props.edit}
            extended
          />
        </div>
      </div>

      <CustomDeleteModal
        modalTitle={props.modalTitle}
        modalBody={props.modalBody}
        onClose={handleClose}
        onDelete={handleDelete}
        onHide={handleClose}
        show={show}
      />

      <CustomModal
        modalTitle="Pool verlängern?"
        modalBody={<p>Möchten Sie diesen Pool verlängern?</p>}
        onLeftBtn={handleCloseExtend}
        onRightBtn={handleExtend}
        onHide={handleCloseExtend}
        show={showExtend}
        leftButton="Abbrechen"
        rightButton="Verlängern"
      />

      <PoolMultiModal
        show={showAdd}
        onSubmit={handleAdd}
        form={props.formAdd}
        modalTitle={props.modalTitleAdd}
        showShip={showAdd}
        onSubmitShip={handleAdd}
        onHide={handleCloseAdd}
        pool={props.pool}
        preventNext={preventNext}
        isEditing={false}
      />

      <PoolMultiModal
        show={showEdit}
        onSubmit={handleEdit}
        form={props.form}
        modalTitle={props.modalTitleEdit}
        showShip={showEdit}
        onSubmitShip={handleEdit}
        onHide={handleCloseEdit}
        pool={poolObject}
        isEditing={true}
      />

      <CustomMoreInfoModal
        show={showDisplay}
        items={props.displayItems}
        onHide={handleCloseDisplay}
        onClose={handleCloseDisplay}
        modalTitle={props.modalTitleAdd}
        hasTable={true}
        attr={{ 0: "shipname" }}
        header={props.modalHeader}
        headers={props.modalHeaders}
      />
    </>
  );
};

export default CrudContentPool;
