import React, { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import styles from "./InsuredObjectForm.module.scss";
import PolicyholderDataService from "../../services/policyholder.data.service";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";

const InsuredObjectForm = (props) => {
  // Variable with the values for the input fields
  const data = props.editData;
  // Save the current year in a varibale.
  const currentYear = new Date().getFullYear();

  // State to save all policyholders for the select field.
  const [policyholder, setPolicyholder] = useState([]);

  // Function to get all policyholder from the backend.
  const getAllPolicyholder = () => {
    PolicyholderDataService.getAll({ filter: "" })
      .then((response) => {
        let policyholders = [];
        response.data.forEach(element => {
          policyholders.push({value: element, label: element.name});
        });
        setPolicyholder(policyholders);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get all policyholders the first time this component is rendered.
  useEffect(() => {
    getAllPolicyholder();
  }, []);

  // Query wether the chosen id exist or not.
  const idExists = async (id) => {
    try {
      const resp = await InsuredObjectDataService.idExists(id);
      return !resp.data;
    } catch (err) {
      console.log(err);
    }
  };

  // A custom yup function to set the form invalid if a id exist.
  Yup.addMethod(Yup.string, "idExists", function (errorMessage) {
    return this.test(`test-id-exists`, errorMessage, function (value) {
      const { path, createError } = this;
      // Checks wether its in edit or add mode
      // isEditing is true if the user is in edit mode.
      // In this moment the user can't change the insured object id.
      if (props.isEditing) {
        // Return true so the form is valid
        return true;
      } else {
        // If in add mode check if the id is valid or not.
        return idExists(value) || createError({ path, message: errorMessage });
      }
    });
  });

  // The object schema to validate the input fields.
  const InsuredObjectSchema = Yup.object().shape({
    id: Yup.string()
      .required("Pflichtfeld")
      .max(3, "Die Id muss aus maximal 3 Ziffern bestehen")
      .min(3, "Die Id muss minimal aus 3 Ziffern bestehen")
      .idExists("Die Id existiert bereits"),
    policyholder: Yup.object().required("Pflichtfeld").nullable(),
    shipname: Yup.string()
      .required("Pflichtfeld!")
      .max(100, "Der Schiffname darf maximal aus 100 Zeichen bestehen")
      .min(2, "Der Schiffname darf minimum aus 2 Zeichen bestehen"),
    imo_nr: Yup.string()
      .matches(/^\d{7}$/, "Das ist keine valide IMO-Nummer")
      .min(7, "Die IMO-Nummer darf aus maxmimal 7 Zeichen bestehen")
      .max(7, "Die IMO-Nummer darf aus minimum 7 Zeichen bestehen"),
    dim: Yup.string()
      .max(40, "Die Dimension darf aus maximal 40 Zeichen bestehen")
      .min(2, "Die Dimension darf aus minimum 2 Zeichen bestehen"),
    load_capacity: Yup.string()
      .max(40, "Die Tragfähigkeit darf aus maximal 40 Zeichen bestehen")
      .min(2, "Die Tragfähigkeit darf aus minimum 2 Zeichen bestehen"),
    motor: Yup.string()
      .max(70, "Der Motor darf maximal aus 70 Zeichen bestehen")
      .min(2, "Der Motor darf minimum aus 2 Zeichen bestehen"),
    home_port: Yup.string()
      .max(40, "Der Heimathafen darf maximal aus 40 Zeichen bestehen")
      .min(2, "Der Heimathafen darf minimum aus 2 Zeichen bestehen"),
    flag: Yup.string()
      .max(100, "Die Flagge darf aus maximal 100 Zeichen bestehen")
      .min(2, "Die Flagge darf aus minimum 2 Zeichen bestehen")
      .required("Pflichtfeld"),
    ship_class: Yup.string()
      .max(20, "Die Klasse darf aus maximal 20 Zeichen bestehen")
      .min(2, "Die Klasse darf aus minimum 2 Zeichen bestehen"),
    sailing_area: Yup.string().nullable().required("Pflichtfeld"),
    construction_year: Yup.number()
      .min(1800, "Das Baujahr muss größer als 1800")
      .max(currentYear, `Das Baujahr darf nicht größer als ${currentYear} sein`)
      .required("Pflichtfeld"),
    callsign: Yup.string()
      .max(5, "Das Rufzeichen muss aus maximal aus 5 Zeichen bestehen")
      .min(5, "Das Rufzeichen muss aus minimal aus 5 Zeichen bestehen"),
    more_information: Yup.string()
      .nullable()
      .max(255, "Das Feld darf maximal 255 Zeichen enthalten"),
  });

  return (
    <>
      <Formik
        validationSchema={InsuredObjectSchema}
        onSubmit={props.onSubmit}
        initialValues={{
          id: data !== undefined ? data.id : "",
          policyholder: data !== undefined ? {value: data.policyholder, label: data.policyholder.name} : "",
          shipname: data !== undefined ? data.shipname : "",
          imo_nr: data !== undefined ? data.imo_nr : "",
          dim: data !== undefined ? data.dim : "",
          load_capacity: data !== undefined ? data.load_capacity : "",
          motor: data !== undefined ? data.motor : "",
          home_port: data !== undefined ? data.home_port : "",
          flag: data !== undefined ? data.flag : "",
          ship_class: data !== undefined ? data.ship_class : "",
          sailing_area: data !== undefined ? data.sailing_area : "",
          construction_year: data !== undefined ? data.construction_year : "",
          callsign: data !== undefined ? data.callsign : "",
          more_information: data !== undefined ? data.more_information : "",
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, touched, errors, values, setFieldValue, setFieldTouched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridShipname">
                <Form.Label>Id</Form.Label>
                <Form.Control
                  name="id"
                  type="text"
                  placeholder="Id"
                  value={values.id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("id", true, false)}
                  isValid={touched.id && !errors.id}
                  isInvalid={!!errors.id && touched.id}
                  disabled={props.isEditing}
                />
                {errors.id && touched.id ? (
                  <div className={styles["invalid-error"]}>{errors.id}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Versicherungsnehmer</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="policyholder"
                  name="policyholder"
                  placeholder="Wähle Eigentümer..."
                  onChange={(selected) => {
                    values.policyholder = selected;
                    setFieldValue("policyholder", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() => setFieldTouched("policyholder", true, false)}
                  options={policyholder}
                  value={values.policyholder}
                  styles={chooseCustomSelectStyle(errors.policyholder, values.policyholder, touched.policyholder)}
                />
                {errors.policyholder && touched.policyholder ? (
                  <div className={styles["invalid-error"]}>
                    {errors.policyholder}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridShipname">
                <Form.Label>Schiffsname</Form.Label>
                <Form.Control
                  name="shipname"
                  type="text"
                  placeholder="Schiffsname"
                  value={values.shipname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("shipname", true, false)}
                  isValid={touched.shipname && !errors.shipname}
                  isInvalid={!!errors.shipname && touched.shipname}
                />
                {errors.shipname && touched.shipname ? (
                  <div className={styles["invalid-error"]}>
                    {errors.shipname}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDim">
                <Form.Label>Dim.</Form.Label>
                <Form.Control
                  name="dim"
                  type="text"
                  placeholder="Dim."
                  value={values.dim}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("dim", true, false)}
                  isValid={touched.dim && !errors.dim}
                  isInvalid={!!errors.dim && touched.dim}
                />
                {errors.dim && touched.dim ? (
                  <div className={styles["invalid-error"]}>{errors.dim}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridFlag">
                <Form.Label>Flagge</Form.Label>
                <Form.Control
                  name="flag"
                  type="text"
                  placeholder="Flagge"
                  value={values.flag}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("flag", true, false)}
                  isValid={touched.flag && !errors.flag}
                  isInvalid={!!errors.flag && touched.flag}
                />
                {errors.flag && touched.flag ? (
                  <div className={styles["invalid-error"]}>{errors.flag}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridLoadCapacity">
                <Form.Label>Tragfähigkeit</Form.Label>
                <Form.Control
                  name="load_capacity"
                  type="text"
                  placeholder="Tragfähigkeit"
                  value={values.load_capacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("load_capacity", true, false)}
                  isValid={touched.load_capacity && !errors.load_capacity}
                  isInvalid={!!errors.load_capacity && touched.load_capacity}
                />
                {errors.load_capacity && touched.load_capacity ? (
                  <div className={styles["invalid-error"]}>
                    {errors.load_capacity}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridImoNr">
                <Form.Label>Imo Nr.</Form.Label>
                <Form.Control
                  name="imo_nr"
                  type="text"
                  placeholder="Imo Nr."
                  value={values.imo_nr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("imo_nr", true, false)}
                  isValid={touched.imo_nr && !errors.imo_nr}
                  isInvalid={!!errors.imo_nr && touched.imo_nr}
                />
                {errors.imo_nr && touched.imo_nr ? (
                  <div className={styles["invalid-error"]}>{errors.imo_nr}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridHomePort">
                <Form.Label>Heimathafen</Form.Label>
                <Form.Control
                  name="home_port"
                  type="text"
                  placeholder="Heimathafen"
                  value={values.home_port}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("home_port", true, false)}
                  isValid={touched.home_port && !errors.home_port}
                  isInvalid={!!errors.home_port && touched.home_port}
                />
                {errors.home_port && touched.home_port ? (
                  <div className={styles["invalid-error"]}>
                    {errors.home_port}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridConstructionYear">
                <Form.Label>Baujahr</Form.Label>
                <Form.Control
                  name="construction_year"
                  type="number"
                  placeholder="Baujahr"
                  value={values.construction_year}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("construction_year", true, false)}
                  isValid={
                    touched.construction_year && !errors.construction_year
                  }
                  isInvalid={!!errors.construction_year && touched.construction_year}
                />
                {errors.construction_year && touched.construction_year ? (
                  <div className={styles["invalid-error"]}>
                    {errors.construction_year}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridShipClass">
                <Form.Label>Klasse</Form.Label>
                <Form.Control
                  name="ship_class"
                  type="text"
                  placeholder="Klasse"
                  value={values.ship_class}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("ship_class", true, false)}
                  isValid={touched.ship_class && !errors.ship_class}
                  isInvalid={!!errors.ship_class && touched.ship_class}
                />
                {errors.ship_class && touched.ship_class ? (
                  <div className={styles["invalid-error"]}>
                    {errors.ship_class}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridMotor">
                <Form.Label>Motor</Form.Label>
                <Form.Control
                  name="motor"
                  type="text"
                  placeholder="Motor"
                  value={values.motor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("motor", true, false)}
                  isValid={touched.motor && !errors.motor}
                  isInvalid={!!errors.motor && touched.motor}
                />
                {errors.motor && touched.motor ? (
                  <div className={styles["invalid-error"]}>{errors.motor}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridSailingArea">
                <Form.Label>Fahrgebiet</Form.Label>
                <Form.Select
                  name="sailing_area"
                  value={values.sailing_area}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.sailing_area && !errors.sailing_area}
                  isInvalid={!!errors.sailing_area && touched.sailing_area}
                >
                  <option hidden>
                    Fahrtgebiet wählen...
                  </option>
                  <option>7.1</option>
                  <option>7.2</option>
                  <option>Gemäß Fahrerlaubins</option>
                  <option>Küstnenahe Gewässer</option>
                  <option>Nord-Ostsee</option>
                  <option>Europäische Binnengewässer</option>
                  <option>Sonstige</option>
                </Form.Select>
                {errors.sailing_area && touched.sailing_area ? (
                  <div className={styles["invalid-error"]}>
                    {errors.sailing_area}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridMotor">
                <Form.Label>Rufzeichen</Form.Label>
                <Form.Control
                  name="callsign"
                  type="text"
                  placeholder="Rufzeichen"
                  value={values.callsign}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("callsign", true, false)}
                  isValid={touched.callsign && !errors.callsign}
                  isInvalid={!!errors.callsign && touched.callsign}
                />
                {errors.callsign && touched.callsign ? (
                  <div className={styles["invalid-error"]}>
                    {errors.callsign}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridSailingArea">
                <Form.Label>Weitere Informationen</Form.Label>
                <Form.Control
                  name="more_information"
                  value={values.more_information}
                  placeholder="Weitere Informationen"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("more_information", true, false)}
                  isValid={touched.more_information && !errors.more_information}
                  isInvalid={!!errors.more_information && touched.more_information}
                />
                {errors.more_information && touched.more_information ? (
                  <div className={styles["invalid-error"]}>
                    {errors.more_information}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <SubmitButton className="submitBtn" variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InsuredObjectForm;
