import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import styles from "./PolicyholderForm.module.scss";
import PolicyholderDataService from "../../services/policyholder.data.service";

const PolicyholderForm = (props) => {
  // The data of an entry
  const data = props.editData;

  // Checks wether the corporation id already exist.
  const idExists = async (id) => {
    try {
      const resp = await PolicyholderDataService.idExists(id);
      return !resp.data;
    } catch (err) {
      console.log(err);
    }
  };

  // Custom yup function to set the form invalid if the policyholder id already exists.
  Yup.addMethod(Yup.string, "idExists", function (errorMessage) {
    return this.test(`test-id-exists`, errorMessage, function (value) {
      const { path, createError } = this;
      // Checks wether its in edit or add mode
      // IsDisabled is true if the user is in edit mode.
      // In this moment the user can't change the policyholder id.
      if (props.isDisabled) {
        // Return true so the form is valid
        return true;
      } else {
        // If in add mode check if the id is valid or not.
        return idExists(value) || createError({ path, message: errorMessage });
      }
    });
  });

  // The object schema to validate the input fields.
  const PlolicyholderSchema = Yup.object().shape({
    id: Yup.string()
      .matches(/^\d*$/, "Darf nur aus Zahlen bestehen")
      .max(5, "Kundennummer darf maximal aus 5 Ziffern bestehen")
      .min(5, "Kundennummer muss aus mindestens 5 Ziffern bestehen.")
      .required("Pflichtfeld")
      .idExists("Die Id existiert bereits"),
    name: Yup.string()
      .min(2, "Versicherungsnehmer darf minimum aus 2 Zeichen bestehen")
      .max(100, "Versicherungsnehmer darf maximal aus 100 Zeichen bestehen")
      .required("Pflichtfeld"),
    postcode: Yup.string()
      .min(4, "PLZ darf minimum aus 4 Zeichen bestehen")
      .max(20, "PLZ darf maximal aus 20 Zeichen bestehen")
      .required("Pflichtfeld"),
    place: Yup.string()
      .min(2, "Ort darf minimum aus 2 Zeichen bestehen")
      .max(40, "Ort darf maximal aus 40 Zeichen bestehen")
      .required("Pflichtfeld"),
    address: Yup.string()
      .min(2, "Anschrift darf minimum aus 2 Zeichen bestehen")
      .max(70, "Anschrift darf maximal aus 70 Zeichen bestehen")
      .required("Pflichtfeld"),
    email: Yup.string()
      .email("Keine gültige E-Mail")
      .max(50, "E-Mail darf maximal aus 50 Zeichen bestehen"),
    mobilnumber: Yup.string()
      .max(30, "Mobilnummer darf maximal aus 30 Zeichen bestehen")
      .matches(/^[\d]+[/]?\s?[-]?[\d]+$/, "Das ist keine Mobilnummer"),
    telnumber: Yup.string()
      .max(30, "Telefonnummer darf maximal aus 30 Zeichen bestehen")
      .matches(/^[\d]+[/]?\s?[-]?[\d]+$/, "Das ist keine Telefonnummer"),
    fax: Yup.string()
      .max(30, "Faxnummer darf maximal aus 30 Zeichen bestehen")
      .matches(/^[\d]+[/]?\s?[-]?[\d]+$/, "Das ist keine Faxnummer"),
  });

  return (
    <>
      <Formik
        validationSchema={PlolicyholderSchema}
        onSubmit={props.onSubmit}
        initialValues={{
          id: data !== undefined ? data.id : "",
          name: data !== undefined ? data.name : "",
          postcode: data !== undefined ? data.postcode : "",
          place: data !== undefined ? data.place : "",
          address: data !== undefined ? data.address : "",
          email: data !== undefined ? data.email : "",
          mobilnumber: data !== undefined ? data.mobilnumber : "",
          telnumber: data !== undefined ? data.telnumber : "",
          fax: data !== undefined ? data.fax : "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldTouched
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridId">
                <Form.Label>Kundennummer</Form.Label>
                <Form.Control
                  name="id"
                  type="text"
                  placeholder="Kundennummer"
                  value={values.id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("id", true, false)}
                  isValid={touched.id && !errors.id}
                  isInvalid={!!errors.id && touched.id}
                  disabled={props.isDisabled}
                />
                {errors.id && touched.id ? (
                  <div className={styles["invalid-error"]}>{errors.id}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPolicyholder">
                <Form.Label>Versicherungsnehmer</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Versicherungsnehmer"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("name", true, false)}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                />
                {errors.name && touched.name ? (
                  <div className={styles["invalid-error"]}>{errors.name}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPostcode">
                <Form.Label>Plz</Form.Label>
                <Form.Control
                  name="postcode"
                  type="text"
                  placeholder="Plz"
                  value={values.postcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("postcode", true, false)}
                  isValid={touched.postcode && !errors.postcode}
                  isInvalid={!!errors.postcode && touched.postcode}
                />
                {errors.postcode && touched.postcode ? (
                  <div className={styles["invalid-error"]}>
                    {errors.postcode}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPlace">
                <Form.Label>Ort</Form.Label>
                <Form.Control
                  name="place"
                  type="text"
                  placeholder="Ort"
                  value={values.place}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("place", true, false)}
                  isValid={touched.place && !errors.place}
                  isInvalid={!!errors.place && touched.place}
                />
                {errors.place && touched.place ? (
                  <div className={styles["invalid-error"]}>{errors.place}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridAddress">
                <Form.Label>Adresse</Form.Label>
                <Form.Control
                  name="address"
                  type="text"
                  placeholder="Adresse"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("address", true, false)}
                  isValid={touched.address && !errors.address}
                  isInvalid={!!errors.address && touched.address}
                />
                {errors.address && touched.address ? (
                  <div className={styles["invalid-error"]}>
                    {errors.address}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>E-Mail</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="E-Mail"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("email", true, false)}
                  isValid={touched.email && !errors.email}
                  isInvalid={!!errors.email && touched.email}
                />
                {errors.email && touched.email ? (
                  <div className={styles["invalid-error"]}>{errors.email}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridMobilnumber">
                <Form.Label>Mobilnummer</Form.Label>
                <Form.Control
                  name="mobilnumber"
                  type="text"
                  placeholder="Mobilnummer"
                  value={values.mobilnumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("mobilnumber", true, false)}
                  isValid={touched.mobilnumber && !errors.mobilnumber}
                  isInvalid={!!errors.mobilnumber && touched.mobilnumber}
                />
                {errors.mobilnumber && touched.mobilnumber ? (
                  <div className={styles["invalid-error"]}>
                    {errors.mobilnumber}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridTelnumber">
                <Form.Label>Telefonnummer</Form.Label>
                <Form.Control
                  name="telnumber"
                  type="text"
                  placeholder="Telefonnumer"
                  value={values.telnumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("telnumber", true, false)}
                  isValid={touched.telnumber && !errors.telnumber}
                  isInvalid={!!errors.telnumber && touched.telnumber}
                />
                {errors.telnumber && touched.telnumber ? (
                  <div className={styles["invalid-error"]}>
                    {errors.telnumber}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridFax">
                <Form.Label>Fax</Form.Label>
                <Form.Control
                  name="fax"
                  type="text"
                  placeholder="Fax"
                  value={values.fax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("fax", true, false)}
                  isValid={touched.fax && !errors.fax}
                  isInvalid={!!errors.fax && touched.fax}
                />
                {errors.fax && touched.fax ? (
                  <div className={styles["invalid-error"]}>{errors.fax}</div>
                ) : null}
              </Form.Group>
            </Row>
            <SubmitButton className="submitBtn" variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PolicyholderForm;
