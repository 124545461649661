import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class FranchiseDataService {
// Get all franchises from the database
  getAll(filter) {
    return axios.post(API_URL + "/franchises", filter, { headers: authHeader() });
  }

  // Create a new franchise
  create(data) {
    return axios.post(API_URL + "/franchise", data, { headers: authHeader() });
  }

  // Get just one franchise
  get(id) {
    return axios.get(API_URL + `/franchise/${id}`, { headers: authHeader() });
  }

  // Delete a specific franchise out of the table.
  delete(id) {
    return axios.delete(API_URL + `/franchise/${id}`, { headers: authHeader() });
  }

  // Update the data of one specific franchise.
  update(id, data) {
    return axios.put(API_URL + `/franchise/${id}`, data, {
      headers: authHeader(),
    });
  }
}

export default new FranchiseDataService();