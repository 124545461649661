import React, { useEffect, useState, useCallback, } from "react";
import Header from "../Header/Header";
import CrudContent from "../CrudContent/CrudContent";
import InvoiceDataService from "../../services/invoice.data.service";
import { header, invoiceHeaders, modalTitle, modalBody } from "./AccountingInvoice";
import fileDownload from "js-file-download";

const CreditInvoice = () => {
  const [allCredits, setAllCredits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // Count of header items that need to be skipped
  const skip = 1;

  const getAllCreditInvoices = useCallback(() => {
    InvoiceDataService.getAllCreditInvoices(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          var creditInvoices = [];
          response.data.forEach((element) => {
            creditInvoices.push({
              invoice_number: element.invoice_number,
              policyholder: element.policyholder,
              ship: element.ship,
              filename: element.filename,
              division_type: element.division_type,
              path: element.path,
              type: element.type,
              year: element.year,
              id: element.id,
            });
          });
          setAllCredits(creditInvoices);
        } else {
          setAllCredits([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    getAllCreditInvoices();
  }, [getAllCreditInvoices]);


  // Function to set the current filter value.
 const handleFilter = (filterValue) => {
  setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
};

const handleYearChange = (year) => {
  console.log(year);
  setFilter((prevFilter) => {
    return { ...prevFilter, year: year };
  });
};

// Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    console.log(item);
    // Create an array which holds multiple arrays each with
    item.policyholder =
      item.policyholder !== null ? item.policyholder.name : null;
    item.ship = item.ship !== null ? item.ship.shipname : null;
    // one key value pair separaeted by a comma.
    let invoiceArray = Object.entries(item);
    console.log(invoiceArray);
    for (let i = 0; i < invoiceHeaders.length; i++) {
      // Replace all keys with the header value.
      invoiceArray[i][0] = invoiceHeaders[i];
      invoiceArray[i][2] = "";
    }
    console.log(invoiceArray);
    // Set the displayed data.
    setDisplayData({ array: invoiceArray });
  };

  const handleDownload = (item) => {
    InvoiceDataService.downloadCreditInvoice(item.filename)
      .then((response) => {
        fileDownload(response.data, item.filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="Gutschriftrechnungen"
          items={allCredits}
          header={header}
          modalTitle={modalTitle}
          modalBody={modalBody}
          placeholder={"Filter Rechnungen..."}
          onFilter={handleFilter}
          skip={skip}
          attr={{ 1: "name", 2: "shipname" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          onYearChange={handleYearChange}
          hasYearFilter
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          download
          onDownloadInvoice={handleDownload}
        />
      </div>
    </main>
  );
};

export default CreditInvoice;
