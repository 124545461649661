import React, { useState, useEffect, useCallback } from "react";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import Header from "../Header/Header";
import InsuredObjectForm from "./InsuredObjectForm";
import CrudContent from "../CrudContent/CrudContent";
import useAlert from "../../hooks/use-alert";
import GenericAlert from "../Alert/GenericAlert";

const InsuredObject = () => {
  // Variable which store all ships which are displayed in the table
  const [allShips, setAllShips] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();

  // The header of the table
  const header = [
    "Id",
    "Schiffname",
    "VN",
    "Dim.",
    "Flagge",
    "Tragfähigkeit",
    "Imo Nr.",
    "Heimathafen",
    "Baujahr",
    "Klasse",
    "Motor",
    "Fahrtgebiet",
    "Bearbeiten",
  ];
  // Headers to display the items in the detailed view
  const headers = [
    "Id: ",
    "Schiffname: ",
    "Versicherungsnehmer: ",
    "Dim.: ",
    "Flagge: ",
    "Tragfähigkeit: ",
    "Imo Nummer: ",
    "Heimathafen: ",
    "Baujahr: ",
    "Klasse: ",
    "Motor: ",
    "Fahrtgebiet: ",
    "Rufzeichen: ",
    "Weitere Informationen: ",
  ];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "Schiff löschen";
  const modalTitleEdit = "Schiff bearbeiten";
  const modalTitleAdd = "Schiff hinzufügen";
  const modalBody = "Möchten Sie das Schiff wirklich löschen?";

  // The data to be edited
  const [editData, setEditData] = useState({});

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "" });

  // Count of header items that need to be skipped
  const skip = 2;

  // Function to get all ships out of the database
  const getAllShips = useCallback(() => {
    InsuredObjectDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          // Array which holds all insured objects in the end.
          var io = [];
          response.data.forEach((element) => {
            io.push({
              id: element.id,
              shipname: element.shipname,
              policyholder: element.policyholder,
              dim: element.dim,
              flag: element.flag,
              load_capacity: element.load_capacity,
              imo_nr: element.imo_nr,
              home_port: element.home_port,
              construction_year: element.construction_year,
              ship_class: element.ship_class,
              motor: element.motor,
              sailing_area: element.sailing_area,
              callsign: element.callsign,
              more_information: element.more_information,
            });
          });

          // Set all ships variable to the io array.
          setAllShips(io);
        } else {
          // Set all ships back to its default value.
          setAllShips([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  // Calls the getAllShips() function every time the filter value changes
  useEffect(() => {
    getAllShips();
  }, [getAllShips]);

  // Function to delete a specific insured object out of the database.
  // It accepts the item to get the specific id.
  const deleteItem = (item) => {
    InsuredObjectDataService.delete(item.id)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Das Schiff "' + item.shipname + '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllShips();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Das Schiff "' + item.shipname + '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  const selectValuesFromObjects = (item) => {
    item.policyholder = item.policyholder.value;
  };

  // Function to edit an specific table record
  const editItem = (item) => {
    selectValuesFromObjects(item);
    console.log(item);
    InsuredObjectDataService.update(item.id, item)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Das Schiff "' + item.shipname + '" wurde erfolgreich bearbeitet!',
          "success"
        );
        getAllShips();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Das Schiff "' + item.shipname + '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to add a new table record
  const addItem = (item) => {
    selectValuesFromObjects(item);
    console.log(item);
    InsuredObjectDataService.create(item)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Das Schiff "' + item.shipname + '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllShips();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Das Schiff "' + item.shipname + '" konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function which sets the state of the filteres value.
  const handleFilter = (filterValue) => {
    setFilter(filterValue);
  };

  // Function to set the edit data.
  const handleEditItem = (item) => {
    console.log(item);
    setEditData(item);
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    // Only get one attribute of the policyholder object.
    item.policyholder = item.policyholder.name;
    // Create an array which holds multiple arrays each with
    // one key value pair separated by a comma.
    let shipArray = Object.entries(item);
    for (let i = 0; i < headers.length; i++) {
      // Replace all keys with the header value.
      shipArray[i][0] = headers[i];
    }
    console.log(shipArray);
    // Set the displayed data.
    setDisplayData({ array: shipArray });
  };

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="Schiffe"
          items={allShips}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <InsuredObjectForm
              editData={editData}
              btnText="Bearbeiten"
              isEditing
            />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Schiffkartei..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<InsuredObjectForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 2: "name" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          edit
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};
export default InsuredObject;
