import React from "react";
import { Navbar, Container } from "react-bootstrap";
import WheelSvg from "../../../assets/wheel.svg";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import keycloak from "../../../Keycloak";

function Bar() {

  return (
    <Navbar bg="primary" variant="dark" fixed="top">
      <Container>
        <Navbar.Brand href="policyholder">
          <img
            src={WheelSvg}
            alt="profile"
            style={{ width: "30px", height: "30px", color: "white" }}
          />{" "}
          Störk und Süssmann Versicherungen
        </Navbar.Brand>
        <OverlayTrigger
          key={"bottom"}
          placement={"bottom"}
          overlay={
            <Tooltip id={"tooltip-bottom"}>
              Logout
            </Tooltip>
          }
        >
          <Button onClick={() => keycloak.logout()}>
            <i
              onClick={() => keycloak.logout()}
              className="bi bi-door-closed-fill"
              style={{ color: "white", fontSize: "20px" }}
            ></i>
          </Button>
        </OverlayTrigger>
      </Container>
    </Navbar>
  );
}

export default Bar;
