import React, { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";
import styles from "./DamageForm.module.scss";

const DamageForm = (props) => {
  const data = props.editData;
  console.log(data);

  const [ship, setShip] = useState([]);
  // Function to get all ships out of the database.
  const getAllShips = () => {
    InsuredObjectDataService.getAll({ filter: "" })
      .then((response) => {
        console.log(response.data);
        let ships = [];
        response.data.forEach((element) => {
          ships.push({ value: element, label: element.shipname });
        });
        setShip(ships);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllShips() function thr first time this component
  // is rendered.
  useEffect(() => {
    getAllShips();
  }, []);

  const DamageObjectSchema = Yup.object().shape({
    sf_number: Yup.string().required("Pflichtfeld"),
    vht_number: Yup.string().required("Pflichtfeld"),
    damage_date: Yup.date().required("Pflichtfeld"),
    damage_type: Yup.string().required("Pflichtfeld"),
    ship: Yup.object().required("Pflichtfeld").nullable(),
    more_info: Yup.string().nullable(),
  });

  return (
    <Formik
      validationSchema={DamageObjectSchema}
      onSubmit={(values) => {
        const ship = values.ship;
        console.log(values);
        props.onSubmit(values);
        values.ship = ship;
      }}
      initialValues={{
        sf_number: data !== undefined ? data.sf_number : "",
        vht_number: data !== undefined ? data.vht_number : "",
        damage_date: data !== undefined ? data.damage_date : "",
        damage_type: data !== undefined ? data.damage_type : "",
        ship:
          data !== undefined
            ? { value: data.ship, label: data.ship.shipname }
            : "",
        more_info:
          data !== undefined && data.more_info !== null ? data.more_info : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="formGridSfNumber">
              <Form.Label>SF-Nummer</Form.Label>
              <Form.Control
                name="sf_number"
                type="text"
                placeholder="SF-Nummer"
                value={values.sf_number}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("sf_number", true, false)}
                isValid={touched.sf_number && !errors.sf_number}
                isInvalid={!!errors.sf_number && touched.sf_number}
                disabled={props.isEditing}
              />
              {errors.sf_number && touched.sf_number ? (
                <div className={styles["invalid-error"]}>
                  {errors.sf_number}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridVHTNumber">
              <Form.Label>VHT-Nummer</Form.Label>
              <Form.Control
                name="vht_number"
                type="text"
                placeholder="VHT-Nummer"
                value={values.vht_number}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("vht_number", true, false)}
                isValid={touched.vht_number && !errors.vht_number}
                isInvalid={!!errors.vht_number && touched.vht_number}
              />
              {errors.vht_number && touched.vht_number ? (
                <div className={styles["invalid-error"]}>
                  {errors.vht_number}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridDamageDate">
              <Form.Label>Schadensdatum</Form.Label>
              <Form.Control
                name="damage_date"
                type="date"
                placeholder="Schadensdatum"
                value={values.damage_date}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("damage_date", true, false)}
                isValid={touched.damage_date && !errors.damage_date}
                isInvalid={!!errors.damage_date && touched.damage_date}
              />
              {errors.damage_date && touched.damage_date ? (
                <div className={styles["invalid-error"]}>
                  {errors.damage_date}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDamageType">
              <Form.Label>Art des Schadens</Form.Label>
              <Form.Control
                name="damage_type"
                type="text"
                placeholder="Art des Schadens"
                value={values.damage_type}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("damage_type", true, false)}
                isValid={touched.damage_type && !errors.damage_type}
                isInvalid={!!errors.damage_type && touched.damage_type}
              />
              {errors.damage_type && touched.damage_type ? (
                <div className={styles["invalid-error"]}>
                  {errors.damage_type}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridShip">
              <Form.Label>Schiff</Form.Label>
              <Select
                isClearable
                isSearchable
                id="ship"
                name="ship"
                onChange={(selected) => {
                  values.ship = selected;
                  setFieldValue("ship", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() => setFieldTouched("ship", true, false)}
                placeholder="Wähle ein Schiff..."
                options={ship}
                value={values.ship}
                styles={chooseCustomSelectStyle(
                  errors.ship,
                  values.ship,
                  touched.ship
                )}
              />
              {errors.ship && touched.ship ? (
                <div className={styles["invalid-error"]}>{errors.ship}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridSailingArea">
              <Form.Label>Weitere Informationen</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="more_info"
                value={values.more_info}
                placeholder="Weitere Informationen"
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("more_info", true, false)}
                isValid={touched.more_info && !errors.more_info}
                isInvalid={!!errors.more_info && touched.more_info}
              />
              {errors.more_info && touched.more_info ? (
                <div className={styles["invalid-error"]}>
                  {errors.more_info}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <div className={styles.submitBtn}>
            <SubmitButton variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DamageForm;
