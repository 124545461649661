import React, { useState, useEffect, useCallback } from "react";
import PoolShipDataService from "../../services/pool-ship.data.service";
import PoolDataService from "../../services/pool.data.service";
import GenericAlert from "../Alert/GenericAlert";
import CrudContentPool from "../CrudContent/CrudContentPool";
import Header from "../Header/Header";
import PoolForm from "./PoolForm";
import useAlert from "../../hooks/use-alert";

const Pool = () => {
  const [allPools, setAllPools] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();
  const [pool, setPool] = useState({});

  const header = [
    "Poolname",
    "Zahlungsmethode",
    "Von",
    "Bis",
    "Sparte",
    "Bearbeiten",
    "Verlängern",
  ];
  const headers = ["Poolname:", "Zahlungsmethode:", "Von:", "Bis:", "Sparte"];

  const modalHeader = ["Schiffname"];

  // Data for the different modal (edit, delete, add)
  const modalTitle = "Pool löschen";
  const modalTitleEdit = "Pool bearbeiten";
  const modalTitleAdd = "Pool hinzufügen";
  const modalBody = "Möchten Sie den Pool wirklich löschen?";

  // The data to be edited
  const [editData, setEditData] = useState({});

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "" });

  // Count of header items that need to be skipped
  const skip = 2;

  // Array to set currency or percentage sign after specific values.
  const signArray = [""];

  // Function to get all pools
  const getAllPools = useCallback(() => {
    PoolDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          var pool = [];
          response.data.forEach((element) => {
            pool.push({
              name: element.name,
              payment_method: element.payment_method,
              insurance_period_from: element.insurance_period_from,
              insurance_period_to: element.insurance_period_to,
              division_type: element.division_type,
            });
          });
          setAllPools(pool);
        } else {
          setAllPools([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    getAllPools();
  }, [getAllPools]);

  // Function to delete a specific item
  const deleteItem = (item) => {
    PoolDataService.delete(item.name)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Der Pool "' + item.name + '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllPools();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          "Der Pool " + item.name + " konnte nicht gelöscht werden!",
          "danger"
        );
        console.log(error);
      });
  };

  // Function to edit a specific P&I item.
  const editItem = (item) => {
    PoolDataService.update(item.name, item)
      .then((response) => {
        let pool = response.data;
        setPool(pool);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Der Pool "' + item.name + '" wurde erfolgreich bearbeitet!',
          "success"
        );
        setEditData(item);
        getAllPools();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Der Pool "' + item.name + '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to add a new item to the table
  const addItem = (item) => {
    PoolDataService.create(item)
      .then((response) => {
        console.log("test");
        let pool = response.data;
        setPool(pool);
        getAllPools();
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Der Pool "' + item.name + '" wurde erfolgreich erstellt!',
          "success"
        );
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          "Beim hinzufügen ist ein Fehler aufgetreten!",
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter(filterValue);
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    const data = { ...item };
    console.log(data);
    setEditData(data);
  };

  const getAllPoolShips = (item) => {
    PoolShipDataService.getAll({ filter: item.name }).then((response) => {
      if (response.data.length !== 0) {
        // Array which holds the specific hull-corps.
        var poolShip = [];
        response.data.forEach((element) => {
          poolShip.push({
            ship: element.ship,
          });
        });
        createDisplayDataItem(poolShip, item);
      }
    });
  };

  // Function to create the display data item.
  const createDisplayDataItem = (poolShip, item) => {
    // Create an array which holds multiple arrays each with
    // one key value pair separated by a comma.
    let shipArray = Object.entries(item);
    for (let i = 0; i < headers.length; i++) {
      // Replace all keys with the header value.
      shipArray[i][0] = headers[i];
      shipArray[i][2] = signArray[i];
    }
    console.log(shipArray);
    // Set the displayed data.
    setDisplayData({
      array: shipArray,
      tableData: poolShip,
    });
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    // Only get one attribute of the policyholder object.
    const item = { ...data };

    getAllPoolShips(item);
  };

  const extendItem = (item) => {
    PoolDataService.clone(item.name)
      .then((response) => {
        console.log(response.data);
        handleAlertVisible(
          "Erfolgreich erweitert",
          'Der Pool "' + item.name + '" wurde erfolgreich erweitert!',
          "success"
        );
        getAllPools();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Der Pool "' + item.name + '" konnte nicht erweitert werden!',
          "danger"
        );
        console.log(error);
      });
  };

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContentPool
          heading="Poolverwaltung"
          items={allPools}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          form={<PoolForm editData={editData} btnText="Bearbeiten" />}
          formAdd={<PoolForm btnText="Hinzufügen" />}
          placeholder={"Filter Pool..."}
          modalTitleAdd={modalTitleAdd}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          edit
          modalHeader={modalHeader}
          modalHeaders={modalHeader}
          onExtend={extendItem}
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          pool={pool}
        />
        <GenericAlert
          show={show}
          alertVariant={alertContent.variant}
          alertHeading={alertContent.heading}
          alertBody={alertContent.message}
        />
      </div>
    </main>
  );
};

export default Pool;
