import React from "react";
import styles from "./OverviewItem.module.scss";
import { Accordion } from "react-bootstrap";
import TableWithoutButtons from "../Table/TableWithoutButtons";

const OverviewItem = (props) => {
  return (
    <>
    {props.items.map((datum, index) => (
      <Accordion.Item eventKey={datum.name} key={index}>
        <Accordion.Header>{datum.name}</Accordion.Header>
        <Accordion.Body>
          {props.hasTable && props.tableData[index] !== undefined &&
          <TableWithoutButtons 
              items={props.tableData[index]}
              header={props.header}
              headers={props.headers}
              attr={props.attr}
              signArray={props.signArray}
          />}
          <div>
            {Object.values(datum.division).map((element, index) => (
              <div key={index}>
                <div>
                  <span className={styles.header}>{datum.headers[index]}</span>
                  <span className={styles.items}>{element !== null ? element : "-"}</span>
                  <span className={styles.items}>{datum.signs[index]}</span>
                </div>
                <hr></hr>
              </div>
            ))}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    ))}
    </>
  );
};

export default OverviewItem;
