import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class InvoiceDataService {
  generateInterestInvoice(invoiceData) {
    return axios.post(API_URL + "/interest-invoice", invoiceData, {
      headers: authHeader(),
    });
  }

  generateHullFranchiseInvoice(invoiceData) {
    return axios.post(API_URL + "/hull-franchise-invoice", invoiceData, {
      headers: authHeader(),
    });
  }

  generateHullInvoice(invoiceData) {
    return axios.post(API_URL + "/hull-invoice", invoiceData, {
      headers: authHeader(),
    });
  }

  generateLohInvoice(invoiceData) {
    return axios.post(API_URL + "/loh-invoice", invoiceData, {
      headers: authHeader(),
    });
  }

  generateLohFranchiseInvoice(invoiceData) {
    return axios.post(API_URL + "/loh-franchise-invoice", invoiceData, {
      headers: authHeader(),
    });
  }

  generatePAndIInvoice(invoiceData) {
    return axios.post(API_URL + "/p-and-i-invoice", invoiceData, {
      headers: authHeader(),
    });
  }

  generateElectronicsInsuranceInvoice(invoiceData) {
    return axios.post(API_URL + "/electronics-insurance-invoice", invoiceData, {
      headers: authHeader(),
    });
  }

  getAllAccountingInvoices(filter) {
    return axios.post(API_URL + "/accounting-invoices", filter, {
      headers: authHeader(),
    });
  }

  getAllCustomerInvoices(filter) {
    return axios.post(API_URL + "/customer-invoices", filter, {
      headers: authHeader(),
    });
  }

  getAllCreditInvoices(filter) {
    return axios.post(API_URL + "/credit-invoices", filter, {
      headers: authHeader(),
    });
  }

  delete(id) {
    return axios.delete(API_URL + `/invoice/${id}`, { headers: authHeader() });
  }

  downloadAccountingInvoice(filename) {
    return axios.get(API_URL + `/accounting-invoice/download/${filename}`, {
      headers: authHeader(), responseType: 'blob',
    });
  }

  downloadCustomerInvoice(filename) {
    return axios.get(API_URL + `/customer-invoice/download/${filename}`, {
      headers: authHeader(), responseType: 'blob',
    });
  }

  downloadCreditInvoice(filename) {
    return axios.get(API_URL + `/credit-invoice/download/${filename}`, {
      headers: authHeader(), responseType: 'blob',
    });
  }
}

export default new InvoiceDataService();
