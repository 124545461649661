import React, { useState } from "react";
import { Form } from "react-bootstrap";

const SelectYear = (props) => {
  const [year, setYear] = useState();

  const createYearArray = () => {
    var startYear = 2020;
    var currentYear = new Date().getFullYear();
    var yearArray = [];

    for (let i = startYear; i <= currentYear; i++) {
      yearArray.push(i);
    }

    return yearArray;
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
    props.onYearChange(e.target.value);
  };

  return (
    <Form.Select
      aria-label="Jahr"
      name="year"
      onChange={handleYearChange}
      value={year}
    >
      <option value="">Wähle das Jahr...</option>
      {createYearArray().map((year, index) => (
        <option key={index} value={year}>
          {year}
        </option>
      ))}
    </Form.Select>
  );
};

export default SelectYear;
