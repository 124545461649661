import React from "react";
import { Modal, Button } from "react-bootstrap";
import TableWithoutButtons from "../../Table/TableWithoutButtons";
import styles from "./CustomMoreInfoModal.module.scss";

function CustomMoreInfoModal(props) {
  console.log(props.items)
  console.log(props.items.tableData)
  return (
    <Modal centered show={props.show} onHide={props.onHide}>
      <Modal.Header
        className={styles.modalHeader}
        closeButton
        closeVariant="white"
      >
        <Modal.Title>Informationsüberblick</Modal.Title>
      </Modal.Header>

      <Modal.Body>
      {(props.hasTable && props.items.tableData !== undefined)&&
          <div> 
          <h2>Gesellschaften</h2>
          <TableWithoutButtons 
            items={props.items.tableData}
            header={props.header}
            headers={props.headers}
            attr={props.attr}
            signArray={props.signArray}
          />
          </div>}
        <div className={styles.wrapper}>
          {props.items.array.map((element, index) => (
            <div key={index}>
              <div className={styles["span-wrapper"]}>
                <div>
                <span className={styles.header}>{element[0]}</span>
                </div>
                <div className={styles["item-wrapper"]}>
                <span className={styles.items}>{element[1]}</span>
                <span className={styles.items}>{element[2]}</span>
                </div>
              </div>
              <hr></hr>
            </div>
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomMoreInfoModal;
