import { useState, useEffect } from "react";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";
import DivisionDataService from "../../services/division.data.service";
import InvoiceDataService from "../../services/invoice.data.service";
import styles from "./CreditForm.module.scss"

const CreditForm = (props) => {
  const data = props.editData;

  const [divisions, setDivisions] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const getAllDivisions = () => {
    DivisionDataService.getAll()
      .then((response) => {
        console.log(response.data);
        let divisions = [];
        response.data.forEach((element) => {
          divisions.push({ value: element, label: element.policy_number });
        });
        setDivisions(divisions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllDivisions();
  }, []);

  const getAllInvoices = () => {
    InvoiceDataService.getAllAccountingInvoices({filter: "", year: ""})
        .then(response => {
            console.log(response.data);
            let invoices = [];
            response.data.forEach((element) => {
                invoices.push({ value: element, label: element.invoice_number });
            });
            setInvoices(invoices);
        })
        .catch(error => {
            console.log(error);
        })
  };

  useEffect(() => {
    getAllInvoices();
  }, []);

  const CreditObjectSchema = Yup.object().shape({
    division: Yup.object().required("Pflichtfeld"),
    invoice: Yup.object().required("Pflichtfeld"),
    credit_period_from: Yup.date().required("Pflichtfeld"),
    credit_period_to: Yup.date().required("Pflichtfeld"),
  });

  return (
    <Formik
      validationSchema={CreditObjectSchema}
      onSubmit={(values) => {
        const division = values.division;
        const invoice = values.invoice;
        console.log(values);
        props.onSubmit(values);
        values.division = division;
        values.invoice = invoice;
      }}
      initialValues={{
        id: data !== undefined ? data.id : "",
        division: data !== undefined ? { value: data.division, label: data.division.policy_number } : "",
        invoice: data !== undefined ? { value: data.invoice, label: data.invoice.invoice_number } : "",
        credit_period_from: data !== undefined ? data.credit_period_from : "",
        credit_period_to: data !== undefined ? data.credit_period_to : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridDivision">
              <Form.Label>Sparte</Form.Label>
              <Select
                isClearable
                isSearchable
                id="division"
                name="division"
                onChange={(selected) => {
                  values.division = selected;
                  setFieldValue("division", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() => setFieldTouched("division", true, false)}
                placeholder="Wähle eine Sparte..."
                options={divisions}
                value={values.division}
                styles={chooseCustomSelectStyle(
                  errors.division,
                  values.division,
                  touched.division
                )}
              />
              {errors.division && touched.division ? (
                <div className={styles["invalid-error"]}>{errors.division}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInvoice">
              <Form.Label>Rechnung</Form.Label>
              <Select
                isClearable
                isSearchable
                id="invoice"
                name="invoice"
                onChange={(selected) => {
                  values.invoice = selected;
                  setFieldValue("invoice", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() => setFieldTouched("invoice", true, false)}
                placeholder="Wähle eine Rechnung..."
                options={invoices}
                value={values.invoice}
                styles={chooseCustomSelectStyle(
                  errors.invoice,
                  values.invoice,
                  touched.invoice
                )}
              />
              {errors.invoice && touched.invoice ? (
                <div className={styles["invalid-error"]}>{errors.invoice}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCreditPeriodTo">
              <Form.Label>Gutschrift von</Form.Label>
              <Form.Control
                name="credit_period_from"
                type="date"
                placeholder="Gutschrift von"
                value={values.credit_period_from}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("credit_period_from", true, false)}
                isValid={touched.credit_period_from && !errors.credit_period_from}
                isInvalid={!!errors.credit_period_from && touched.credit_period_from}
              />
              {errors.credit_period_from && touched.credit_period_from ? (
                <div className={styles["invalid-error"]}>
                  {errors.credit_period_from}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCreditPeriodTo">
              <Form.Label>Gutschrift bis</Form.Label>
              <Form.Control
                name="credit_period_to"
                type="date"
                placeholder="Gutschrift bis"
                value={values.credit_period_to}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("credit_period_to", true, false)}
                isValid={touched.credit_period_to && !errors.credit_period_to}
                isInvalid={!!errors.credit_period_to && touched.credit_period_to}
              />
              {errors.credit_period_to && touched.credit_period_to ? (
                <div className={styles["invalid-error"]}>
                  {errors.credit_period_to}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <div className={styles.submitBtn}>
            <SubmitButton variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreditForm;
