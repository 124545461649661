import React, { useCallback, useState } from "react";
import { Accordion } from "react-bootstrap";
import Header from "../Header/Header";
import OverviewItem from "./OverviewItem";
import OverViewShipData from "./OverviewShipData";
import styles from "./ShipOverview.module.scss";

const ShipOverview = () => {
  // Header and sign array for the hull corporations
  const hullCorpHeader = ["Gesellschaft", "Anteil", "Prämiensatz"];
  const hullCorpSignArray = ["", "%", "%"];

  // Header and the sign array for the P & I franchises
  const pAndIFranchHeader = ["Franchise", "Höhe"];
  const pAndIFranchSignArray = ["", "€"];

  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [shipSelected, setShipSelected] = useState(false);
  const [yearSelected, setYearSelected] = useState(false);

  const [hullData, setHullData] = useState([]);
  const [hullFranchiseData, setHullFranchiseData] = useState([]);
  const [interestData, setInterestData] = useState([]);
  const [lohData, setLohData] = useState([]);
  const [lohFranchiseData, setLohFranchiseData] = useState([]);
  const [electronicsInsuranceData, setElectronicsInsuranceData] = useState([]);
  const [pAndIData, setPAndIData] = useState([]);

  const [hullCorpData, setHullCorpData] = useState([]);
  const [pAndIFranchData, setPAndIFranchData] = useState([]);

  const handleHullData = useCallback((ship) => {
    console.log(ship);
    setHullData(ship);
  }, []);

  const handleHullFranchiseData = useCallback((ship) => {
    console.log(ship);
    setHullFranchiseData(ship);
  }, []);

  const handleInterestData = useCallback((ship) => {
    console.log(ship);
    setInterestData(ship);
  }, []);

  const handleLohData = useCallback((ship) => {
    console.log(ship);
    setLohData(ship);
  }, []);

  const handleLohFranchiseData = useCallback((ship) => {
    console.log(ship);
    setLohFranchiseData(ship);
  }, []);

  const handleElectronicsInsuranceData = useCallback((ship) => {
    console.log(ship);
    setElectronicsInsuranceData(ship);
  }, []);

  const handlePAndIData = useCallback((ship) => {
    console.log(ship);
    setPAndIData(ship);
  }, []);

  const handleHullCorpData = useCallback((hullCorps) => {
    console.log(hullCorps);
    setHullCorpData(hullCorps);
  }, []);

  const handlePAndIFranchData = useCallback((pAndIFranch) => {
    console.log(pAndIFranch);
    setPAndIFranchData(pAndIFranch);
  }, []);

  const handleUnauthorized = (isUnauthorized) => {
    console.log(isUnauthorized);
    setIsUnauthorized(isUnauthorized);
  };

  const handleShipSelected = (isSelected) => {
    setShipSelected(isSelected);
  };

  const handleYearSelected = (isSelected) => {
    setYearSelected(isSelected);
  };

  return (
    <main>
      <Header />
      <div className="main">
        <div className={styles.container}>
          <h1>Schiffsübersicht</h1>
        </div>
        <OverViewShipData
          onHullDataInserted={handleHullData}
          onHullFranchiseDataInserted={handleHullFranchiseData}
          onInterestDataInserted={handleInterestData}
          onLohDataInserted={handleLohData}
          onLohFranchiseDataInserted={handleLohFranchiseData}
          onElectronicsInsuranceDataInserted={handleElectronicsInsuranceData}
          onPAndIDataInserted={handlePAndIData}
          onHullCorpFetched={handleHullCorpData}
          onPAndIFranchFetched={handlePAndIFranchData}
          onUnauthorized={handleUnauthorized}
          onShipSelected={handleShipSelected}
          onYearSelected={handleYearSelected}
        />
        <div className={styles.container}>
          <div className={styles["ship-accordion"]}>
            <Accordion>
              {hullData.name !== "" && (
                <OverviewItem
                  items={hullData}
                  tableData={hullCorpData}
                  header={hullCorpHeader}
                  headers={hullCorpHeader}
                  attr={{ 0: "name" }}
                  signArray={hullCorpSignArray}
                  hasTable
                />
              )}
              {hullFranchiseData.name !== "" && (
                <OverviewItem items={hullFranchiseData} />
              )}
              {interestData.name !== "" && (
                <OverviewItem items={interestData} />
              )}
              {lohData.name !== "" && <OverviewItem items={lohData} />}
              {lohFranchiseData.name !== "" && (
                <OverviewItem items={lohFranchiseData} />
              )}
              {electronicsInsuranceData.name !== "" && (
                <OverviewItem items={electronicsInsuranceData} />
              )}
              {pAndIData.name !== "" && (
                <OverviewItem
                  items={pAndIData}
                  tableData={pAndIFranchData}
                  header={pAndIFranchHeader}
                  headers={pAndIFranchHeader}
                  attr={{ 0: "name" }}
                  signArray={pAndIFranchSignArray}
                  hasTable
                />
              )}
            </Accordion>
            {hullData.length === 0 &&
              hullFranchiseData.length === 0 &&
              interestData.length === 0 &&
              lohData.length === 0 &&
              lohFranchiseData.length === 0 &&
              electronicsInsuranceData.length === 0 &&
              pAndIData.length === 0 &&
              shipSelected &&
              yearSelected &&
              !isUnauthorized && (
                <div className={styles["wrap-container"]}>
                  <p>Keine Daten vorhanden!</p>
                </div>
              )}
            {isUnauthorized && (
              <div className={styles["wrap-container"]}>
                <p>Nicht autorisiert. Bitte erneut einloggen!</p>
              </div>
            )}
            {!shipSelected && !yearSelected && !isUnauthorized && (
              <div className={styles["wrap-container"]}>
                <p>Wähle ein Schiff und das Geschäftsjahr!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ShipOverview;
