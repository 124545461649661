import { useState } from "react";

const useAlert = () => {
    const [show, setShow] = useState(false);
    const [alertContent, setAlertContent] = useState({heading: "", message: "", variant: ""});

    const handleAlertVisible = (heading, message, variant) => {
        setAlertContent({heading, message, variant})
        setShow(true)
        setTimeout(() => {setShow(false)}, 2500)
    }; 

    return {
        alertContent,
        show,
        handleAlertVisible
    };
};

export default useAlert;