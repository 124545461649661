// Pattern for a price value.
// The price need to have to decimal places.
export const pricePattern = /^\d+([,.]{1}\d{1,2})?$/;

// Pattern for very accurate percentages.
// It can have four decimal places.
export const percentagePatternPremium = /^\d{1,2}([,.]{1}\d{1,4})?$/;

// Pattern for very accurate percentages.
// It can have four decimal places.
export const percentagePatternPremiumShare = /^\d{1,2}([,.]{1}\d{1,4})?$|^100$/;

// Normal percentage pattern.
// It can have two decimal places.
export const percentagePattern = /^\d{1,2}([,.]{1}\d{1,2})?$/

// Pattern for the name of the pool.
export const poolNamePattern =  /^[A-Za-z0-9äüöÄÜÖ\s]+[-]{1}[\s]?[0-9]{4}$/