import React from "react";
import styles from "./PoolForm.module.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { poolNamePattern } from "../../patterns/patterns";

const PoolForm = (props) => {
  const data = props.editData;
  console.log(data);

  const PoolObjectSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        poolNamePattern,
        "Falsches Format. Richtig ist (Poolname - 2022)"
      )
      .required("Pflichtfeld"),
    payment_method: Yup.string().required("Pflichtfeld"),
    insurance_period_from: Yup.date().required("Pflichtfeld"),
    insurance_period_to: Yup.date().required("Pflichtfeld"),
    division_type: Yup.string().nullable().required("Pflichtfeld"),
  });

  return (
    <>
      <Formik
        validationSchema={PoolObjectSchema}
        onSubmit={props.onSubmit}
        initialValues={{
          name: data !== undefined ? data.name : "",
          payment_method: data !== undefined ? data.payment_method : "",
          insurance_period_from:
            data !== undefined ? data.insurance_period_from : "",
          insurance_period_to:
            data !== undefined ? data.insurance_period_to : "",
          division_type: data !== undefined ? data.division_type : "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPoolname">
                <Form.Label>Poolname</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Poolname"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("name", true, false)}
                  isValid={touched.name && !errors.name}
                  isInvalid={!!errors.name && touched.name}
                  disabled={props.isEditing}
                />
                {errors.name && touched.name ? (
                  <div className={styles["invalid-error"]}>{errors.name}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridPaymentMethod">
                <Form.Label>Zahlungsmethode</Form.Label>
                <Form.Select
                  name="payment_method"
                  value={values.payment_method}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.payment_method && !errors.payment_method}
                  isInvalid={!!errors.payment_method && touched.payment_method}
                >
                  <option></option>
                  <option>1</option>
                  <option>1/2</option>
                  <option>1/3</option>
                  <option>1/4</option>
                  <option>1/6</option>
                  <option>1/12</option>
                </Form.Select>
                {errors.payment_method && touched.payment_method ? (
                  <div className={styles["invalid-error"]}>
                    {errors.payment_method}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsurancePeriodFrom">
                <Form.Label>Versicherungszeitraum von</Form.Label>
                <Form.Control
                  name="insurance_period_from"
                  type="date"
                  placeholder="Versicherungszeitraum von"
                  value={values.insurance_period_from}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_period_from", true, false)
                  }
                  isValid={
                    touched.insurance_period_from &&
                    !errors.insurance_period_from
                  }
                  isInvalid={
                    !!errors.insurance_period_from &&
                    touched.insurance_period_from
                  }
                />
                {errors.insurance_period_from &&
                touched.insurance_period_from ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_period_from}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridInsurancePeriodTo">
                <Form.Label>Versicherungszeitraum bis</Form.Label>
                <Form.Control
                  name="insurance_period_to"
                  type="date"
                  placeholder="Versicherungszeitraum bis"
                  value={values.insurance_period_to}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_period_to", true, false)
                  }
                  isValid={
                    touched.insurance_period_to && !errors.insurance_period_to
                  }
                  isInvalid={
                    !!errors.insurance_period_to && touched.insurance_period_to
                  }
                />
                {errors.insurance_period_to && touched.insurance_period_to ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_period_to}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridDivisionType">
                <Form.Label>Sparte</Form.Label>
                <Form.Select
                  name="division_type"
                  value={values.division_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.division_type && !errors.division_type}
                  isInvalid={!!errors.division_type && touched.division_type}
                >
                  <option hidden>
                    Wähle eine Sparte...
                  </option>
                  <option>Kasko</option>
                  <option>Kasko-Franchise</option>
                  <option>Interesse</option>
                  <option>LOH</option>
                  <option>LOH-Franchise</option>
                  <option>Elektronikversicherung</option>
                  <option>P & I</option>
                </Form.Select>
                {errors.division_type && touched.division_type ? (
                  <div className={styles["invalid-error"]}>
                    {errors.division_type}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <div className={styles.submitBtn}>
              <SubmitButton
                className="submitBtn"
                variant="primary"
                type="submit"
              >
                {props.btnText}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PoolForm;
