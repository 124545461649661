import React from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import styles from "./CustomDeleteModal.module.scss"

function CustomDeleteModal(props) {
  return (
    <Modal centered show={props.show} onHide={props.onHide}>
      <Modal.Header className={styles.modalHeader} closeButton closeVariant="white">
        <Modal.Title>{props.modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{props.modalBody}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Abbrechen
        </Button>
        <Button variant="danger" onClick={props.onDelete}>
          Löschen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomDeleteModal;
