import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class ElectronicsInsuranceDataService {
  // Get all electronics-insurances from the database
  getAll(filter) {
    return axios.post(API_URL + "/electronics-insurances", filter, {
      headers: authHeader(),
    });
  }

  // Get just one electronics-insurance
  get(pnr) {
    return axios.get(API_URL + `/electronics-insurance/${pnr}`, { headers: authHeader() });
  }

  // Create a new electronics-insurance
  create(data) {
    return axios.post(API_URL + "/electronics-insurance", data, {
      headers: authHeader(),
    });
  }

  // Delete a specific electronics-insurance out of the table.
  // Accepts the policynumber of the electronics-insurance.
  delete(pnr) {
    return axios.delete(API_URL + `/electronics-insurance/${pnr}`, {
      headers: authHeader(),
    });
  }

  // Update the data of one specific electronics-insurance.
  update(pnr, data) {
    return axios.put(API_URL + `/electronics-insurance/${pnr}`, data, {
      headers: authHeader(),
    });
  }

  getAllCurrent() {
    return axios.get(API_URL + "/electronics-insurances", { headers: authHeader() });
  }
}

export default new ElectronicsInsuranceDataService();
