import React, { useEffect, useState } from "react";
import PoolShipDataService from "../../../../services/pool-ship.data.service";
import PoolShipForm from "../../../Pool/PoolShipForm";
import styles from "./CustomPoolShipModal.module.scss";
import { Modal, Button } from "react-bootstrap";
import Table from "../../../Table/Table";
import CustomDeleteModal from "../CustomDeleteModal";
import useAlert from "../../../../hooks/use-alert";
import GenericAlert from "../../../Alert/GenericAlert";

const CustomPoolShipModal = (props) => {
  const { show, alertContent, handleAlertVisible } = useAlert();
  // Variable to display the delete modal
  const [showDel, setShowDel] = useState(false);

  // Variable with the current item which is selected
  const [item, setItem] = useState("");

  // Hold the ships for a specific pool.
  const [pools, setPools] = useState([]);

  // Header to be displayed in the table component
  const header = ["Schiff", "Bearbeiten"];

  // Headers for the table without the edit field.
  const headers = ["Schiff"];

  // Header and Bodys for the modals
  const modalTitleAddCorp = "Schiffe"; // Variable to display the edit modal
  const modalTitleDel = "Schiff löschen";
  const modalBodyDel = "Möchten Sie das Schiff wirklich löschen?";

  // The pool ship object which is passed to the PoolShipForm component.
  const [poolShip, setPoolShip] = useState({
    pool: props.pool,
    ship: { value: "", label: "" },
  });

  const getAllPoolShips = (filter) => {
    PoolShipDataService.getAll(filter).then((response) => {
      if (response.data.length !== 0) {
        // Holds the pool-ships items
        var ps = [];

        response.data.forEach((element) => {
          ps.push({
            ship: element.ship,
          });
        });

        setPools(ps);
      } else {
        setPools([]);
      }
    });
  };

  const { pool } = props;

  useEffect(() => {
    getAllPoolShips({ filter: pool.name });
  }, [pool]);

  const selectValuesFromObjects = (item) => {
    item.ship = item.ship.value;
  };

  const addPoolShips = (item) => {
    const data = { ...item };
    selectValuesFromObjects(data);
    console.log(data);
    PoolShipDataService.create(data)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Das Schiff mit der Nummer "' +
            data.ship.id +
            '" wurde erfolgreich hinzugefügt!',
          "success"
        );
        getAllPoolShips({ filter: pool.name });
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Das Schiff mit der Nummer "' +
            data.ship.id +
            '" konnte nicht hinzugefügt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  const resetPoolShip = () => {
    setPoolShip((prevPoolShip) => {
      return {
        ...prevPoolShip,
        ship: { value: "", label: "" },
      };
    });
  };

  // Function to handle the onSubmit for add and edit a hull corporation.
  const handleAddAndEdit = (e) => {
    console.log(e);
    addPoolShips(e);
    // Sets the HullCorp object to default to clear the input fields.
    resetPoolShip();
  };

  // Function to delete an specific pool-ship in the backend
  const deletePoolShip = () => {
    PoolShipDataService.delete(item.pool_name, item.ship_id)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Das Schiff mit der Nummer "' +
            item.ship_id +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllPoolShips({ filter: pool.name });
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Das Schiff mit der Nummer "' +
            item.ship_id +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to handle the delete of an specific entry
  const handleDelete = () => {
    // Call the methods which holds the backend deletion call.
    deletePoolShip();
    // Close the delete modal.
    setShowDel(false);
  };

  // The functions to open and close the delete modal
  const handleCloseDel = () => setShowDel(false);
  const handleShowDel = (datum) => {
    // If the delete modal opens create an object with the pool name
    // and the ship id to delete the right item.
    const item = {
      pool_name: props.pool.name,
      ship_id: datum.ship.id,
    };
    // Set the item;
    setItem(item);
    // Open the delete modal.
    setShowDel(true);
  };

  return (
    <>
      <Modal centered show={props.show} onHide={props.onClose}>
        <Modal.Header
          className={styles.modalHeader}
          closeVariant="white"
          closeButton
        >
          <Modal.Title>{modalTitleAddCorp}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4>Schiffe im Pool {pool.name}</h4>
          <PoolShipForm
            btnText={"Hinzufügen"}
            onSubmit={handleAddAndEdit}
            editData={poolShip}
          />
          <div className={styles.tableContainer}>
            <Table
              headers={headers}
              header={header}
              items={pools}
              attr={{ 0: "shipname" }}
              tableStyle="tableContainerModal"
              onDelete={handleShowDel}
              delete
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props.isEditing && (
            <Button variant="secondary" onClick={props.onBack}>
              Zurück
            </Button>
          )}
          <Button variant="secondary" onClick={props.onClose}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>

      <CustomDeleteModal
        modalTitle={modalTitleDel}
        modalBody={modalBodyDel}
        onClose={handleCloseDel}
        onDelete={handleDelete}
        onHide={handleCloseDel}
        show={showDel}
      />
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </>
  );
};

export default CustomPoolShipModal;
