import React, { useState, useEffect } from "react";
import LohDataService from "../../services/loh.data.service";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import styles from "./LohFranchiseForm.module.scss";
import { percentagePattern, pricePattern } from "../../patterns/patterns";
import CorporationDataService from "../../services/corporation.data.service";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";

const LohFranchiseForm = (props) => {
  // The data to be displayed in the textfields.
  const data = props.editData;

  const [loh, setLoh] = useState([]);
  const [corporation, setCorporation] = useState([]);
  const [ship, setShip] = useState([]);

  const getAllLohs = () => {
    LohDataService.getAll({ filter: "", year: "" })
      .then((response) => {
        let loh = [];
        response.data.forEach((element) => {
          loh.push({ value: element, label: element.policy_number });
        });
        setLoh(loh);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllLohs();
  }, []);

  // Function to get all corporations out of the database.
  const getAllCorporations = () => {
    CorporationDataService.getAll({ filter: "" })
      .then((response) => {
        let corporation = [];
        response.data.forEach((element) => {
          corporation.push({ value: element, label: element.name });
        });
        setCorporation(corporation);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllCorporations() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllCorporations();
  }, []);

  // Function to get all ships out of the databse.
  const getAllShips = () => {
    InsuredObjectDataService.getAll({ filter: "" })
      .then((response) => {
        console.log(response.data);
        let ships = [];
        response.data.forEach((element) => {
          ships.push({ value: element, label: element.shipname });
        });
        setShip(ships);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllShips() function thr first time this component
  // is rendered.
  useEffect(() => {
    getAllShips();
  }, []);

  // The object schema to validate the input fields.
  const LohFranchiseSchema = Yup.object().shape({
    daily_rate: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
    payment_method: Yup.string().nullable(),
    deductible: Yup.number()
      .required("Pflichtfeld")
      .min(0, "Die SB muss größer oder gleich 0 sein.")
      .max(1000, "Die SB darf den Wert von 1000 nicht überschreiten."),
    hull: Yup.number()
      .required("Pflichtfeld")
      .min(0, "Die Kasko muss größer oder gleich 0 sein.")
      .max(1000, "Die Kasko darf den Wert von 1000 nicht überschreiten."),
    machine: Yup.number()
      .required("Pflichtfeld")
      .min(0, "Die Maschine muss größer oder gleich 0 sein.")
      .max(1000, "Die Maschine darf den Wert von 1000 nicht überschreiten."),
    tax: Yup.string()
      .nullable()
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      ),
    insurance_start: Yup.date().required("Pflichtfeld"),
    brokerage: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      ),
    loh: Yup.object().nullable(),
    corporation: Yup.object().required("Pflichtfeld").nullable(),
    ship: Yup.object().required("Pflichtfeld").nullable(),
    insurance_period_from: Yup.date().required("Pflichtfeld"),
    insurance_period_to: Yup.date().required("Pflichtfeld"),
    fixed_premium: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
    fixed_premium_corp: Yup.string()
      .required("Pflichtfeld")
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      ),
  });

  const fillOutFieldsWithLohData = (loh, value, setFieldValue) => {
    if (loh.deductible !== null) {
      value.hull = loh.deductible;
      setFieldValue("hull", loh.deductible);
      value.machine = loh.deductible;
      setFieldValue("machine", loh.deductible);
    }
  };

  return (
    <>
      <Formik
        validationSchema={LohFranchiseSchema}
        onSubmit={(values) => {
          console.log(values.loh);
          const loh = values.loh;
          const ship = values.ship;
          const corporation = values.corporation;
          props.onSubmit(values);
          values.loh = loh;
          values.ship = ship;
          values.corporation = corporation;
        }}
        initialValues={{
          policy_number: data !== undefined ? data.policy_number : "",
          insurance_start: data !== undefined ? data.insurance_start : "",
          daily_rate: data !== undefined ? data.daily_rate : "",
          deductible: data !== undefined ? data.deductible : "",
          hull: data !== undefined ? data.hull : "",
          machine: data !== undefined ? data.machine : "",
          payment_method: data !== undefined ? data.payment_method : "",
          tax: data !== undefined && data.tax !== null ? data.tax : "",
          termination:
            data !== undefined && data.termination !== null
              ? data.termination
              : "",
          brokerage: data !== undefined ? data.brokerage : "",
          loh:
            data !== undefined && data.loh !== null
              ? { value: data.loh, label: data.loh.policy_number }
              : "",
          ship:
            data !== undefined
              ? { value: data.ship, label: data.ship.shipname }
              : "",
          corporation:
            data !== undefined
              ? { value: data.corporation, label: data.corporation.name }
              : "",
          insurance_period_from:
            data !== undefined ? data.insurance_period_from : "",
          insurance_period_to:
            data !== undefined ? data.insurance_period_to : "",
          fixed_premium: data !== undefined ? data.fixed_premium : "",
          fixed_premium_corp: data !== undefined ? data.fixed_premium_corp : "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridLoh">
                <Form.Label>Loh</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="loh"
                  name="loh"
                  placeholder="Wähle Loh..."
                  onChange={(selected) => {
                    if (selected !== null) {
                      console.log(selected);
                      LohDataService.get(selected.value.policy_number)
                        .then((response) => {
                          const loh = response.data;
                          values.ship = response.data.ship;
                          setFieldValue("ship", {
                            value: response.data.ship,
                            label: response.data.ship.shipname,
                          });
                          fillOutFieldsWithLohData(
                            loh,
                            selected.value,
                            setFieldValue
                          );
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                    values.loh = selected;
                    setFieldValue("loh", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() => setFieldTouched("loh", true, false)}
                  options={loh}
                  value={values.loh}
                  styles={chooseCustomSelectStyle(
                    errors.loh,
                    values.loh,
                    touched.loh
                  )}
                />
                {errors.loh && touched.loh ? (
                  <div className={styles["invalid-error"]}>{errors.loh}</div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridDailyRate">
                <Form.Label>Tagessatz</Form.Label>
                <Form.Control
                  name="daily_rate"
                  type="text"
                  placeholder="Tagessatz"
                  value={values.daily_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("daily_rate", true, false)}
                  isValid={touched.daily_rate && !errors.daily_rate}
                  isInvalid={!!errors.daily_rate && touched.daily_rate}
                />
                {errors.daily_rate && touched.daily_rate ? (
                  <div className={styles["invalid-error"]}>
                    {errors.daily_rate}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridFixedPremium">
                <Form.Label>Festprämie</Form.Label>
                <Form.Control
                  name="fixed_premium"
                  type="text"
                  placeholder="Festprämie"
                  value={values.fixed_premium}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("fixed_premium", true, false)}
                  isValid={touched.fixed_premium && !errors.fixed_premium}
                  isInvalid={!!errors.fixed_premium && touched.fixed_premium}
                />
                {errors.fixed_premium && touched.fixed_premium ? (
                  <div className={styles["invalid-error"]}>
                    {errors.fixed_premium}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridDailyRate">
                <Form.Label>Festprämie Gesell.</Form.Label>
                <Form.Control
                  name="fixed_premium_corp"
                  type="text"
                  placeholder="Festprämie Gesell."
                  value={values.fixed_premium_corp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("fixed_premium_corp", true, false)
                  }
                  isValid={
                    touched.fixed_premium_corp && !errors.fixed_premium_corp
                  }
                  isInvalid={
                    !!errors.fixed_premium_corp && touched.fixed_premium_corp
                  }
                />
                {errors.fixed_premium_corp && touched.fixed_premium_corp ? (
                  <div className={styles["invalid-error"]}>
                    {errors.fixed_premium_corp}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Versicherungsbeginn</Form.Label>
                <Form.Control
                  name="insurance_start"
                  type="date"
                  placeholder="Versicherungsbeginn"
                  value={values.insurance_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_start", true, false)
                  }
                  isValid={touched.insurance_start && !errors.insurance_start}
                  isInvalid={
                    !!errors.insurance_start && touched.insurance_start
                  }
                  disabled={props.isEditing}
                />
                {errors.insurance_start && touched.insurance_start ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_start}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPaymentMethod">
                <Form.Label>Zahlungsmethode</Form.Label>
                <Form.Select
                  name="payment_method"
                  value={values.payment_method}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.payment_method && !errors.payment_method}
                  isInvalid={!!errors.payment_method && touched.payment_method}
                >
                  <option hidden>Methode wählen...</option>
                  <option></option>
                  <option>1</option>
                  <option>1/2</option>
                  <option>1/3</option>
                  <option>1/4</option>
                  <option>1/6</option>
                  <option>1/12</option>
                </Form.Select>
                {errors.payment_method && touched.payment_method ? (
                  <div className={styles["invalid-error"]}>
                    {errors.payment_method}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridInsurancePeriodFrom">
                <Form.Label>Versicherungszeitraum von</Form.Label>
                <Form.Control
                  name="insurance_period_from"
                  type="date"
                  placeholder="Versicherungszeitraum von"
                  value={values.insurance_period_from}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_period_from", true, false)
                  }
                  isValid={
                    touched.insurance_period_from &&
                    !errors.insurance_period_from
                  }
                  isInvalid={
                    !!errors.insurance_period_from &&
                    touched.insurance_period_from
                  }
                />
                {errors.insurance_period_from &&
                touched.insurance_period_from ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_period_from}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridInsurancePeriodTo">
                <Form.Label>Versicherungszeitraum bis</Form.Label>
                <Form.Control
                  name="insurance_period_to"
                  type="date"
                  placeholder="Versicherungszeitraum bis"
                  value={values.insurance_period_to}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_period_to", true, false)
                  }
                  isValid={
                    touched.insurance_period_to && !errors.insurance_period_to
                  }
                  isInvalid={
                    !!errors.insurance_period_to && touched.insurance_period_to
                  }
                />
                {errors.insurance_period_to && touched.insurance_period_to ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_period_to}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridDeductible">
                <Form.Label>Selbstbeteiligung</Form.Label>
                <Form.Control
                  name="deductible"
                  type="number"
                  placeholder="Selbstbeteiligung"
                  value={values.deductible}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("deductible", true, false)}
                  isValid={touched.deductible && !errors.deductible}
                  isInvalid={!!errors.deductible && touched.deductible}
                />
                {errors.deductible && touched.deductible ? (
                  <div className={styles["invalid-error"]}>
                    {errors.deductible}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridHull">
                <Form.Label>Max.</Form.Label>
                <Form.Control
                  name="hull"
                  type="number"
                  placeholder="Max."
                  value={values.hull}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("hull", true, false)}
                  isValid={touched.hull && !errors.hull}
                  isInvalid={!!errors.hull && touched.hull}
                />
                {errors.hull && touched.hull ? (
                  <div className={styles["invalid-error"]}>{errors.hull}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridMachine">
                <Form.Label>Max. Jahr</Form.Label>
                <Form.Control
                  name="machine"
                  type="number"
                  placeholder="Max. Jahr"
                  value={values.machine}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("machine", true, false)}
                  isValid={touched.machine && !errors.machine}
                  isInvalid={!!errors.machine && touched.machine}
                />
                {errors.machine && touched.machine ? (
                  <div className={styles["invalid-error"]}>
                    {errors.machine}
                  </div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridTax">
                <Form.Label>Steuer</Form.Label>
                <Form.Control
                  name="tax"
                  type="text"
                  placeholder="Steuer"
                  value={values.tax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("tax", true, false)}
                  isValid={touched.tax && !errors.tax}
                  isInvalid={!!errors.tax && touched.tax}
                />
                {errors.tax && touched.tax ? (
                  <div className={styles["invalid-error"]}>{errors.tax}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="formGridCorporation">
                <Form.Label>Gesellschaft</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="corporation"
                  name="corporation"
                  placeholder="Wähle Gesellschaft..."
                  onChange={(selected) => {
                    values.corporation = selected;
                    setFieldValue("corporation", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() =>
                    setFieldTouched("corporation", true, false)
                  }
                  options={corporation}
                  value={values.corporation}
                  styles={chooseCustomSelectStyle(
                    errors.corporation,
                    values.corporation,
                    touched.corporation
                  )}
                />
                {errors.corporation && touched.corporation ? (
                  <div className={styles["invalid-error"]}>
                    {errors.corporation}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridShip">
                <Form.Label>Schiff</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="ship"
                  name="ship"
                  onChange={(selected) => {
                    values.ship = selected;
                    setFieldValue("ship", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() => setFieldTouched("ship", true, false)}
                  placeholder="Wähle ein Schiff..."
                  options={ship}
                  value={values.ship}
                  styles={chooseCustomSelectStyle(
                    errors.ship,
                    values.ship,
                    touched.ship
                  )}
                />
                {errors.ship && touched.ship ? (
                  <div className={styles["invalid-error"]}>{errors.ship}</div>
                ) : null}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridBrokerage">
                <Form.Label>Courtage</Form.Label>
                <Form.Control
                  name="brokerage"
                  type="text"
                  placeholder="Courtage"
                  value={values.brokerage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("brokerage", true, false)}
                  isValid={touched.brokerage && !errors.brokerage}
                  isInvalid={!!errors.brokerage && touched.brokerage}
                />
                {errors.brokerage && touched.brokerage ? (
                  <div className={styles["invalid-error"]}>
                    {errors.brokerage}
                  </div>
                ) : null}
              </Form.Group>
              {props.isEditing && (
                <Form.Group as={Col} controlId="formGridInsuranceStart">
                  <Form.Label>Kündigung zum</Form.Label>
                  <Form.Control
                    name="termination"
                    type="date"
                    placeholder="Kündigung zum"
                    value={values.termination}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onInput={() => setFieldTouched("termination", true, false)}
                    isValid={touched.termination && !errors.termination}
                    isInvalid={!!errors.termination && touched.termination}
                  />
                  {errors.termination && touched.termination ? (
                    <div className={styles["invalid-error"]}>
                      {errors.termination}
                    </div>
                  ) : null}
                </Form.Group>
              )}
            </Row>
            <div className={styles.submitBtn}>
              <SubmitButton
                className="submitBtn"
                variant="primary"
                type="submit"
              >
                {props.btnText}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LohFranchiseForm;
