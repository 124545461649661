import React, { useCallback, useEffect, useState } from "react";
import DamageDataService from "../../services/damage.data.service";
import Header from "../Header/Header";
import useAlert from "../../hooks/use-alert";
import CrudContent from "../CrudContent/CrudContent";
import GenericAlert from "../Alert/GenericAlert";
import DamageForm from "./DamageForm";
import { dateFormatterDE } from "../../formatter/date-formatter";

const Damage = () => {
  const [allDamages, setAllDamages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();

  const header = [
    "SF-Nummer",
    "VHT-Nummer",
    "Datum",
    "Art",
    "Schiff",
    "Bearbeiten",
  ];
  const damageHeaders = [
    "SF-Nummer:",
    "VHT-Nummer:",
    "Schadensdatum:",
    "Schadensart:",
    "Schiff:",
    "Infos:",
  ];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "Schaden löschen";
  const modalTitleEdit = "Schaden bearbeiten";
  const modalTitleAdd = "Schaden hinzufügen";
  const modalBody = "Möchten Sie den Schaden wirklich löschen?";

  const initialDamageObject = {
    sf_number: "",
    vht_number: "",
    damage_date: "",
    damage_type: "",
    ship: "",
    more_info: "",
  };

  // The data to be edited
  const [editData, setEditData] = useState(initialDamageObject);

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  const getAllDamages = useCallback(() => {
    DamageDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          let damageArray = [];
          response.data.forEach((element) => {
            damageArray.push({
              sf_number: element.sf_number,
              vht_number: element.vht_number,
              damage_date: element.damage_date,
              damage_type: element.damage_type,
              ship: element.ship,
              more_info: element.more_info,
            });
          });
          setAllDamages(damageArray);
        } else {
          setAllDamages([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    getAllDamages();
  }, [getAllDamages]);

  const deleteItem = (item) => {
    DamageDataService.delete(item.policy_number)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Der Schaden "' + item.sf_number + '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllDamages();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Der Schaden "' + item.sf_number + '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  const selectValuesFromObjects = (item) => {
    if (item.ship.value !== undefined) {
      item.ship = item.ship.value;
    }
  };

  // Function to edit a specific hull-franchise item.
  const editItem = (item) => {
    selectValuesFromObjects(item);
    console.log(item);
    DamageDataService.update(item.sf_number, item)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Der Schaden "' + item.sf_number + '" wurde erfolgreich bearbeitet!',
          "success"
        );
        getAllDamages();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Der Schaden "' +
            item.sf_number +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  const addItem = (item) => {
    selectValuesFromObjects(item);
    console.log(item);
    DamageDataService.create(item)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Der Schaden "' + item.sf_number + '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllDamages();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Der Schaden "' + item.sf_number + '" konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    console.log(item);
    setEditData(item);
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    // Get one specific attribute of the objects to be displayed.
    item.ship = item.ship.shipname;
    item.damage_date = dateFormatterDE(item.damage_date);
    // Create an array which holds multiple arrays each with
    // one key value pair separaeted by a comma.
    let damageArray = Object.entries(item);
    for (let i = 0; i < damageHeaders.length; i++) {
      // Replace all keys with the header value.
      damageArray[i][0] = damageHeaders[i];
      damageArray[i][2] = "";
    }
    console.log(damageArray);
    // Set the displayed data.
    setDisplayData({ array: damageArray });
  };

  const handleYearChange = (year) => {
    console.log(year);
    setFilter((prevFilter) => {
      return { ...prevFilter, year: year };
    });
  };

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="Schäden"
          items={allDamages}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <DamageForm editData={editData} btnText="Bearbeiten" isEditing />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Schäden..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<DamageForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 4: "shipname" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          edit
          onYearChange={handleYearChange}
          hasYearFilter
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};

export default Damage;
