import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class HullDataService {
  // Get all hulls from the database
  getAll(filter) {
    return axios.post(API_URL + "/hulls", filter, { headers: authHeader() });
  }

  // Create a new hull
  create(data) {
    return axios.post(API_URL + "/hull", data, { headers: authHeader() });
  }

  // Get just one hull
  get(id) {
    return axios.get(API_URL + `/hull/${id}`, { headers: authHeader() });
  }

  // Delete a specific hull out of the table.
  delete(id) {
    return axios.delete(API_URL + `/hull/${id}`, { headers: authHeader() });
  }

  // Update the data of one specific hull.
  update(id, data) {
    return axios.put(API_URL + `/hull/${id}`, data, {
      headers: authHeader(),
    });
  }

  getAllCurrent() {
    return axios.get(API_URL + "/hulls", { headers: authHeader() });
  }
}

export default new HullDataService();