import React, { useState, useEffect, useCallback } from "react";
import HullFranchiseDataService from "../../services/hull-franchise.data.service";
import HullFranchiseForm from "./HullFranchiseForm";
import Header from "../Header/Header";
import CrudContent from "../CrudContent/CrudContent";
import { dateFormatterDE } from "../../formatter/date-formatter";
import InvoiceForm from "../Invoice/InvoiceForm";
import InvoiceDataService from "../../services/invoice.data.service";
import GenericAlert from "../Alert/GenericAlert";
import useAlert from "../../hooks/use-alert";
import HullFranchiseChangeForm from "./HullFranchiseChangeForm";
import { checkIfItemIsStringAndReplaceComma } from "../../helper/helper";

// The headers to be displayed in the table.
export const hullFranchiseHeaders = [
  "Policennummer:",
  "Kasko:",
  "Festprämie:",
  "Festprämie Gesellschaft:",
  "Versicherungsbeginn:",
  "ZW",
  "Steuer 1:",
  "Courtage:",
  "Schiff:",
  "Gesellschaft:",
  "Kündigung zum:",
  "Versicherungsumme (KH):",
  "Prämie (KH):",
  "Selbstbeteiligung (KH):",
  "Versicherungsumme (MFF):",
  "Prämie (MFF):",
  "Selbstbeteiligung (MFF):",
  "Versicherungsumme (MSB):",
  "Prämie (MSB):",
  "Selbstbeteiligung (MSB):",
  "Versicherungsumme (TPL):",
  "Prämie (TPL):",
  "Selbstbeteiligung (TPL):",
  "51:",
  "Versicherungszeitraum von:",
  "Versicherungszeitraum bis:",
];

export const hullFranchiseSignArray = [
  "",
  "",
  "€",
  "€",
  "",
  "",
  "%",
  "%",
  "",
  "",
  "",
  "€",
  "€",
  "€",
  "€",
  "€",
  "€",
  "€",
  "€",
  "€",
  "€",
  "€",
  "€",
  "€",
  "",
  "",
];

const HullFranchise = () => {
  // The state which holds all hull-franchises.
  const [allHullFranchises, setAllHullFranchises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();
  const [dueDateIsDisabled, setDueDateIsDisabled] = useState(false);

  // The headers to be displayed in the table.
  const header = [
    "Policennummer",
    "Kasko",
    "Festprämie",
    "Festprämie G.",
    "Versicherungsbeginn",
    "ZW",
    "Steuer 1",
    "Courtage",
    "Schiff",
    "Gesellschaft",
    "Kündigung",
    "Bearbeiten",
  ];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "Kasko-Franchise löschen";
  const modalTitleEdit = "Kasko-Franchise bearbeiten";
  const modalTitleAdd = "Kasko-Franchise hinzufügen";
  const modalBody = "Möchten Sie die Kasko-Franchise wirklich löschen?";

  const initialHullFranchiseObject = {
    policy_number: "",
    hull: "",
    fixed_premium: "",
    fixed_premium_corp: "",
    insurance_start: "",
    payment_method: "",
    tax: "",
    brokerage: "",
    ship: "",
    corporation: "",
    termination: "",
    insurance_sum_one: "",
    premium_one: "",
    deductible_one: "",
    insurance_sum_two: "",
    premium_two: "",
    deductible_two: "",
    insurance_sum_three: "",
    premium_three: "",
    deductible_three: "",
    insurance_sum_four: "",
    premium_four: "",
    deductible_four: "",
    fifty_one_sum: "",
    insurance_period_from: "",
    insurance_period_to: "",
  };

  // The data to be edited
  const [editData, setEditData] = useState(initialHullFranchiseObject);

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  // Function to get all hull-franchises out of the database
  // based on the filtered value.
  const getAllHullFranchises = useCallback(() => {
    HullFranchiseDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          // Array which hold all loh-franchises
          var hullFran = [];
          response.data.forEach((element) => {
            hullFran.push({
              policy_number: element.policy_number,
              hull: element.hull,
              fixed_premium: element.fixed_premium.toString().replace(".", ","),
              fixed_premium_corp: element.fixed_premium_corp
                .toString()
                .replace(".", ","),
              insurance_start: element.insurance_start,
              payment_method: element.payment_method,
              tax: element.tax !== null ? element.tax.toString().replace(".", ",") : null,
              brokerage: element.brokerage.toString().replace(".", ","),
              ship: element.ship,
              corporation: element.corporation,
              termination: element.termination,
              insurance_sum_one:
                element.insurance_sum_one !== null
                  ? element.insurance_sum_one.toString().replace(".", ",")
                  : null,
              premium_one:
                element.premium_one !== null
                  ? element.premium_one.toString().replace(".", ",")
                  : null,
              deductible_one:
                element.deductible_one !== null
                  ? element.deductible_one.toString().replace(".", ",")
                  : null,
              insurance_sum_two:
                element.insurance_sum_two !== null
                  ? element.insurance_sum_two.toString().replace(".", ",")
                  : null,
              premium_two:
                element.premium_two !== null
                  ? element.premium_two.toString().replace(".", ",")
                  : null,
              deductible_two:
                element.deductible_two !== null
                  ? element.deductible_two.toString().replace(".", ",")
                  : null,
              insurance_sum_three:
                element.insurance_sum_three !== null
                  ? element.insurance_sum_three.toString().replace(".", ",")
                  : null,
              premium_three:
                element.premium_three !== null
                  ? element.premium_three.toString().replace(".", ",")
                  : null,
              deductible_three:
                element.deductible_three !== null
                  ? element.deductible_three.toString().replace(".", ",")
                  : null,
              insurance_sum_four:
                element.insurance_sum_four !== null
                  ? element.insurance_sum_four.toString().replace(".", ",")
                  : null,
              premium_four:
                element.premium_four !== null
                  ? element.premium_four.toString().replace(".", ",")
                  : null,
              deductible_four:
                element.deductible_four !== null
                  ? element.deductible_four.toString().replace(".", ",")
                  : null,
              fifty_one_sum:
                element.fifty_one_sum !== null
                  ? element.fifty_one_sum.toString().replace(".", ",")
                  : null,
              insurance_period_from: element.insurance_period_from,
              insurance_period_to: element.insurance_period_to,
            });
          });
          console.log(hullFran);
          setAllHullFranchises(hullFran);
        } else {
          setAllHullFranchises([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  // Calls the getAllHullFranchises() function every time the filter value changes
  useEffect(() => {
    getAllHullFranchises();
  }, [getAllHullFranchises]);

  const deleteItem = (item) => {
    HullFranchiseDataService.delete(item.policy_number)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die Kasko-Franchise-Versicherung "' +
            item.policy_number +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllHullFranchises();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Kasko-Franchise-Versicherung "' +
            item.policy_number +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Replace all commas with dots.
  // Must be done for all decimal values because
  // the backend only accepts data with a dot.
  const replaceComma = (item) => {
    item.tax = checkIfItemIsStringAndReplaceComma(item.tax);
    item.brokerage = checkIfItemIsStringAndReplaceComma(item.brokerage);
    item.insurance_sum_one = checkIfItemIsStringAndReplaceComma(item.insurance_sum_one);
    item.premium_one = checkIfItemIsStringAndReplaceComma(item.premium_one);
    item.deductible_one = checkIfItemIsStringAndReplaceComma(item.deductible_one);
    item.insurance_sum_two = checkIfItemIsStringAndReplaceComma(item.insurance_sum_two);
    item.premium_two = checkIfItemIsStringAndReplaceComma(item.premium_two);
    item.deductible_two = checkIfItemIsStringAndReplaceComma(item.deductible_two);
    item.insurance_sum_three = checkIfItemIsStringAndReplaceComma(item.insurance_sum_three);
    item.premium_three = checkIfItemIsStringAndReplaceComma(item.premium_three);
    item.deductible_three = checkIfItemIsStringAndReplaceComma(item.deductible_three);
    item.insurance_sum_four = checkIfItemIsStringAndReplaceComma(item.insurance_sum_four);
    item.premium_four = checkIfItemIsStringAndReplaceComma(item.premium_four);
    item.deductible_four = checkIfItemIsStringAndReplaceComma(item.deductible_four);
    item.fixed_premium = checkIfItemIsStringAndReplaceComma(item.fixed_premium);
    item.fixed_premium_corp = checkIfItemIsStringAndReplaceComma(item.fixed_premium_corp);
  };

  const selectValuesFromObjects = (item) => {
    if (item.policy_number.value !== undefined) {
      item.policy_number = item.policy_number.value;
    }
    if (item.ship.value !== undefined) {
      item.ship = item.ship.value;
    }
    if (item.corporation.value !== undefined) {
      item.corporation = item.corporation.value;
    }
    if (item.hull !== null && item.hull.value !== undefined) {
      item.hull = item.hull.value;
    }
  };

  // Function to edit a specific hull-franchise item.
  const editItem = (item) => {
    selectValuesFromObjects(item);
    console.log(item);
    replaceComma(item);
    HullFranchiseDataService.update(item.policy_number, item)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die Kasko-Franchise-Versicherung "' +
            item.policy_number +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        getAllHullFranchises();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Kasko-Franchise-Versicherung "' +
            item.policy_number +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  const addItem = (item) => {
    console.log(item)
    selectValuesFromObjects(item);
    console.log(item);
    replaceComma(item);
    HullFranchiseDataService.create(item)
      .then((response) => {
        let hullFranchise = response.data;
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die Kasko-Franchise-Versicherung "' +
            hullFranchise.policy_number +
            '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllHullFranchises();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Kasko-Franchise-Versicherung konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    console.log(item);
    setEditData(item);
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    // Get one specific attribute of the objects to be displayed.
    item.ship = item.ship.shipname;
    item.hull = item.hull.policy_number;
    item.corporation = item.corporation.name;
    item.insurance_start = dateFormatterDE(item.insurance_start);
    item.termination = dateFormatterDE(item.termination);
    item.insurance_period_from = dateFormatterDE(item.insurance_period_from);
    item.insurance_period_to = dateFormatterDE(item.insurance_period_to);
    // Create an array which holds multiple arrays each with
    // one key value pair separaeted by a comma.
    let hullArray = Object.entries(item);
    for (let i = 0; i < hullFranchiseHeaders.length; i++) {
      // Replace all keys with the header value.
      hullArray[i][0] = hullFranchiseHeaders[i];
      hullArray[i][2] = hullFranchiseSignArray[i];
    }
    console.log(hullArray);
    // Set the displayed data.
    setDisplayData({ array: hullArray });
  };

  const handleYearChange = (year) => {
    console.log(year);
    setFilter((prevFilter) => {
      return { ...prevFilter, year: year };
    });
  };

  const handleInvoiceCreation = (item) => {
    HullFranchiseDataService.get(item.div.policy_number)
      .then((response) => {
        const invoiceData = {
          hullFranchise: response.data,
          dueDatesList: item.dueDatesList,
        };
        console.log(invoiceData);
        InvoiceDataService.generateHullFranchiseInvoice(invoiceData)
          .then((response) => {
            handleAlertVisible(
              "Erfolgreiche Rechnungsgenerierung",
              "Die Kasko-Franchise-Rechnung wurde erfolgreich generiert!",
              "success"
            );
            console.log(response);
          })
          .catch((error) => {
            handleAlertVisible(
              "Fehler",
              "Die Kasko-Franchise-Rechnung konnte nicht generiert werden!",
              "danger"
            );
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setDisabled = (isDisabled) => {
    setDueDateIsDisabled(isDisabled);
  }

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="Kasko-Franchise"
          items={allHullFranchises}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <HullFranchiseForm
              editData={editData}
              btnText="Bearbeiten"
              isEditing
            />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Kasko-Franchisen..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<HullFranchiseForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 1: "policy_number", 8: "shipname", 9: "name" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          signArray={hullFranchiseSignArray}
          edit
          onYearChange={handleYearChange}
          hasYearFilter
          onInvoiceCreation={handleInvoiceCreation}
          invoice
          dueDatesForm={
            <InvoiceForm btnText="Manuelle Fälligkeiten anwenden" isDisabled={dueDateIsDisabled}/>
          }
          dueDatesIsDisabled={setDisabled}
          invoiceModalHeader="Kasko-Franchise Rechungsgenerierung"
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          changeDivision
          onChangeItem={addItem}
          formChange={<HullFranchiseChangeForm btnText="Hinzufügen"/>}
          changeTitle="Kasko-Franchise Änderung hinzufügen"
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};

export default HullFranchise;
