const customSelectStyleInvalid = {
    control: (base, state) => ({
      ...base,
      borderColor: "#dc3545",
      "&:hover": { borderColor: "#dc3545" },
      backgroundImage:
        'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e")',
      backgroundRepeat: "no-repeat",
      paddingRight: "calc(1.5em + 0.75rem)",
      backgroundPosition: "right calc(0.375em + 0.1875rem) center",
      backgroundSize: "calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)",
    }),
  };

  const customSelectStyleValid = {
    control: (base, state) => ({
      ...base,
      borderColor: "#198754",
      "&:hover": { borderColor: "#198754" },
      backgroundImage:
        'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e")',
      backgroundRepeat: "no-repeat",
      paddingRight: "calc(1.5em + 0.75rem)",
      backgroundPosition: "right calc(0.375em + 0.1875rem) center",
      backgroundSize: "calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)",
    }),
  };

  const customSelectStyleNormal = {
    control: (base, state) => ({
      ...base,
      borderColor: "hsl(0, 0%, 80%)"
    }),
  };

  export const chooseCustomSelectStyle = (error, value, touched) => {
    if (!error && !touched) {
      return customSelectStyleNormal;
    } 
      
    if (!!error && touched) {
        return customSelectStyleInvalid;
    }

    if (!error && touched && value !== "") {
      return customSelectStyleValid;
    }
  }