import React, { useState, useEffect } from "react";
import HullDataService from "../../services/hull.data.service";
import styles from "./InterestForm.module.scss";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  percentagePattern,
  pricePattern,
  percentagePatternPremium,
} from "../../patterns/patterns";
import CorporationDataService from "../../services/corporation.data.service";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";

const InterestForm = (props) => {
  const data = props.editData;
  console.log(data);

  // The states for select fileds (corporation, hull)
  const [corporation, setCorporation] = useState([]);
  const [hull, setHull] = useState([]);
  const [ship, setShip] = useState([]);

  // Function to get all ships out of the database.
  const getAllHulls = () => {
    HullDataService.getAll({ filter: "", year: "" })
      .then((response) => {
        let hull = [];
        response.data.forEach((element) => {
          hull.push({ value: element, label: element.policy_number });
        });
        setHull(hull);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllHulls() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllHulls();
  }, []);

  // Function to get all corporations out of the database.
  const getAllCorporations = () => {
    CorporationDataService.getAll({ filter: "" })
      .then((response) => {
        let corporation = [];
        response.data.forEach((element) => {
          corporation.push({ value: element, label: element.name });
        });
        setCorporation(corporation);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllCorporations() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllCorporations();
  }, []);

  // Function to get all ships out of the database.
  const getAllShips = () => {
    InsuredObjectDataService.getAll({ filter: "" })
      .then((response) => {
        console.log(response.data);
        let ships = [];
        response.data.forEach((element) => {
          ships.push({ value: element, label: element.shipname });
        });
        setShip(ships);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllShips() function thr first time this component
  // is rendered.
  useEffect(() => {
    getAllShips();
  }, []);

  const InterestObjectSchema = Yup.object().shape({
    insurance_sum: Yup.string()
      .matches(
        pricePattern,
        "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
    insurance_start: Yup.date().required("Pflichtfeld"),
    premium_rate: Yup.string()
      .required("Pflichtfeld")
      .matches(
        percentagePatternPremium,
        "Mit Komma trennen und maximal 4 Nachkommstellen"
      ),
    tax: Yup.string()
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      )
      .nullable(),
    payment_method: Yup.string().nullable(),
    brokerage: Yup.string()
      .matches(
        percentagePattern,
        "Mit Komma trennen maximal 2 Nachkommastellen"
      )
      .required("Pflichtfeld"),
    hull: Yup.object().nullable(),
    corporation: Yup.object().required("Pflichtfeld").nullable(),
    ship: Yup.object().required("Pflichtfeld").nullable(),
    premium_rate_corp: Yup.string()
      .matches(
        percentagePatternPremium,
        "Mit Komma trennen und maximal 4 Nachkommstellen"
      )
      .required("Pflichtfeld!"),
    insurance_period_from: Yup.date().required("Pflichtfeld"),
    insurance_period_to: Yup.date().required("Pflichtfeld"),
  });

  return (
    <Formik
      validationSchema={InterestObjectSchema}
      onSubmit={(values) => {
        const hull = values.hull;
        const corporation = values.corporation;
        const ship = values.ship;
        console.log(values);
        props.onSubmit(values);
        values.corporation = corporation;
        values.ship = ship;
        values.hull = hull;
      }}
      initialValues={{
        policy_number: data !== undefined ? data.policy_number : "",
        insurance_sum: data !== undefined ? data.insurance_sum : "",
        premium: data !== undefined ? data.premium : "",
        premium_rate: data !== undefined ? data.premium_rate : "",
        payment_method: data !== undefined ? data.payment_method : "",
        tax: data !== undefined && data.tax !== null ? data.tax : "",
        insurance_start: data !== undefined ? data.insurance_start : "",
        brokerage: data !== undefined ? data.brokerage : "",
        hull:
          data !== undefined && data.hull !== null
            ? { value: data.hull, label: data.hull.policy_number }
            : "",
        termination:
          data !== undefined && data.termination !== null
            ? data.termination
            : "",
        corporation:
          data !== undefined
            ? { value: data.corporation, label: data.corporation.name }
            : "",
        ship:
          data !== undefined
            ? { value: data.ship, label: data.ship.shipname }
            : "",
        insurance_period_from:
          data !== undefined ? data.insurance_period_from : "",
        insurance_period_to: data !== undefined ? data.insurance_period_to : "",
        premium_rate_corp: data !== undefined ? data.premium_rate_corp : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="formGridHull">
              <Form.Label>Kasko</Form.Label>
              <Select
                isClearable
                isSearchable
                id="hull"
                name="hull"
                placeholder="Wähle Kasko..."
                onChange={(selected) => {
                  if (selected !== null) {
                    HullDataService.get(selected.value.policy_number)
                      .then((response) => {
                        values.ship = response.data.ship;
                        setFieldValue("ship", {
                          value: response.data.ship,
                          label: response.data.ship.shipname,
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  values.hull = selected;
                  setFieldValue("hull", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() => setFieldTouched("hull", true, false)}
                options={hull}
                value={values.hull}
                styles={chooseCustomSelectStyle(
                  errors.hull,
                  values.hull,
                  touched.hull
                )}
              />
              {errors.hull && touched.hull ? (
                <div className={styles["invalid-error"]}>{errors.hull}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceStart">
              <Form.Label>Versicherungsbeginn</Form.Label>
              <Form.Control
                name="insurance_start"
                type="date"
                placeholder="Versicherungsbeginn"
                value={values.insurance_start}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("insurance_start", true, false)}
                isValid={touched.insurance_start && !errors.insurance_start}
                isInvalid={!!errors.insurance_start && touched.insurance_start}
                disabled={props.isEditing}
              />
              {errors.insurance_start && touched.insurance_start ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_start}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPaymentMethod">
              <Form.Label>Zahlungsmethode</Form.Label>
              <Form.Select
                name="payment_method"
                value={values.payment_method}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.payment_method && !errors.payment_method}
                isInvalid={!!errors.payment_method && touched.payment_method}
              >
                <option hidden>Methode wählen...</option>
                <option></option>
                <option>1</option>
                <option>1/2</option>
                <option>1/3</option>
                <option>1/4</option>
                <option>1/6</option>
                <option>1/12</option>
              </Form.Select>
              {errors.payment_method && touched.payment_method ? (
                <div className={styles["invalid-error"]}>
                  {errors.payment_method}
                </div>
              ) : null}
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsurancePeriodFrom">
              <Form.Label>Versicherungszeitraum von</Form.Label>
              <Form.Control
                name="insurance_period_from"
                type="date"
                placeholder="Versicherungszeitraum von"
                value={values.insurance_period_from}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("insurance_period_from", true, false)
                }
                isValid={
                  touched.insurance_period_from && !errors.insurance_period_from
                }
                isInvalid={
                  !!errors.insurance_period_from &&
                  touched.insurance_period_from
                }
              />
              {errors.insurance_period_from && touched.insurance_period_from ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_period_from}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridInsurancePeriodTo">
              <Form.Label>Versicherungszeitraum bis</Form.Label>
              <Form.Control
                name="insurance_period_to"
                type="date"
                placeholder="Versicherungszeitraum bis"
                value={values.insurance_period_to}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("insurance_period_to", true, false)
                }
                isValid={
                  touched.insurance_period_to && !errors.insurance_period_to
                }
                isInvalid={
                  !!errors.insurance_period_to && touched.insurance_period_to
                }
              />
              {errors.insurance_period_to && touched.insurance_period_to ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_period_to}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridInsuranceSum">
              <Form.Label>Versicherungssumme</Form.Label>
              <Form.Control
                name="insurance_sum"
                type="text"
                placeholder="Versicherungssume"
                value={values.insurance_sum}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("insurance_sum", true, false)}
                isValid={touched.insurance_sum && !errors.insurance_sum}
                isInvalid={!!errors.insurance_sum && touched.insurance_sum}
              />
              {errors.insurance_sum && touched.insurance_sum ? (
                <div className={styles["invalid-error"]}>
                  {errors.insurance_sum}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPremiumRate">
              <Form.Label>Prämiensatz</Form.Label>
              <Form.Control
                name="premium_rate"
                type="text"
                placeholder="Prämiensatz"
                value={values.premium_rate}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("premium_rate", true, false)}
                isValid={touched.premium_rate && !errors.premium_rate}
                isInvalid={!!errors.premium_rate && touched.premium_rate}
              />
              {errors.premium_rate && touched.premium_rate ? (
                <div className={styles["invalid-error"]}>
                  {errors.premium_rate}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridTaxTwo">
              <Form.Label>Steuer</Form.Label>
              <Form.Control
                name="tax"
                type="text"
                placeholder="Steuer"
                value={values.tax}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("tax", true, false)}
                isValid={touched.tax && !errors.tax}
                isInvalid={!!errors.tax && touched.tax}
              />
              {errors.tax && touched.tax ? (
                <div className={styles["invalid-error"]}>{errors.tax}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridBrokerage">
              <Form.Label>Courtage</Form.Label>
              <Form.Control
                name="brokerage"
                type="text"
                placeholder="Courtage"
                value={values.brokerage}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("brokerage", true, false)}
                isValid={touched.brokerage && !errors.brokerage}
                isInvalid={!!errors.brokerage && touched.brokerage}
              />
              {errors.brokerage && touched.brokerage ? (
                <div className={styles["invalid-error"]}>
                  {errors.brokerage}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridShip">
              <Form.Label>Schiff</Form.Label>
              <Select
                isClearable
                isSearchable
                id="ship"
                name="ship"
                onChange={(selected) => {
                  values.ship = selected;
                  setFieldValue("ship", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() => setFieldTouched("ship", true, false)}
                placeholder="Wähle ein Schiff..."
                options={ship}
                value={values.ship}
                styles={chooseCustomSelectStyle(
                  errors.ship,
                  values.ship,
                  touched.ship
                )}
              />
              {errors.ship && touched.ship ? (
                <div className={styles["invalid-error"]}>{errors.ship}</div>
              ) : null}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridCorporation">
              <Form.Label>Gesellschaft</Form.Label>
              <Select
                isClearable
                isSearchable
                id="corporation"
                name="corporation"
                placeholder="Wähle Gesellschaft..."
                onChange={(selected) => {
                  values.corporation = selected;
                  setFieldValue("corporation", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() => setFieldTouched("corporation", true, false)}
                options={corporation}
                value={values.corporation}
                styles={chooseCustomSelectStyle(
                  errors.corporation,
                  values.corporation,
                  touched.corporation
                )}
              />
              {errors.corporation && touched.corporation ? (
                <div className={styles["invalid-error"]}>
                  {errors.corporation}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridShare">
              <Form.Label>Prämiensatz Gesell.</Form.Label>
              <Form.Control
                name="premium_rate_corp"
                type="text"
                placeholder="Prämiensatz Gesell."
                value={values.premium_rate_corp}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("premium_rate_corp", true, false)
                }
                isValid={touched.premium_rate_corp && !errors.premium_rate_corp}
                isInvalid={
                  !!errors.premium_rate_corp && touched.premium_rate_corp
                }
              />
              {errors.premium_rate_corp && touched.premium_rate_corp ? (
                <div className={styles["invalid-error"]}>
                  {errors.premium_rate_corp}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row>
            {props.isEditing && (
              <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Kündigung zum</Form.Label>
                <Form.Control
                  name="termination"
                  type="date"
                  placeholder="Kündigung zum"
                  value={values.termination}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() => setFieldTouched("termination", true, false)}
                  isValid={touched.termination && !errors.termination}
                  isInvalid={!!errors.termination && touched.termination}
                />
                {errors.termination && touched.termination ? (
                  <div className={styles["invalid-error"]}>
                    {errors.termination}
                  </div>
                ) : null}
              </Form.Group>
            )}
          </Row>
          <div className={styles.submitBtn}>
            <SubmitButton variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InterestForm;
