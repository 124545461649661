import React, { useState } from "react";
import styles from "./InvoiceForm.module.scss";
import { Form, Col, Row, Button } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";

const InvoiceForm = (props) => {
  const [formFields, setFormFields] = useState([
    { dueDateFrom: "", dueDateTo: "" }
  ]);

  const addFieldRow = () => {
    let newField = { dueDateFrom: '', dueDateTo: '' }
    setFormFields([...formFields, newField])
  };

  const removeFieldRow = (i) => {
    let newField = [...formFields]
    newField.splice(i, 1)
    setFormFields(newField)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formFields);
    props.onSubmit(formFields);
  };

  const handleFormChange = (index, event) => {
    let data = [...formFields];
    console.log(data)
    data[index][event.target.name] = event.target.value;
    console.log(data)
    setFormFields(data);
  };

  return (
      <>
        <Form noValidate onSubmit={handleSubmit}>
            {formFields.map((value, index) => {
            return (
            <div key={index}>
                <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridDueDateFrom">
                  <Form.Label>Fälligkeitszeitraum von</Form.Label>
                  <Form.Control
                    name="dueDateFrom"
                    type="date"
                    placeholder="Fälligkeitszeitraum von"
                    value={value.dueDateFrom}
                    onChange={event => handleFormChange(index, event)}
                  />
                </Form.Group>
    
                <Form.Group as={Col} controlId="formGridDueDateTo">
                  <Form.Label>Fälligkeitszeitraum bis</Form.Label>
                  <Form.Control
                    name="dueDateTo"
                    type="date"
                    placeholder="Fälligkeitszeitraum bis"
                    value={value.dueDateTo}
                    onChange={event => handleFormChange(index, event)}
                  />
                </Form.Group>
              </Row>
              
              </div>
            )})}
            
          <div className={styles.submitBtn}>
            <SubmitButton variant="primary" type="submit" disabled={props.isDisabled}>
              {props.btnText}
            </SubmitButton>
          </div>
        </Form>
        <Button onClick={removeFieldRow} className={`${styles.btn} ${styles.margin}`} disabled={props.isDisabled}>-</Button>
        <Button onClick={addFieldRow} className={styles.btn} disabled={props.isDisabled}>+</Button>
    </>
  );
};

export default InvoiceForm;
