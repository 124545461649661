import React, { useState, useEffect, useCallback } from "react";
import PAndIFranchiseDataService from "../../services/p-and-i-franchise.data.service";
import PAndIDataService from "../../services/p-and-i.data.service";
import CrudContentPAndI from "../CrudContent/CrudContentPAndI";
import PAndIForm from "./PAndIForm";
import Header from "../Header/Header";
import { dateFormatterDE } from "../../formatter/date-formatter";
import InvoiceDataService from "../../services/invoice.data.service";
import InvoiceForm from "../Invoice/InvoiceForm";
import useAlert from "../../hooks/use-alert";
import GenericAlert from "../Alert/GenericAlert";
import { checkIfItemIsStringAndReplaceComma } from "../../helper/helper";
import PAndIChangeForm from "./PAndIChangeForm";

// Header to be displayed in the table
export const pAndIHeaders = [
  "Policennummer:",
  "Festprämie:",
  "Festprämie Gesellschaft:",
  "Beginn:",
  "ZW:",
  "Steuer 1:",
  "Courtage:",
  "Gesellschaft:",
  "Schiff:",
  "Versicherungssteuerfrei:",
  "Kündigung:",
  "51:",
  "Versicherungszeitraum von:",
  "Versicherungszeitraum bis:"
];

// Array to set currency or percentage sign after specific values.
export const pAndISignArray = [
  "",
  "€",
  "€",
  "",
  "",
  "%",
  "%",
  "",
  "",
  "",
  "",
  "€",
  "",
  "",
];

const PAndI = () => {
  console.log("pandi")
  // The state which holdes all P&Is
  const [allPAndIs, setAllPAndIs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();
  const [dueDateIsDisabled, setDueDateIsDisabled] = useState(false);

  // Header to be displayed in the table
  const header = [
    "Policennummer",
    "Festprämie",
    "Festprämie G.",
    "Beginn",
    "ZW",
    "Steuer 1",
    "Courtage",
    "Gesellschaft",
    "Schiff",
    "VS frei",
    "Kündigung",
    "Bearbeiten",
  ];

  const modalHeader = ["Franchise", "Höhe"];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "P&I löschen";
  const modalTitleEdit = "P&I bearbeiten";
  const modalTitleAdd = "P&I hinzufügen";
  const modalBody = "Möchten Sie die P&I wirklich löschen?";

  const initialPAndIObject = {
    policy_number: "",
    fixed_premium: "",
    fixed_premium_corp: "",
    insurance_start: "",
    payment_method: "",
    tax: "",
    brokerage: "",
    corporation: "",
    ship: "",
    tax_free: "",
    termination: "",
    fifty_one_sum: "",
    insurance_period_from: "",
    insurance_period_to: "",
  };

  const [pAndI, setPAndI] = useState({});

  // The data to be edited
  const [editData, setEditData] = useState(initialPAndIObject);

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  // Function to get all P&Is out of the database.
  const getAllPAndIs = useCallback(() => {
    PAndIDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          // Array which holds all P&Is.
          var pAnI = [];
          response.data.forEach((element) => {
            pAnI.push({
              policy_number: element.policy_number,
              fixed_premium: element.fixed_premium.toString().replace(".", ","),
              fixed_premium_corp: element.fixed_premium_corp
                .toString()
                .replace(".", ","),
              insurance_start: element.insurance_start,
              payment_method: element.payment_method,
              tax: element.tax !== null ? element.tax.toString().replace(".", ",") : null,
              brokerage: element.brokerage.toString().replace(".", ","),
              corporation: element.corporation,
              ship: element.ship,
              tax_free: element.tax_free,
              termination: element.termination,
              fifty_one_sum:
                element.fifty_one_sum !== null
                  ? element.fifty_one_sum.toString().replace(".", ",")
                  : null,
              insurance_period_from: element.insurance_period_from,
              insurance_period_to: element.insurance_period_to,
            });
          });
          setAllPAndIs(pAnI);
        } else {
          setAllPAndIs([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  // Call getAllPAndIs() everytime the filter value changes.
  useEffect(() => {
    getAllPAndIs();
  }, [getAllPAndIs]);

  // Function to delete an P&I object out of the database.
  // Accepts an P&I object to get its policynumber.
  const deleteItem = (item) => {
    PAndIDataService.delete(item.policy_number)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die P & I-Versicherung "' +
            item.policy_number +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllPAndIs();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die P & I-Versicherung "' +
            item.policy_number +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Replace all commas with dots.
  // Must be done for all decimals values because
  // the backend only accepts dara with a dot.
  const replaceComma = (item) => {
    item.fixed_premium = checkIfItemIsStringAndReplaceComma(item.fixed_premium);
    item.fixed_premium_corp = checkIfItemIsStringAndReplaceComma(
      item.fixed_premium_corp
    );
    item.tax = checkIfItemIsStringAndReplaceComma(item.tax);
    item.brokerage = checkIfItemIsStringAndReplaceComma(item.brokerage);
  };

  const selectValuesFromObjects = (item) => {
    if (item.policy_number.value !== undefined) {
      item.policy_number = item.policy_number.value;
    }
    if (item.ship.value !== undefined) {
      item.ship = item.ship.value;
    }
    if (item.corporation.value !== undefined) {
      item.corporation = item.corporation.value;
    }
  };

  // Function to edit a specific P&I item.
  const editItem = (item) => {
    replaceComma(item);
    selectValuesFromObjects(item);
    PAndIDataService.update(item.policy_number, item)
      .then((response) => {
        let pAndIData = response.data;
        setPAndI(pAndIData);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die P & I-Versicherung "' +
            item.policy_number +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        setEditData(item);
        getAllPAndIs();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die P & I-Versicherung "' +
            item.policy_number +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to create a new P&I item in the database.
  const addItem = (item) => {
    console.log(item);
    replaceComma(item);
    selectValuesFromObjects(item);
    PAndIDataService.create(item)
      .then((response) => {
        let pAndIData = response.data;
        setPAndI(pAndIData);
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die P & I-Versicherung "' +
            pAndIData.policy_number +
            '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllPAndIs();
        setPAndI(pAndIData);
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die P & I-Versicherung konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    const data = { ...item };
    console.log(data);
    setEditData(data);
  };

  const getAllFranchises = (item) => {
    PAndIFranchiseDataService.getAll({ filter: item.policy_number })
      .then((response) => {
        if (response.data.length !== 0) {
          // Array which holds the specific franchises.
          var franch = [];
          response.data.forEach((element) => {
            franch.push({
              franchise: element.franchise,
              amount: element.amount,
              pAndI: element.pAndI,
            });
          });
          createDisplayDataItem(franch, item);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to create the display data item.
  const createDisplayDataItem = (pAndIFranchise, item) => {
    // Create an array which holds multiple arrays each with
    // one key value pair separated by a comma.
    let franchArray = Object.entries(item);
    for (let i = 0; i < pAndIHeaders.length; i++) {
      // Replace all keys with the header value.
      franchArray[i][0] = pAndIHeaders[i];
      franchArray[i][2] = pAndISignArray[i];
    }
    console.log(franchArray);
    // Set the displayed data.
    setDisplayData({
      array: franchArray,
      tableData: pAndIFranchise,
    });
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    // Only get one attribute of the ship object.
    const item = { ...data };
    item.corporation = item.corporation.name;
    item.ship = item.ship.shipname;
    item.insurance_start = dateFormatterDE(item.insurance_start);
    item.termination = dateFormatterDE(item.termination);
    item.insurance_period_from = dateFormatterDE(item.insurance_period_from);
    item.insurance_period_to = dateFormatterDE(item.insurance_period_to);
    console.log(item.policy_number);

    getAllFranchises(item);
  };

  const handleYearChange = (year) => {
    console.log(year);
    setFilter((prevFilter) => {
      return { ...prevFilter, year: year };
    });
  };

  const handleInvoiceCreation = (item) => {
    PAndIDataService.get(item.div.policy_number)
      .then((response) => {
        const invoiceData = {
          pAndI: response.data,
          dueDatesList: item.dueDatesList,
        };
        console.log(invoiceData);
        InvoiceDataService.generatePAndIInvoice(invoiceData)
          .then((response) => {
            handleAlertVisible(
              "Erfolgreiche Rechnungsgenerierung",
              "Die P & I-Rechnung wurde erfolgreich generiert!",
              "success"
            );
            console.log(response);
          })
          .catch((error) => {
            handleAlertVisible(
              "Fehler",
              "Die P & I-Rechnung konnte nicht generiert werden!",
              "danger"
            );
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setDisabled = (isDisabled) => {
    setDueDateIsDisabled(isDisabled);
  }

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContentPAndI
          heading="P & I"
          items={allPAndIs}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <PAndIForm
              editData={editData}
              btnText="Bearbeiten"
              isEditing={true}
            />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter P & I..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<PAndIForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 7: "name", 8: "shipname" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo={true}
          signArray={pAndISignArray}
          edit={true}
          modalHeader={modalHeader}
          modalHeaders={modalHeader}
          onYearChange={handleYearChange}
          hasYearFilter={true}
          onInvoiceCreation={handleInvoiceCreation}
          invoice
          dueDatesForm={
            <InvoiceForm btnText="Manuelle Fälligkeiten anwenden" isDisabled={dueDateIsDisabled} />
          }
          dueDatesIsDisabled={setDisabled}
          invoiceModalHeader="P & I Rechungsgenerierung"
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          changeDivision
          onChangeItem={addItem}
          formChange={<PAndIChangeForm btnText="Hinzufügen"/>}
          pAndI={pAndI}
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};

export default PAndI;
