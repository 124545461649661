import React, { useState } from "react";
import CustomPageOneModal from "../CustomPageOneModal";
import CustomPoolShipModal from "./CustomPoolShipModal";

const PoolMultiModal = (props) => {
  const [count, setCount] = useState(1);

  // Function to increase the count by one
  // Switch to modal two.
  const handleNext = () => {
    setCount((curCount) => {
      return curCount + 1;
    });
  };

  // Decrease the count by one to go back to modal one.
  const handleBack = () => {
    setCount((curCount) => {
      return curCount - 1;
    });
  };

  // Function to close both modals.
  // Closing is only in the second modal possible.
  // Therefor the count needs to be decreased by one.
  const handleClose = () => {
    if (count > 1) {
      handleBack();
    }
    props.onHide();
  };

  console.log(count);

  return (
    <>
      {count === 1 && (
        <CustomPageOneModal
          onNext={handleNext}
          onSubmit={props.onSubmit}
          form={props.form}
          show={props.show}
          modalTitle={props.modalTitle}
          preventNext={props.preventNext}
          isEditing={props.isEditing}
          onClose={handleClose}
          closable
        />
      )}
      {count === 2 && (
          <CustomPoolShipModal
            onBack={handleBack}
            onClose={handleClose}
            show={props.showShip}
            onSubmit={props.onSubmitShip}
            modalTitle={props.modalTitleShip}
            pool={props.pool}
            onDelete={props.onDelete}
            isEditing={props.isEditing}
          />
        )}
    </>
  );
};

export default PoolMultiModal;
