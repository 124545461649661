import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class DivisionDataService {
  getAll() {
    return axios.get(API_URL + "/divisions", {
      headers: authHeader(),
    });
  }
}

export default new DivisionDataService();