import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class LohDataService {
  // Get all lohs from the database
  getAll(filter) {
    return axios.post(API_URL + "/lohs", filter, { headers: authHeader() });
  }

  // Create a new loh
  create(data) {
    return axios.post(API_URL + "/loh", data, { headers: authHeader() });
  }

  // Get just one loh
  get(id) {
    return axios.get(API_URL + `/loh/${id}`, { headers: authHeader() });
  }

  // Delete a specific loh out of the table.
  delete(id) {
    return axios.delete(API_URL + `/loh/${id}`, { headers: authHeader() });
  }

  // Update the data of one specific loh.
  update(id, data) {
    return axios.put(API_URL + `/loh/${id}`, data, {
      headers: authHeader(),
    });
  }

  getAllCurrent() {
    return axios.get(API_URL + "/lohs", { headers: authHeader() });
  }
}

export default new LohDataService();