import React, { useState, useEffect, useCallback } from "react";
//import UserService from "../services/user.service";
import Header from "../Header/Header";
//import styles from "./Policyholder.module.scss"
import CrudContent from "../CrudContent/CrudContent";
import PolicyholderDataService from "../../services/policyholder.data.service";
import PolicyholderForm from "./PolicyholderForm";
import useAlert from "../../hooks/use-alert";
import GenericAlert from "../Alert/GenericAlert";

const Policyholder = () => {
  // Variable which holds all policyholders which are displayed in the table
  const [allPolicyholders, setAllPolicyholders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();

  // The header of the table
  const header = [
    "Kundennr.",
    "VN",
    "Wohnort",
    "Plz",
    "Adresse",
    "E-Mail",
    "Mobilnummer",
    "Telefonnummer",
    "Fax",
    "Bearbeiten",
  ];
  // Data for the different modal (edit, delete, add)
  const modalTitle = "Kunden löschen";
  const modalTitleEdit = "Kunden bearbeiten";
  const modalTitleAdd = "Kunden hinzufügen";
  const modalBody = "Möchten Sie den Kunden wirklich löschen?";

  // The data to be edited
  const [editData, setEditData] = useState({});
  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });
  // The filter value
  const [filter, setFilter] = useState({ filter: "" });
  // Count of header items that need to be skipped
  const skip = 1;
  // Function to get all policyholders
  const getAllPolicyholder = useCallback(() => {
    setIsUnauthorized(false);
    PolicyholderDataService.getAll(filter)
      .then((response) => {
        //console.log(response.data);
        if (response.data.length !== 0) {
          // Array which holds all policyholders
          var ph = [];
          response.data.forEach((element) => {
            ph.push({
              id: element.id,
              name: element.name,
              postcode: element.postcode,
              place: element.place,
              address: element.address,
              email: element.email,
              mobilnumber: element.mobilnumber,
              telnumber: element.telnumber,
              fax: element.fax,
            });
          });
          //console.log(response.data)
          // Set all policyholders to the array created above.
          setAllPolicyholders(ph);
        } else {
          // Set all policyholders to its default value.
          setAllPolicyholders([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  // useEffect Hook to adjust the list every time the filter value changes
  useEffect(() => {
    getAllPolicyholder();
  }, [getAllPolicyholder]);

  // Function to delete a specific item
  const deleteItem = (item) => {
    PolicyholderDataService.delete(item.id)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Der Versicherungnehmer mit der Nummer "' +
            item.id +
            '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllPolicyholder();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Der Versicherungnehmer mit der Nummer "' +
            item.id +
            '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to edit a specific item
  const editItem = (item) => {
    PolicyholderDataService.update(item.id, item)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Der Versicherungnehmer mit der Nummer "' +
            item.id +
            '" wurde erfolgreich bearbeitet!',
          "success"
        );
        getAllPolicyholder();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Der Versicherungnehmer mit der Nummer "' +
            item.id +
            '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to add a new item to the table
  const addItem = (item) => {
    PolicyholderDataService.create(item)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Der Versicherungnehmer mit der Nummer "' +
            item.id +
            '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllPolicyholder();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Der Versicherungnehmer mit der Nummer "' +
            item.id +
            '" konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function which sets the state of the filteres value.
  const handleFilter = (filterValue) => {
    setFilter(filterValue);
  };

  // Function to set the edit data.
  const handleEditItem = (item) => {
    console.log(item);
    setEditData(item);
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    const item = { ...data };
    // Create an array which holds multiple arrays each with
    // one key value pair separated by a comma.
    let shipArray = Object.entries(item);
    for (let i = 0; i < header.length - 1; i++) {
      shipArray[i][0] = header[i] + ": ";
    }
    console.log(shipArray);
    // Set the displayed data.
    setDisplayData({ array: shipArray });
  };

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="Versicherungsnehmer"
          items={allPolicyholders}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <PolicyholderForm
              editData={editData}
              btnText="Bearbeiten"
              isDisabled={true}
            />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Kundenkartei..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<PolicyholderForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo={true}
          edit={true}
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
        />
        <GenericAlert
          show={show}
          alertVariant={alertContent.variant}
          alertHeading={alertContent.heading}
          alertBody={alertContent.message}
        />
      </div>
    </main>
  );
};
export default Policyholder;
