import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class PAndIDataService {
  // Get all P&Is from the database
  getAll(filter) {
    return axios.post(API_URL + "/p-and-is", filter, {
      headers: authHeader(),
    });
  }

  // Create a new P&I
  create(data) {
    return axios.post(API_URL + "/p-and-i", data, { headers: authHeader() });
  }

  // Get just one P&I
  get(id) {
    return axios.get(API_URL + `/p-and-i/${id}`, { headers: authHeader() });
  }

  // Delete a specific P&I out of the table.
  delete(id) {
    return axios.delete(API_URL + `/p-and-i/${id}`, { headers: authHeader() });
  }

  // Update the data of one specific P&I.
  update(id, data) {
    return axios.put(API_URL + `/p-and-i/${id}`, data, {
      headers: authHeader(),
    });
  }

  getAllCurrent() {
    return axios.get(API_URL + "/p-and-is", { headers: authHeader() });
  }
}

export default new PAndIDataService();
