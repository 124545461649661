import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class PoolDataService {
  // Get all pool from the database
  getAll(filter) {
    return axios.post(API_URL + "/pools", filter, { headers: authHeader() });
  }

  // Create a new pool
  create(data) {
    return axios.post(API_URL + "/pool", data, { headers: authHeader() });
  }

  // Get just one pool
  get(id) {
    return axios.get(API_URL + `/pool/${id}`, { headers: authHeader() });
  }

  // Delete a specific pool out of the table.
  delete(id) {
    return axios.delete(API_URL + `/pool/${id}`, { headers: authHeader() });
  }

  update(id, data) {
    return axios.put(API_URL + `/pool/${id}`, data, {
      headers: authHeader(),
    });
  }

  clone(id) {
    return axios.get(API_URL + `/pool/clone/${id}`,{ headers: authHeader() });
  }
}

export default new PoolDataService();