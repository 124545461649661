import React, { useEffect, useState } from "react";
import InsuredObjectDataService from "../../services/insured.object.data.service";
import * as Yup from "yup";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import styles from "./PoolShipForm.module.scss";
import { Formik } from "formik";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";

const PoolShipForm = (props) => {
  const [poolFields, setPoolFields] = useState({
    pool: props.editData.pool,
    ship: ""
  });
  // Variable with the values for the input fields
  const data = props.editData;
  useEffect(() => {
    setPoolFields({
      pool: data.pool,
      ship: {value: data.ship, label: data.ship.shipname}
    })
  }, [data]);

  const resetPoolFields = () => {
    setPoolFields({
      pool: data.pool,
      ship: ""
    });
  }

  const [ship, setShip] = useState([]);

  // Function to get all ships out of the databse.
  const getAllShips = () => {
    InsuredObjectDataService.getAll({ filter: "" })
      .then((response) => {
        console.log(response.data);
        let ships = [];
        response.data.forEach((element) => {
          ships.push({ value: element, label: element.shipname });
        });
        setShip(ships);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllShips();
  }, []);

  const PoolShipObjectSchema = Yup.object().shape({
    ship: Yup.object().required("Pflichtfeld").nullable(),
  });

  return (
    <>
      <Formik
        validationSchema={PoolShipObjectSchema}
        onSubmit={(values) => {
          console.log(values)
          props.onSubmit(values);
          resetPoolFields();
        }}
        initialValues={poolFields}
      >
        {({ handleSubmit, handleBlur, touched, errors, values, setFieldValue, setFieldTouched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridShip">
                <Form.Label>Schiff</Form.Label>
                <Select
                  isClearable
                  isSearchable
                  id="ship"
                  name="ship"
                  onChange={(selected) => {
                    values.ship = selected;
                    setFieldValue("ship", selected);
                  }}
                  onBlur={handleBlur}
                  onMenuClose={() => setFieldTouched("ship", true, false)}
                  placeholder="Wähle ein Schiff..."
                  options={ship}
                  value={values.ship}
                  styles={chooseCustomSelectStyle(errors.ship, values.ship, touched.ship)}
                />
                {errors.ship ? (
                  <div className={styles["invalid-error"]}>{errors.ship}</div>
                ) : null}
              </Form.Group>
            </Row>
            <SubmitButton className="submitBtn" variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PoolShipForm;
