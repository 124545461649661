import React, { useCallback, useEffect, useState } from "react";
import CreditDataService from "../../services/credit.data.service";
import CrudContent from "../CrudContent/CrudContent";
import GenericAlert from "../Alert/GenericAlert";
import Header from "../Header/Header";
import useAlert from "../../hooks/use-alert";
import CreditForm from "./CreditForm";
import { dateFormatterDE } from "../../formatter/date-formatter";

const Credit = () => {
  const [allCredits, setAllCredits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const { show, alertContent, handleAlertVisible } = useAlert();

  const header = [
    "ID",
    "Policennummer",
    "Rechnungsnummer",
    "Von",
    "Bis",
    "Typ",
    "Bearbeiten",
  ];
  const creditHeaders = [
    "ID:",
    "Policennummer:",
    "Rechnungsnummer:",
    "Von:",
    "Bis:",
    "Typ:",
  ];

  // The variables for the modals (add, delete, edit)
  const modalTitle = "Gutschrift löschen";
  const modalTitleEdit = "Gutschrift bearbeiten";
  const modalTitleAdd = "Gutschrift hinzufügen";
  const modalBody = "Möchten Sie die Gutschrift wirklich löschen?";

  const initialCreditObject = {
    id: "",
    division: "",
    invoice: "",
    credit_period_from: "",
    credit_period_to: "",
    division_type: "",
  };

  // The data to be edited
  const [editData, setEditData] = useState(initialCreditObject);

  // The data to be displayed
  const [displayData, setDisplayData] = useState({
    array: [],
    tableData: [],
  });

  // The filter value out of the filter input on top of the table
  const [filter, setFilter] = useState({ filter: "", year: "" });

  // Count of header items that need to be skipped
  const skip = 1;

  const getAllCredits = useCallback(() => {
    CreditDataService.getAll(filter)
      .then((response) => {
        if (response.data.length !== 0) {
          let creditArray = [];
          response.data.forEach((element) => {
            creditArray.push({
              id: element.id,
              division: element.division,
              invoice: element.invoice,
              credit_period_from: element.credit_period_from,
              credit_period_to: element.credit_period_to,
              division_type: element.division_type,
            });
          });
          setAllCredits(creditArray);
        } else {
          setAllCredits([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setIsUnauthorized(true);
          } else {
            setError(true);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    getAllCredits();
  }, [getAllCredits]);

  const deleteItem = (item) => {
    CreditDataService.delete(item.id)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich gelöscht",
          'Die Gutschrift "' + item.id + '" wurde erfolgreich gelöscht!',
          "success"
        );
        getAllCredits();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Gutschrift "' + item.id + '" konnte nicht gelöscht werden!',
          "danger"
        );
        console.log(error);
      });
  };

  const selectValuesFromObjects = (item) => {
    if (item.division.value !== undefined) {
      item.division = item.division.value;
    }
    if (item.invoice.value !== undefined) {
      item.invoice = item.invoice.value;
    }
  };

  // Function to edit a specific hull-franchise item.
  const editItem = (item) => {
    selectValuesFromObjects(item);
    console.log(item);
    CreditDataService.update(item.id, item)
      .then((response) => {
        console.log(response);
        handleAlertVisible(
          "Erfolgreich bearbeitet",
          'Die Gutschrift "' + item.id + '" wurde erfolgreich bearbeitet!',
          "success"
        );
        getAllCredits();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Gutschrift "' + item.id + '" konnte nicht bearbeitet werden!',
          "danger"
        );
        console.log(error);
      });
  };

  const addItem = (item) => {
    selectValuesFromObjects(item);
    console.log(item);
    CreditDataService.create(item)
      .then((response) => {
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die Gutschrift "' + response.data.id + '" wurde erfolgreich erstellt!',
          "success"
        );
        getAllCredits();
      })
      .catch((error) => {
        handleAlertVisible(
          "Fehler",
          'Die Gutschrift "' + item.id + '" konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      });
  };

  // Function to set the current filter value.
  const handleFilter = (filterValue) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, filter: filterValue.filter };
    });
  };

  // Function to set the current edit data.
  const handleEditItem = (item) => {
    console.log(item);
    setEditData(item);
  };

  // Function which creates an array with the data to be displayed
  // in the more information view.
  const handleDisplayItem = (data) => {
    console.log(data)
    const item = { ...data };
    console.log(item)
    // Get one specific attribute of the objects to be displayed.
    item.division = item.division.policy_number;
    item.invoice = item.invoice.invoice_number;
    item.credit_period_from = dateFormatterDE(item.credit_period_from);
    item.credit_period_to = dateFormatterDE(item.credit_period_to);
    // Create an array which holds multiple arrays each with
    // one key value pair separaeted by a comma.
    let creditArray = Object.entries(item);
    for (let i = 0; i < creditHeaders.length; i++) {
      // Replace all keys with the header value.
      creditArray[i][0] = creditHeaders[i];
      creditArray[i][2] = "";
    }
    console.log(creditArray);
    // Set the displayed data.
    setDisplayData({ array: creditArray });
  };

  const chooseCreditFunction = (divisionType, creditCreationData) => {
    switch(divisionType) {
      case "Kasko": 
        return CreditDataService.createHullCredit(creditCreationData);
      case "LOH-Franchise": 
        return CreditDataService.createLohFranchiseCredit(creditCreationData);
      case "LOH":
        return CreditDataService.createLohCredit(creditCreationData);
      case "Interesse":
        return CreditDataService.createInterestCredit(creditCreationData);
      case "Kasko-Franchise":
        return CreditDataService.createHullFranchiseCredit(creditCreationData);
      case "Elektronik":
        return CreditDataService.createElectronicsInsuranceCredit(creditCreationData);
      case "P & I":
        return CreditDataService.createPAndICredit(creditCreationData);
      default:
        return null;
    }
  }

  const handleCreditCreation = (creditCreationData, divisionType) => {
    console.log(creditCreationData)
    console.log(divisionType)
    chooseCreditFunction(divisionType, creditCreationData)
      .then(response => {
        handleAlertVisible(
          "Erfolgreich hinzugefügt",
          'Die ' + divisionType + ' Gutschrift wurde erfolgreich erstellt!',
          "success"
        );
      })
      .catch(error => {
        handleAlertVisible(
          "Fehler",
          'Die ' + divisionType + ' Gutschrift konnte nicht erstellt werden!',
          "danger"
        );
        console.log(error);
      })
  }

  return (
    <main>
      <Header />
      <div className="main">
        <CrudContent
          heading="Gutschriften"
          items={allCredits}
          header={header}
          onDelete={deleteItem}
          delete
          modalTitle={modalTitle}
          modalBody={modalBody}
          modalTitleEdit={modalTitleEdit}
          hasForm
          form={
            <CreditForm editData={editData} btnText="Bearbeiten" isEditing />
          }
          onEditItemReceive={handleEditItem}
          onEditItem={editItem}
          placeholder={"Filter Gutschriften..."}
          modalTitleAdd={modalTitleAdd}
          formAdd={<CreditForm btnText="Hinzufügen" />}
          onAddItem={addItem}
          add
          onFilter={handleFilter}
          skip={skip}
          attr={{ 1: "policy_number", 2: "invoice_number" }}
          onDisplayItemReceive={handleDisplayItem}
          displayItems={displayData}
          moreInfo
          edit
          loading={isLoading}
          unauthorized={isUnauthorized}
          error={error}
          credit
          onCreditCreation={handleCreditCreation}
        />
      </div>
      <GenericAlert
        show={show}
        alertVariant={alertContent.variant}
        alertHeading={alertContent.heading}
        alertBody={alertContent.message}
      />
    </main>
  );
};

export default Credit;
