import React, { useEffect, useState }  from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  pricePattern,
  percentagePattern,
} from "../../patterns/patterns";
import { Form, Col, Row } from "react-bootstrap";
import SubmitButton from "../UI/Button/SubmitButton";
import styles from "./HullForm.module.scss";
import Select from "react-select";
import { chooseCustomSelectStyle } from "../../custom-style/custom-select";
import HullFranchiseDataService from "../../services/hull-franchise.data.service";
import { fillOutFieldsWithDivisonData } from "../../helper/helper";

const HullFranchiseChangeForm = (props) => {
  const data = props.editData;

  // The states for select fileds (policynumber)
  const [policynumber, setPolicynumber] = useState([]);

  const getAllPolicynumbers = () => {
    HullFranchiseDataService.getAllCurrent()
      .then((response) => {
        let policynumbers = [];
        response.data.forEach((element) => {
          console.log(element);
          policynumbers.push({
            value: element,
            label: element,
          });
        });
        console.log(policynumbers);
        setPolicynumber(policynumbers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Only call the getAllPolicynumbers() function the first time this component
  // is rendered.
  useEffect(() => {
    getAllPolicynumbers();
  }, []);

  const HullFranchiseSchema = Yup.object().shape(
    {
      policy_number: Yup.object().required("Pflichtfeld").nullable(),
      insurance_start: Yup.date().required("Pflichtfeld"),
      insurance_sum_one: Yup.string().when(["deductible_one", "premium_one"], {
        is: (deductible_one, premium_one) =>
          deductible_one !== undefined || premium_one !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      insurance_sum_two: Yup.string().when(["deductible_two", "premium_two"], {
        is: (deductible_two, premium_two) =>
          deductible_two !== undefined || premium_two !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      insurance_sum_three: Yup.string().when(
        ["deductible_three", "premium_three"],
        {
          is: (deductible_three, premium_three) =>
            deductible_three !== undefined || premium_three !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      insurance_sum_four: Yup.string().when(
        ["deductible_four", "premium_four"],
        {
          is: (deductible_four, premium_four) =>
            deductible_four !== undefined || premium_four !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      premium_one: Yup.string().when(["insurance_sum_one", "deductible_one"], {
        is: (insurance_sum_one, deductible_one) =>
          insurance_sum_one !== undefined || deductible_one !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      premium_two: Yup.string().when(["insurance_sum_two", "deductible_two"], {
        is: (insurance_sum_two, deductible_two) =>
          insurance_sum_two !== undefined || deductible_two !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      premium_three: Yup.string().when(
        ["insurance_sum_three", "deductible_three"],
        {
          is: (insurance_sum_three, deductible_three) =>
            insurance_sum_three !== undefined || deductible_three !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      premium_four: Yup.string().when(
        ["insurance_sum_four", "deductible_four"],
        {
          is: (insurance_sum_four, deductible_four) =>
            insurance_sum_four !== undefined || deductible_four !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      deductible_one: Yup.string().when(["insurance_sum_one", "premium_one"], {
        is: (insurance_sum_one, premium_one) =>
          insurance_sum_one !== undefined || premium_one !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      deductible_two: Yup.string().when(["insurance_sum_two", "premium_two"], {
        is: (insurance_sum_two, premium_two) =>
          insurance_sum_two !== undefined || premium_two !== undefined,
        then: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .required("Pflichtfeld"),
        otherwise: Yup.string()
          .matches(
            pricePattern,
            "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
          )
          .nullable(),
      }),
      deductible_three: Yup.string().when(
        ["insurance_sum_three", "premium_three"],
        {
          is: (insurance_sum_three, premium_three) =>
            insurance_sum_three !== undefined || premium_three !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      deductible_four: Yup.string().when(
        ["insurance_sum_four", "premium_four"],
        {
          is: (insurance_sum_four, premium_four) =>
            insurance_sum_four !== undefined || premium_four !== undefined,
          then: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .required("Pflichtfeld"),
          otherwise: Yup.string()
            .matches(
              pricePattern,
              "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
            )
            .nullable(),
        }
      ),
      payment_method: Yup.string().nullable(),
      tax: Yup.string()
        .matches(
          percentagePattern,
          "Mit Komma trennen maximal 2 Nachkommastellen"
        )
        .required("Pflichtfeld"),
      brokerage: Yup.string()
        .matches(
          percentagePattern,
          "Mit Komma trennen maximal 2 Nachkommastellen"
        )
        .required("Pflichtfeld"),
      hull: Yup.object().nullable(),
      corporation: Yup.object().required("Pflichtfeld").nullable(),
      insurance_period_from: Yup.date().required("Pflichtfeld"),
      insurance_period_to: Yup.date().required("Pflichtfeld"),
      ship: Yup.object().required("Pflichtfeld").nullable(),
      fixed_premium: Yup.string()
        .matches(
          pricePattern,
          "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
        )
        .required("Pflichtfeld"),
      fixed_premium_corp: Yup.string()
        .matches(
          pricePattern,
          "Betrag mit Komma trennen und maximal 2 Nachkommastellen"
        )
        .required("Pflichtfeld"),
    },
    [
      ["premium_one", "deductible_one"],
      ["premium_one", "insurance_sum_one"],
      ["deductible_one", "insurance_sum_one"],
      ["premium_two", "deductible_two"],
      ["premium_two", "insurance_sum_two"],
      ["deductible_two", "insurance_sum_two"],
      ["premium_three", "deductible_three"],
      ["premium_three", "insurance_sum_three"],
      ["deductible_three", "insurance_sum_three"],
      ["premium_four", "deductible_four"],
      ["premium_four", "insurance_sum_four"],
      ["deductible_four", "insurance_sum_four"],
    ]
  );

  return (
    <Formik
      validationSchema={HullFranchiseSchema}
      onSubmit={(values) => {
        props.onSubmit(values);
      }}
      initialValues={{
        policy_number:
          data !== undefined && data.policy_number !== null
            ? { value: data.policy_number, label: data.policy_number }
            : "",
        insurance_sum_one:
          data !== undefined && data.insurance_sum_one !== null
            ? data.insurance_sum_one
            : "",
        insurance_sum_two:
          data !== undefined && data.insurance_sum_two !== null
            ? data.insurance_sum_two
            : "",
        insurance_sum_three:
          data !== undefined && data.insurance_sum_three !== null
            ? data.insurance_sum_three
            : "",
        insurance_sum_four:
          data !== undefined && data.insurance_sum_four !== null
            ? data.insurance_sum_four
            : "",
        premium_one:
          data !== undefined && data.premium_one !== null
            ? data.premium_one
            : "",
        premium_two:
          data !== undefined && data.premium_two !== null
            ? data.premium_two
            : "",
        premium_three:
          data !== undefined && data.premium_three !== null
            ? data.premium_three
            : "",
        premium_four:
          data !== undefined && data.premium_four !== null
            ? data.premium_four
            : "",
        deductible_one:
          data !== undefined && data.deductible_one !== null
            ? data.deductible_one
            : "",
        deductible_two:
          data !== undefined && data.deductible_two !== null
            ? data.deductible_two
            : "",
        deductible_three:
          data !== undefined && data.deductible_three !== null
            ? data.deductible_three
            : "",
        deductible_four:
          data !== undefined && data.deductible_four !== null
            ? data.deductible_four
            : "",
        payment_method: data !== undefined ? data.payment_method : "",
        tax: data !== undefined ? data.tax : "",
        insurance_start: data !== undefined ? data.insurance_start : "",
        brokerage: data !== undefined ? data.brokerage : "",
        corporation:
          data !== undefined
            ? data.corporation
            : "",
        ship:
          data !== undefined
            ? data.ship
            : "",
        hull:
          data !== undefined && data.hull !== null
            ? data.hull
            : "",
        termination:
          data !== undefined && data.termination !== null
            ? data.termination
            : "",
        insurance_period_from:
          data !== undefined ? data.insurance_period_from : "",
        insurance_period_to: data !== undefined ? data.insurance_period_to : "",
        fixed_premium: data !== undefined ? data.fixed_premium : "",
        fixed_premium_corp: data !== undefined ? data.fixed_premium_corp : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        errors,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridHull">
              <Form.Label>Policennummer</Form.Label>
              <Select
                isClearable
                isSearchable
                id="policy_number"
                name="policy_number"
                placeholder="Wähle Policennummer..."
                onChange={(selected) => {
                  if (selected !== null) {
                    HullFranchiseDataService.get(selected.value)
                      .then((response) => {
                        console.log(response.data);
                        const hullFranchise = response.data;
                        fillOutFieldsWithDivisonData(hullFranchise, values, setFieldValue);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                  values.policy_number = selected;
                  setFieldValue("policy_number", selected);
                }}
                onBlur={handleBlur}
                onMenuClose={() =>
                  setFieldTouched("policy_number", true, false)
                }
                options={policynumber}
                value={values.policy_number}
                styles={chooseCustomSelectStyle(
                  errors.policy_number,
                  values.policy_number,
                  touched.policy_number
                )}
              />
              {errors.policy_number && touched.policy_number ? (
                <div className={styles["invalid-error"]}>
                  {errors.policy_number}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="formGridFixedPremium">
              <Form.Label>Festprämie</Form.Label>
              <Form.Control
                name="fixed_premium"
                type="text"
                placeholder="Festprämie"
                value={values.fixed_premium}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("fixed_premium", true, false)}
                isValid={touched.fixed_premium && !errors.fixed_premium}
                isInvalid={!!errors.fixed_premium && touched.fixed_premium}
              />
              {errors.fixed_premium && touched.fixed_premium ? (
                <div className={styles["invalid-error"]}>
                  {errors.fixed_premium}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridFixedPremiumCorp">
              <Form.Label>Festprämie Gesell.</Form.Label>
              <Form.Control
                name="fixed_premium_corp"
                type="text"
                placeholder="Festprämie Gesell."
                value={values.fixed_premium_corp}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() =>
                  setFieldTouched("fixed_premium_corp", true, false)
                }
                isValid={
                  touched.fixed_premium_corp && !errors.fixed_premium_corp
                }
                isInvalid={
                  !!errors.fixed_premium_corp && touched.fixed_premium_corp
                }
              />
              {errors.fixed_premium_corp && touched.fixed_premium_corp ? (
                <div className={styles["invalid-error"]}>
                  {errors.fixed_premium_corp}
                </div>
              ) : null}
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridInsuranceStart">
                <Form.Label>Versicherungsbeginn</Form.Label>
                <Form.Control
                  name="insurance_start"
                  type="date"
                  placeholder="Versicherungsbeginn"
                  value={values.insurance_start}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={() =>
                    setFieldTouched("insurance_start", true, false)
                  }
                  isValid={touched.insurance_start && !errors.insurance_start}
                  isInvalid={
                    !!errors.insurance_start && touched.insurance_start
                  }
                  disabled={props.isEditing}
                />
                {errors.insurance_start && touched.insurance_start ? (
                  <div className={styles["invalid-error"]}>
                    {errors.insurance_start}
                  </div>
                ) : null}
              </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridTax">
              <Form.Label>Steuer</Form.Label>
              <Form.Control
                name="tax"
                type="text"
                placeholder="Steuer"
                value={values.tax}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={() => setFieldTouched("tax", true, false)}
                isValid={touched.tax && !errors.tax}
                isInvalid={!!errors.tax && touched.tax}
              />
              {errors.tax && touched.tax ? (
                <div className={styles["invalid-error"]}>{errors.tax}</div>
              ) : null}
            </Form.Group>
          </Row>
          <div className={styles.submitBtn}>
            <SubmitButton variant="primary" type="submit">
              {props.btnText}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default HullFranchiseChangeForm;
