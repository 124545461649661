import React from "react";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import SelectYear from "../UI/SelectYear/SelectYear";
import styles from "./ItemFilter.module.scss";

function ItemFilter(props) {
  // If the filterValue changes pass it upwards to the parent component.
  const handleOnChange = (e) => {
    props.onChange(e);
  };

  return (
    <div className="row">
      <div className="col">
        <InputGroup className="mb-3">
          <FormControl
            placeholder={props.placeholder}
            aria-label="Filter"
            aria-describedby="basic-addon1"
            onChange={handleOnChange}
          />
          <InputGroup.Text id="basic-addon1">
            <i className="bi bi-search"></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      {props.hasYearFilter && (
        <div className="col">
          <SelectYear onYearChange={props.onYearChange} />
        </div>
      )}
      <div className="col">
        {props.add &&
        <Button className={styles.addBtn} onClick={() => props.onAdd()}>
          <i className="bi bi-plus-circle" style={{ color: "white" }}></i> Add
        </Button>
        }
        {props.changeDivision && (
          <Button
            className={styles.changeBtn}
            onClick={() => props.onAddChange()}
          >
            <i className="bi bi-plus-circle" style={{ color: "white" }}></i>{" "}
            Änderung
          </Button>
        )}
      </div>
    </div>
  );
}

export default ItemFilter;
