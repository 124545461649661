import authHeader from "./auth-header";
import axios from "axios";
import { API_URL } from "./constants.service";

class LohFranchiseDataService {
  // Get all loh-franchises from the database
  getAll(filter) {
    return axios.post(API_URL + "/loh-franchises", filter, { headers: authHeader() });
  }

  // Create a new loh-franchise
  create(data) {
    return axios.post(API_URL + "/loh-franchise", data, { headers: authHeader() });
  }

  // Get just one loh-franchise
  get(id) {
    return axios.get(API_URL + `/loh-franchise/${id}`, { headers: authHeader() });
  }

  // Delete a specific loh-franchise out of the table.
  delete(id) {
    return axios.delete(API_URL + `/loh-franchise/${id}`, { headers: authHeader() });
  }

  // Update the data of one specific loh-franchise.
  update(id, data) {
    return axios.put(API_URL + `/loh-franchise/${id}`, data, {
      headers: authHeader(),
    });
  }

  getAllCurrent() {
    return axios.get(API_URL + "/loh-franchises", { headers: authHeader() });
  }
}

export default new LohFranchiseDataService();